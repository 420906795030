import React from 'react';
import { Button, Card, Collapse, Divider, Input, Space, Table } from 'antd';
import Icon, { EditFilled, EyeFilled, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import { callOnServer, getClaims, getLoggedInUserName, RRServer } from '../../utils/lib';
import { UserInterface, USER_TYPES } from '../../types';

// import Razorpay from "razorpay";
import logo from "../../logo.svg";
import { Select } from 'antd';

const { Option } = Select;
const { Panel } = Collapse;

interface DrivingSchoolInterface {
    code: string;
    id: string
    drivingSchoolName: string
    currentCount: string
    pricePerCode: number
    codesRemaining: number
}
interface CorporateInterface {
    code: string;
    id: string
    corporateName: string
    currentCount: string
}
interface CorporateCodesInterface {
    code: string;
    password: string;
}


type schoolHashedType = { [key: string]: DrivingSchoolInterface };
type corporateHashedType = { [key: string]: CorporateInterface };
type corporateCodesHashedType = { [key: string]: CorporateCodesInterface };
interface UserStateProps {
    schools: DrivingSchoolInterface[],
    schoolsHashed: schoolHashedType

    corporates: CorporateInterface[],
    corporatesHashed: corporateHashedType

    selectedSchool: DrivingSchoolInterface | null
    userCode: string
    users: UserInterface[]

    corporateCodes: CorporateCodesInterface[],
    corporateCodesHashed: corporateCodesHashedType


    filterSchoolId: string
    filterUserId: string
    filterMobileNumber : string

    codesToBuy: number,

    loading: boolean
}

class CorporateList extends React.Component<any, UserStateProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            schools: [],
            schoolsHashed: {},
            corporates: [],
            corporatesHashed: {},
            selectedSchool: null,
            userCode: '',
            users: [],
            corporateCodes: [],
            corporateCodesHashed: {},
            filterSchoolId: '',
            filterUserId: '',
            filterMobileNumber:'',
            codesToBuy: 5,
            loading: false
        }
        this.getSchool = this.getSchool.bind(this);


    }

    componentDidMount() {
        //         const companyId=getUse
        this.getData('');

    }

    componentDidUpdate(prevProps: any) {

        if (this.props.drivingSchool !== prevProps.drivingSchool) {
            console.log('Props changed', this.props)
            this.getData(this.props.drivingSchool.id);
        }
    }

    getData = (id: string) => {

        getClaims().then(claims => {
            if (!claims || !!!claims['drivingSchoolId']) { return null }
            const drivingSchoolId = claims ? claims['drivingSchoolId'] : '#';

            const userPromise = (drivingSchoolId === '*' || drivingSchoolId === '#') 
                ? RRServer.list({ table: 'users', forCurrentUser: false, where: [['corporateType','==','PETROLEUM']] })
                : RRServer.list({ table: 'users', forCurrentUser: false, where: [['companyId', '==', drivingSchoolId],['corporateType','==','PETROLEUM']] });
            const dsPromise =  (drivingSchoolId === '*' || drivingSchoolId === '#') 
                ? RRServer.list({ table: 'driving_schools', forCurrentUser: false })
                : RRServer.get({ table: 'driving_schools', id: drivingSchoolId });

            Promise.all([
                // RRServer.list({ table: 'users', forCurrentUser: false, where:[['companyId','==',id]] }),
                userPromise,
                dsPromise,
                RRServer.list({ table: 'corporate_users', forCurrentUser: false }),
                RRServer.list({ table: 'corporate_codes', forCurrentUser: false }),
            ])
                .then((results: any) => {
                    let users = results[0].data.data.sort((a: UserInterface, b: UserInterface) => a.name.localeCompare(b.name));

                    let schools: DrivingSchoolInterface[];
                    let _s:schoolHashedType={}
                    if (results[1].data.data) {
                        schools = results[1].data.data.length ? results[1].data.data : [results[1].data.data];
                         _s = {};
                        schools.map(school => _s[school.id] = school)
                    } else {
                        schools = [];
                    }

                    let corporates: CorporateInterface[] = results[2].data.data;
                    let _c: corporateHashedType = {};
                    corporates.map(corp => _c[corp.id] = corp)

                    let corporateCodes: CorporateCodesInterface[] = results[3].data.data;
                    let _cc: corporateCodesHashedType = {};
                    corporateCodes.map(corp => _cc[corp.code] = corp)

                    this.setState({ users, schools, schoolsHashed: _s, corporates, corporatesHashed: _c, corporateCodes, corporateCodesHashed: _cc })
                });


        })
    }

    getSchool(schoolId: string) {
        const school = this.state.schools.filter(school => school.id = schoolId);
        return school[0];
    }

    getName = (user: UserInterface) => {
        if (!user) { return 'NU' }
        const { schoolsHashed, corporatesHashed } = this.state;
        if (user.type === 'DRIVING_SCHOOL') {
            const _school = schoolsHashed[user.companyId];
            return (_school && _school.drivingSchoolName);
        } else if (user.type === 'CORPORATE_USER_IOCL') {
            const _corp = corporatesHashed[user.companyId];
            return (_corp && _corp.corporateName);
        }
    }

    getPassword = (user: UserInterface) => {
        if (user.password) {
            return user.password;
        }
        const { corporateCodesHashed } = this.state;
        const cc = corporateCodesHashed[user.code];
        return cc ? cc.password : '-';
    }

    loadScript(src: string) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    createOrder = async () => {
        const { schools, codesToBuy } = this.state;
        const pricePerCode = schools[0].pricePerCode;

        if (!pricePerCode) {
            alert('Price Per Code Not Set');
            return;
        }

        return RRServer.callFn('payment1-createOrder', {
            pricePerCode: pricePerCode, codes: codesToBuy
        })
            .then((r: any) => r.data.order)

    }

    displayRazorpay = async () => {
        const { schools, codesToBuy } = this.state;

        const res = await this.loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        this.setState({ loading: true })


        // creating a new order
        const result = await this.createOrder();
        console.log(result);

        if (!result) {
            alert("Server error. Are you online?");
            return;
        }

        // // Getting the order details back
        const { amount, id: order_id, currency } = result;
        const name = getLoggedInUserName();

        const options = {
            key: "rzp_live_bfig0kGIyXdE7J", // Enter the Key ID generated from the Dashboard
            amount: amount.toString(),
            currency: currency,
            name: name,
            description: "Roadrakshak User Codes",
            image: { logo },
            order_id: order_id,
            handler: async (response: any) => {
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                    code: codesToBuy
                };


                RRServer.add({
                    table: 'payments', data: {
                        razorpayPaymentId: response.razorpay_payment_id,
                        razorpayOrderId: response.razorpay_order_id,
                        amount,
                        codesToBuy,
                        pricePerCode: schools[0].pricePerCode,
                        schoolId: schools[0].id,
                        schoolName: schools[0].drivingSchoolName
                    }
                });
                const codesRemaining = schools[0].codesRemaining || 0;
                RRServer.update({ table: RRServer.Tables.DRIVING_SCHOOLS, id: schools[0].id, codesRemaining: codesRemaining + codesToBuy });
                alert('Payment Successful');
                this.setState({ loading: false })

                return;
                //skipping this for now
                //@todo MUST VERIFY ORDER
                RRServer.callFn('payment1-verifyOrder', data)
                    .then((r: any) => {
                        console.log('Success', r);
                        // alert(result.data.msg);
                        if (r.data.s) {

                            RRServer.add({
                                table: 'payments', data: {
                                    razorpayPaymentId: response.razorpay_payment_id,
                                    razorpayOrderId: response.razorpay_order_id,
                                    amount,
                                    codesToBuy,
                                    pricePerCode: schools[0].pricePerCode,
                                    schoolId: schools[0].id,
                                    schoolName: schools[0].drivingSchoolName
                                }
                            });
                            const codesRemaining = schools[0].codesRemaining || 0;
                            RRServer.update({ table: RRServer.Tables.DRIVING_SCHOOLS, id: schools[0].id, codesRemaining: codesRemaining + codesToBuy });
                            alert('Payment Successful');
                            this.setState({ loading: false })

                        }

                    })

            },
            prefill: {
                name: name,

            },
            notes: {

            },
            theme: {
                color: "#61dafb",
            },
        };

        const paymentObject = new (window as any).Razorpay(options);
        paymentObject.open();
    }

    subtractCodes = () => this.setState({ codesToBuy: this.state.codesToBuy == 5 ? 5 : this.state.codesToBuy - 5 })
    addCodes = () => this.setState({ codesToBuy: this.state.codesToBuy + 5 })

    render() {

        const { users, schools, filterSchoolId, filterUserId, filterMobileNumber, codesToBuy } = this.state;
        let { url } = this.props.match;
        const currentSchool = this.props.drivingSchool || {};


        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || '#';

        let dataSource = users.map(user => ({ key: user.id, ...user }))
        const columns = [
            // {
            //     title: 'ID',
            //     dataIndex: 'id',
            //     key: 'id',
            // },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                sorter: true,
            },
            {
                title: 'Code',
                dataIndex: 'code',
                key: 'code',
            },
            {
                title: 'Password',
                // dataIndex: 'password',
                // key: 'password',
                render: (user: UserInterface) => {
                    return this.getPassword(user);
                }
            },
            {
                title: 'Mobile',
                dataIndex: 'mobile',
                key: 'mobile',
            },
            {
                title: 'App Download',
                key: 'appdownload',
                render: (user: UserInterface) => {
                    return !!user.fcmToken ? 'Yes':'No';
                },
                filters: [
                    { text: 'Boarded', value: 'boarded' },
                    { text: 'Pending', value: 'Pending' },
                ],
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
            },
            {
                title: 'Action',
                key: 'action',
                sorter: true,
                render: ({ id }: { id: string }) => (
                    <Space split={<Divider type="vertical" />}>
                        <Link to={`${url}/edit-ds-user/${id}`} key='edit-0'>
                            <EditFilled />
                        </Link>
                        {/* <Link to={`${url}/view/${id}`} key='view-1'>
                            <EyeFilled />
                        </Link> */}
                        {/* <Link to={`${url}/view/${id}`} key='view-1'>
                            <DeleteFilled />
                        </Link> */}
                    </Space>
                ),
            },
        ];

        if (filterSchoolId) {
            dataSource = dataSource.filter(d => d.companyId === filterSchoolId)
        }
        if (filterUserId ) {
            dataSource = dataSource.filter(d => d.id === filterUserId)
        }
        if (filterMobileNumber) {
            dataSource = dataSource.filter(d => d.id === filterMobileNumber)
        }

        const ids=[
            {id:'dWCDFblM44jUJkuqijW8',name:'Chitoor'},
            {id:'2x38475dOv3oVM0WiZt7',name:'Simoga'}
        ];

        return (
            <main>

                
<Select
                    showSearch
                    style={{ width: 200, marginTop: '2vh', marginBottom: '2vh' }}
                    placeholder="Filter CID"
                    optionFilterProp="children"
                    allowClear
                    onClear={() => this.setState({ filterSchoolId: '' })}
                    onChange={(id:string) => this.setState({ filterSchoolId: id })}
                    // onFocus={onFocus}
                    // onBlur={onBlur}
                    // onSearch={onSearch}
                    filterOption={(input, option) => {
                        return option!.children ? option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                    }
                    }
                >
                    {ids.map(id=><Option key={id.id} value={id.id}>{id.name}</Option>)}

                </Select>


                <Table className="UsersTable" dataSource={dataSource} columns={columns} />
            </main>
        );
    }
}

// export default withRouter(UserAdd);
export default withRouter(CorporateList);