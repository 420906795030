import React from 'react';
import { Button, Divider, Form, Input, Skeleton, Modal, DatePicker } from 'antd';
import { withRouter } from 'react-router-dom';
import { RRServer } from '../../utils/lib';
import { ClassScheduleInterface, DAYS, UserInterface } from '../../types';
import moment from 'moment';


interface ClassSchedulesViewInterface {
    classSchedule: ClassScheduleInterface | null,
    instructor: UserInterface | null,
    selectedDate: string,
    slots: string[],
    loading:boolean
}

class ClassSchedulesView extends React.Component<any, ClassSchedulesViewInterface> {
    constructor(props: any) {
        super(props);
        this.state = {
            classSchedule: null,
            instructor: null,
            selectedDate: '',
            slots: [],
            loading:false
        }
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        const instructorId = this.props.match.params.instructorId;

        Promise.all([
            RRServer.get({ table: RRServer.Tables.CLASS_SCHEDULES, id }),
            RRServer.get({ table: RRServer.Tables.USERS, id: instructorId })
        ])
            .then((results: any[]) => {
                const classSchedule = results[0].data.data;
                const instructor = results[1].data.data;
                const day = moment(classSchedule.date, 'DD/MM/YYYY').day();
                console.log(instructor.slots[DAYS[day]])

                this.setState({
                    classSchedule,
                    instructor,
                    slots: instructor.slots[DAYS[day]]
                })

            })
    }

    changeDate = (d: any, ds: any) => {
        const { instructor, classSchedule } = this.state
        const day = DAYS[d.day()];
        const slots = instructor?.slots![day] || [];
        classSchedule!.time = slots[0];
        classSchedule!.date = ds;
        this.setState({ selectedDate: ds, slots, classSchedule })
    }

    saveNewSchedule = () => {

        const { classSchedule } = this.state;
        if (classSchedule) {
            this.setState({loading:true})
            RRServer.update({ table: RRServer.Tables.CLASS_SCHEDULES, id: classSchedule.id,time:classSchedule.time, date:classSchedule.date, rescheduleRequest:false })
            .then((result:any)=>{
                if(result.data.s){
                    alert('Schedule Updated');
                    window.history.back();
                }
            })
        }

    }



    render() {
        const { classSchedule, instructor, slots , loading} = this.state

        // classSchedule&& console.log(!!classSchedule?.time,!!classSchedule!.date)

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };

        const tailLayout = {
            wrapperCol: { offset: 18, span: 16 },
        };




        return (
            <main>
                <Divider />
                <div style={{ width: '400px' }}>

                    {(classSchedule && instructor) === null
                        ? <Skeleton active />
                        : <Form
                            {...layout}
                            name="basic"
                            initialValues={{
                                ...classSchedule,
                                date: moment(classSchedule!.date, "DD/MM/YYYY")
                            }}
                            onValuesChange={(changedValues, allValues) => console.log(changedValues, allValues)}
                        >
                            <Form.Item
                                label="School"
                                name="school"
                            >
                                <Input placeholder="Name" disabled={true} />
                            </Form.Item>

                            <Form.Item
                                label="User"
                                name="User"
                            >
                                <Input placeholder="User" disabled={true} />
                            </Form.Item>

                            <Form.Item
                                label="Date"
                                name="date"
                            >
                                {/* <Input placeholder="Date"   /> */}
                                <DatePicker format={'DD/MM/YYYY'} onChange={this.changeDate} allowClear={false} />
                            </Form.Item>
                            <Form.Item
                                label="Time"
                                name="time"
                            >
                                {/* <Input placeholder="Time" /> */}
                                <select>
                                    {slots.map((slot: string) => <option key={slot}>{slot}</option>)}
                                </select>
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <Button type="primary" onClick={this.saveNewSchedule} disabled={loading || !!!classSchedule!.time || !!!classSchedule!.date}>
                                    Reschedule
                                </Button>
                            </Form.Item>

                        </Form>
                    }
                </div>
            </main>
        );
    }
}

export default withRouter(ClassSchedulesView);