import { Tabs, Skeleton, Card } from 'antd';
import Statistic from 'antd/lib/statistic';
import React from 'react';
import { CorporateCodeInterface, DrivingSchoolInterface, ScoreInterface, UserInterface } from '../../types';
import { RRServer } from '../../utils/lib';
import APToolbar from './AP-Buttons-Toolbar';
import Style from './AP-Dashboard.module.css';
import rakshaklogo from "../../assets/images/rakshaklogo.png";
import asianpaintslogo from "../../assets/images/asian-paints-logo.png";
import poweredByImg from "../../assets/images/poweredByRC.png";

interface DashProps {
    schools: DrivingSchoolInterface[]
    users: UserInterface[],
    corporateCodes: CorporateCodeInterface[],
    corporateCodesCount: number,
    drivingSchoolCodesCount: number,
    studentsCount: number,
    instructorCount: number,
    adminCount: number,
    loading: boolean,
    scores: ScoreInterface[]
}



const CORPORATE_CODE = "RKkKTihRtahnXPFWUN8O";



class APDashboard extends React.Component<null, DashProps> {


    constructor(props: null) {
        super(props);

        this.state = {
            loading: true,
            schools: [],
            users: [],
            corporateCodes: [],
            corporateCodesCount: 0,
            drivingSchoolCodesCount: 0,
            studentsCount: 0,
            instructorCount: 0,
            adminCount: 0,
            scores: []
        }
    }


    componentDidMount() {

        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || '';

        console.log('Claims', claims);
        if (!claims.drivingSchoolId) {
            console.log('Dirving School Not Found');
            return;
        }

        let CORPORATE_CODE_ID = CORPORATE_CODE;//claims.drivingSchoolId;


        this.getScoresForGame(CORPORATE_CODE_ID);


        const promises = [
            RRServer.list({
                table: RRServer.Tables.DRIVING_SCHOOLS,
                forCurrentUser: false,
            }),
            RRServer.list({
                table: RRServer.Tables.USERS,
                forCurrentUser: false,
                where: [["companyId", "==", CORPORATE_CODE_ID]]
            }),
            RRServer.list({
                table: 'corporate_codes',
                forCurrentUser: false,
                where: [["companyId", "==", CORPORATE_CODE_ID]]
            })
        ];


        Promise.all(promises)
            .then((results: any) => {

                const [r1, r2, r3] = results;


                this.setState({
                    schools: r1.data.data,
                    users: r2.data.data,
                    corporateCodesCount: r3.data.data.length,
                    corporateCodes: r3.data.data,
                    studentsCount: r3.data.data.filter(({ type }: { type: string }) => type ? type.indexOf('CORPORATE_USER_AP') == -1 : false).length,
                    loading: false
                })

            })


        // if (claims.drivingSchoolId === "*") {


        //     const promises = [
        //         RRServer.list({
        //             table: RRServer.Tables.DRIVING_SCHOOLS,
        //             forCurrentUser: false,
        //         }),
        //         RRServer.list({
        //             table: RRServer.Tables.USERS,
        //             forCurrentUser: false,
        //             where: [["companyId", "==",CORPORATE_CODE]]
        //         }),
        //         RRServer.list({
        //             table: 'corporate_codes',
        //             forCurrentUser: false,
        //             where: [["companyId", "==", CORPORATE_CODE]]
        //         })
        //     ];


        //     Promise.all(promises)
        //         .then((results: any) => {

        //             const [r1, r2, r3] = results;


        //             this.setState({
        //                 schools: r1.data.data,
        //                 users: r2.data.data,
        //                 corporateCodesCount: r3.data.data.length,
        //                 corporateCodes: r3.data.data,
        //                 studentsCount: r3.data.data.filter(({ type }: { type: string }) => type ? type.indexOf('CORPORATE_USER_AP1') == -1 : false).length,
        //                 loading: false
        //             })

        //         })

        //     // RRServer.list({
        //     //     table: RRServer.Tables.DRIVING_SCHOOLS,
        //     //     forCurrentUser: false,
        //     // })
        //     //     .then((result: any) => {
        //     //         this.setState({ schools: result.data.data })
        //     //     })

        //     // RRServer.list({
        //     //     table: RRServer.Tables.USERS,
        //     //     forCurrentUser: false,
        //     //     where: [["companyId", "==", "KcuezrWKEM7RgEjzKPRb"]]
        //     // })
        //     //     .then((result: any) => {
        //     //         this.setState({
        //     //             users: result.data.data
        //     //         })
        //     //     })

        //     // RRServer.list({
        //     //     table: 'corporate_codes',
        //     //     forCurrentUser: false,
        //     //     where: [["companyId", "==", "KcuezrWKEM7RgEjzKPRb"]]
        //     // }).then((result: any) => {
        //     //     this.setState({
        //     //         corporateCodesCount: result.data.data.length,
        //     //         corporateCodes: result.data.data,
        //     //         studentsCount: result.data.data.filter(({ type }: { type: string }) => type ? type.indexOf('CORPORATE_USER_AP1') == -1 : false).length,
        //     //         loading: false
        //     //     });
        //     // })

        // } else {


        //     const promises = [
        //         RRServer.get({
        //             table: RRServer.Tables.DRIVING_SCHOOLS,
        //             id: claims.drivingSchoolId
        //         }),
        //         RRServer.list({
        //             table: RRServer.Tables.USERS,
        //             forCurrentUser: false,
        //             where: [["drivingSchoolId", "==", claims.drivingSchoolId]]
        //         }),
        //         RRServer.list({
        //             table: 'corporate_codes',
        //             forCurrentUser: false,
        //             where: [["companyId", "==", claims.drivingSchoolId]]
        //         })
        //     ];

        //     Promise.all(promises)
        //         .then((results: any) => {
        //             const [r1, r2, r3] = results;

        //             this.setState({
        //                 schools: r1.data.data,
        //                 users: r2.data.data,
        //                 corporateCodesCount: r3.data.data.length,
        //                 corporateCodes: r3.data.data,
        //                 drivingSchoolCodesCount: r3.data.data.filter(({ type }: { type: string }) => type.indexOf('DRIVING_') != -1).length,
        //                 studentsCount: r3.data.data.filter(({ type }: { type: string }) => type.indexOf('DRIVING_') != -1 && type.indexOf('DRIVING_SCHOOL_ADMIN') == -1 && type.indexOf('DRIVING_SCHOOL_INSTRUCTOR') == -1).length,
        //                 instructorCount: r3.data.data.filter(({ type }: { type: string }) => type === 'DRIVING_SCHOOL_INSTRUCTOR').length,
        //                 adminCount: r3.data.data.filter(({ type }: { type: string }) => type === 'DRIVING_SCHOOL_ADMIN').length,
        //                 loading: false

        //             })

        //         })

        //     // RRServer.get({
        //     //     table: RRServer.Tables.DRIVING_SCHOOLS,
        //     //     id: claims.drivingSchoolId
        //     // })
        //     //     .then((result: any) => {
        //     //         this.setState({ schools: result.data.data })
        //     //     })

        //     // RRServer.list({
        //     //     table: RRServer.Tables.USERS,
        //     //     forCurrentUser: false,
        //     //     where: [["drivingSchoolId", "==", claims.drivingSchoolId]]
        //     // })
        //     //     .then((result: any) => {
        //     //         this.setState({
        //     //             users: result.data.data
        //     //         })
        //     //     })

        //     // RRServer.list({
        //     //     table: 'corporate_codes',
        //     //     forCurrentUser: false,
        //     //     where: [["companyId", "==", claims.drivingSchoolId]]
        //     // }).then((result: any) => {
        //     //     this.setState({
        //     //         corporateCodesCount: result.data.data.length,
        //     //         corporateCodes: result.data.data,
        //     //         drivingSchoolCodesCount: result.data.data.filter(({ type }: { type: string }) => type.indexOf('DRIVING_') != -1).length,
        //     //         studentsCount: result.data.data.filter(({ type }: { type: string }) => type.indexOf('DRIVING_') != -1 && type.indexOf('DRIVING_SCHOOL_ADMIN') == -1 && type.indexOf('DRIVING_SCHOOL_INSTRUCTOR') == -1).length,
        //     //         instructorCount: result.data.data.filter(({ type }: { type: string }) => type === 'DRIVING_SCHOOL_INSTRUCTOR').length,
        //     //         adminCount: result.data.data.filter(({ type }: { type: string }) => type === 'DRIVING_SCHOOL_ADMIN').length,
        //     //         loading: false
        //     //     });
        //     // })


        // }



    }

    showDashItem = (loading: boolean, title: String, value: number) => {
        return loading
            ? <Skeleton.Button active={true} size={'large'} />
            : < Statistic
                title={title}
                value={value}
                precision={0}
                valueStyle={{ color: '#3f8600' }
                }
            />
    }

    getScoresForGame = (cid: string) => {
        RRServer.list({ table: RRServer.Tables.SCORES, forCurrentUser: false, where: [["cid", "==", cid]] })
            .then((r: any) => {
                console.log('Scores', r);
                this.setState({ scores: r.data.data })
            })
    }


    render() {
        const { schools, drivingSchoolCodesCount, adminCount, instructorCount, studentsCount, users, loading, scores } = this.state;
        const getUniqueEntriesFromScores = (scores: ScoreInterface[]) => {
            let _s:{[key:string]:boolean} = {};
            let cntr=0;
            scores.map(score => {
                if(!_s[score.uid]){
                    cntr++;
                }
                _s[score.uid] = !!1;
            })
            return cntr;
        };

        let dt = new Date();
        const loginsThisMonth = users.filter(user => {
            if (user.lastLogin) {
                return user.lastLogin > +new Date(dt.getFullYear(), dt.getMonth(), 1, 0, 0, 0, 0)
                    && user.lastLogin < +new Date(dt.getFullYear(), dt.getMonth() + 1, 1, 0, 0, 0, 0)
            } else {
                return false;
            }
        });

        return (
            <div className="container mt-5">
                  <div className="d-flex flex-row justify-content-between align-items-center" style={{ marginTop: '0%'}}>
                    <img alt='logo' className={Style.apLogo} style={{ width: 250, height: 50, marginBottom: '1%', opacity: '1' }} src={asianpaintslogo} />
                    </div>
                <h2>Summary</h2>
                <div className="d-flex justify-content-start align-items-center mt-4">

                    <div className={"d-flex justify-content-center align-items-center flex-column " + Style.card}>
                        <div className={Style.imageWarp}>
                            <img style={{ width: '50%' }} src={'https://res.cloudinary.com/dx1ypopt2/image/upload/v1621084428/user_hwmmhi.svg'} alt='' />
                        </div>
                        <div style={{ marginTop: '10px', textAlign: 'center', color: 'white', marginLeft: '0px', fontSize: '22px', width: '100%'}}>
                            <p>Users Assigned</p>
                            <h3><b>{users.length}</b></h3>
                        </div>
                    </div>
                    <div className={"d-flex justify-content-center align-items-center flex-column " + Style.cardActive}>
                        <div className={Style.imageWarp}>
                            <img style={{ width: '50%' }} src={'https://res.cloudinary.com/dx1ypopt2/image/upload/v1621084428/user_hwmmhi.svg'} alt='' />
                        </div>
                        <div style={{  marginTop: '10px', textAlign: 'center', color: 'white', marginLeft: '0px', fontSize: '22px', width: '100%'}}>
                            <p>Users Activated</p>
                            <h3><b>{getUniqueEntriesFromScores(scores)}</b></h3>
                        </div>
                    </div>                      

                    {/*
                    <div className="d-flex flex-row justify-content-between align-items-center" style={{ marginTop: '2%'}}>
                    <img alt='logo' className={Style.RakshakLogo} style={{ width: 300, marginTop:'95%', opacity: '0' }} src={rakshaklogo} />
                    </div>  
                    */}
                </div>
                <div className="d-flex justify-content-start align-items-center mt-4">
                    {
                    <div className="d-flex flex-row justify-content-between align-items-center" style={{ marginTop: '1%', marginLeft: '75%'}}>
                    <img alt='logo' className={Style.RakshakLogo} style={{ width: 300, marginTop:'95%', opacity: '1' }} src={poweredByImg} />
                    </div>  
                    }
                </div>                
            </div>)
    }
}

export default APDashboard;