import { Card, Tabs, Row, Col } from 'antd';
import React from 'react';
import { CorporateCodeInterface, DrivingSchoolInterface, ScoreInterface, UserInterface } from '../../types';

import { Tooltip, Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis } from 'recharts';

import dayjs from 'dayjs';

// UI Code Importing

// import { Pie } from 'react-chartjs-2';
import Style from './AP-DailyUpdates.module.css';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import rakshaklogo from "../../assets/images/rakshaklogo.png";
import timer from "../../assets/images/timer.png";
import { RRServer } from '../../utils/lib';

const { TabPane } = Tabs;

const CORPORATE_CODE = "RKkKTihRtahnXPFWUN8O";

interface DashProps {
    schools: DrivingSchoolInterface[]
    corporateCodes: CorporateCodeInterface[],
    corporateCodesCount: number,
    drivingSchoolCodesCount: number,
    studentsCount: number,
    instructorCount: number,
    adminCount: number,
    loading: boolean,
    scores: ScoreInterface[],
    users: UserInterface[],
    scoresHashed: any,
    scoresByAttempt: any
    times: number[],
    chartValues: { [key: string]: {} }
}
class APDailyUpdates extends React.Component<RouteComponentProps, DashProps> {


    constructor(props: RouteComponentProps) {
        super(props);

        this.state = {
            loading: true,
            schools: [{ code: "ggwp", currentCount: "10", currentCountAdmin: "1", drivingSchoolName: "ggwp", id: "1054", instructorCurrentCount: "24", officeAdminCurrentCount: "25" }],
            corporateCodes: [{ type: "CORPORATE_USER_AP" }],
            corporateCodesCount: 0,
            drivingSchoolCodesCount: 0,
            studentsCount: 0,
            instructorCount: 0,
            adminCount: 0,
            scores: [],
            users: [],
            scoresHashed: { level: 1, numUsers: 100, score: 1000 },
            scoresByAttempt: {},
            times: [],
            chartValues: {}
        }
    }


    calculateScores = (scores: ScoreInterface[]) => {

        console.log('Scores Filtered', scores)

        let _scores: { [key: string]: any } = {};

        scores.map(score => {
            _scores[score.game] = _scores[score.game] || {
                numUsers: 0,
                score: 0
            };

            _scores[score.game].numUsers += 1;
            _scores[score.game].score += score.score;

        })
        let _scoresByLevel: { [key: number]: any } = {};
        let _scoresByGame: { [key: string]: any } = {};
        let times: number[] = []
        let chartValues: any = {};

        scores.filter(score => score.extra.daily).map(score => {

            _scoresByGame[score.game] = _scoresByGame[score.game] || {
                numUsers: 0,
                score: 0,
                levle: 0,
                game: '',
                A: 0, B: 0, C: 0, D: 0, E: 0
            }

            _scoresByGame[score.game].numUsers += score.attemptNumber==1? 1:0;
            _scoresByGame[score.game].score += score.score;
            _scoresByGame[score.game].level = score.level;
            _scoresByGame[score.game].game = score.game;
            times.push(score.addedOn);

            chartValues[score.game] = chartValues[score.game] || {
                A: 0, B: 0, C: 0, D: 0, E: 0
            }

            if (score.game.indexOf('Humsafar') == -1) {
                _scoresByGame[score.game].A += score.score >= 0 && score.score <= 20 ? 1 : 0;
                _scoresByGame[score.game].B += score.score > 20 && score.score <= 40 ? 1 : 0;
                _scoresByGame[score.game].C += score.score > 40 && score.score <= 60 ? 1 : 0;
                _scoresByGame[score.game].D += score.score > 60 && score.score <= 80 ? 1 : 0;
                _scoresByGame[score.game].E += score.score > 80 && score.score <= 100 ? 1 : 0;

                // chartValues[score.game].push({ name: score.game, ..._scoresByGame[score.game] })

                chartValues[score.game].A += score.score > 0 && score.score <= 20 ? 1 : 0;
                chartValues[score.game].B += score.score > 20 && score.score <= 40 ? 1 : 0;
                chartValues[score.game].C += score.score > 40 && score.score <= 60 ? 1 : 0;
                chartValues[score.game].D += score.score > 60 && score.score <= 80 ? 1 : 0;
                chartValues[score.game].E += score.score > 80 && score.score <= 100 ? 1 : 0;

            }


            _scoresByLevel[score.level] = _scores[score.level] || {
                numUsers: 0,
                score: 0
            };

            _scoresByLevel[score.level].numUsers += 1;
            _scoresByLevel[score.level].score += score.score;
            _scoresByLevel[score.level].level = score.level;

        })
        let _scoresByAttempt: { [key: number]: any } = {
            1: {
                numUsers: 0,
                score: 0,
                time: []
            }, 2: {
                numUsers: 0,
                score: 0,
                time: []
            }
        };

        scores.map(score => {
            // _scoresByAttempt[score.attemptNumber] = _scores[score.attemptNumber] || {
            //     numUsers: 0,
            //     score: 0
            // };

            _scoresByAttempt[score.attemptNumber].numUsers += 1;
            _scoresByAttempt[score.attemptNumber].score += score.score;
            _scoresByAttempt[score.attemptNumber].level = score.level;
            _scoresByAttempt[score.attemptNumber].attemptNumber = score.attemptNumber;
            _scoresByAttempt[score.attemptNumber].time.push(score.addedOn);

        })
        console.log(scores, _scoresByAttempt)
        this.setState({
            scoresHashed: _scoresByGame,
            scoresByAttempt: _scoresByAttempt,
            times,
            chartValues

        })

    }


    componentDidMount() {

        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || '';

        console.log('Claims', claims);
        if (!claims.drivingSchoolId) {
            console.log('Dirving School Not Found');
            return;
        }

        const cid = CORPORATE_CODE;//claims.drivingSchoolId;

        Promise.all([
            RRServer.list({ table: RRServer.Tables.USERS, forCurrentUser: false, where: [["companyId", "==", cid]] }),
            RRServer.list({ table: RRServer.Tables.SCORES, forCurrentUser: false, where: [["cid", "==", cid]] })
        ])
            .then((r: any) => {

                const users = r[0].data.data;
                const scores: ScoreInterface[] = r[1].data.data;

                // this.calculateScores(scores);
                this.setState({
                    users,
                    scores
                })

                const start = dayjs().set('hour', 0).set('minute', 0).set('second', 0).valueOf()
                const end = dayjs().set('hour', 23).set('minute', 59).set('second', 59).valueOf()



                this.calculateScores(scores.filter(s=>s.extra.daily).filter(s=>s.game.indexOf('Humsafar')==-1).filter(s => s.addedOn > start && s.addedOn < end))



            })



    }


    changeDate = (ev: React.ChangeEvent<HTMLInputElement>) => {

        const scores = this.state.scores;


        const start = dayjs(ev.target.value).valueOf()
        const end = dayjs(ev.target.value).add(1, 'day').valueOf()

        console.log('Scores', scores)
        console.log('dates', start, end, scores)

        this.calculateScores(scores.filter(s => s.addedOn > start && s.addedOn < end))

    }


    render() {
        const { scoresHashed, scoresByAttempt, times, chartValues } = this.state;

        let min1Time = 0, max1Time = 0;
        let min2Time = 0, max2Time = 0;
        if (scoresByAttempt[1]) {
            min1Time = Math.min.apply(this, scoresByAttempt[1].time);
            max1Time = Math.max.apply(this, scoresByAttempt[1].time);
            console.log(min1Time, max1Time)
        }
        if (scoresByAttempt[2]) {
            min2Time = Math.min.apply(this, scoresByAttempt[2].time);
            max2Time = Math.max.apply(this, scoresByAttempt[2].time);
            console.log(min2Time, max2Time)
        }
        if (times.length) {
            min1Time = Math.min.apply(this, times);
            max1Time = Math.max.apply(this, times);
            console.log(min1Time, max1Time)
        }



        // let data:any = [{ name: "Sign Factory 2", A: 7, B: 1, C: 1, D: 2, E: 3 }];
        let data:any = [];


        for (let c in chartValues) {
            data.push({ name: c, ...chartValues[c] })
        }

        console.log(data)

        const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#ef869e", "#71deb9"];


        console.log(chartValues)

        return (
            <div className="container mt-5" style={{marginTop: '-2%'}}>
                <h2 className={Style.title}>TODAYS UPDATES</h2>
                <div className={Style.spacer} />

                <div className={Style.spacer} />

                <div className={Style.spacer} />

                <Row>
                    <Col span={24}>
                        <div className="d-flex flex-row justify-content-between align-items-center" style={{ marginTop: '0%' }}>
                            <input type="date" className="form-control" style={{ width: '15%' }} onChange={this.changeDate} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className="d-flex flex-row justify-content-between align-items-center" style={{ marginTop: '2%' }}>
                            <h2 className={Style.header}>QUANTITATIVE ANALYSIS</h2>
                            {/* <input type="date" className="form-control" style={{ width: '15%' }} onChange={this.changeDate} /> */}
                        </div>

                        <div className="row justify-content-center align-items-start mt-5">
                            <div className="col-9">
                                <Card className={Style.tableCard} >
                                    <table className="mb-0" style={{ marginLeft: '0vw', width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th className={Style.tableHeading}>Game</th>
                                                <th className={Style.tableHeading}># Users Attempted</th>
                                                <th className={Style.tableHeading}>Average Score</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.values(scoresHashed).map((score: any) =>
                                                <tr>
                                                    <td scope="row">{score.game}</td>
                                                    <td>{score.numUsers}</td>
                                                    <td>{(score.score / score.numUsers).toFixed(2)}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </Card>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row style={{marginTop: '2%'}}>
                    <Col span={12}>
                        <BarChart width={500} height={250} data={Object.values(chartValues)}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="A" fill={COLORS[0]} />
                            <Bar dataKey="B" fill={COLORS[1]} />
                            <Bar dataKey="C" fill={COLORS[2]} />
                            <Bar dataKey="D" fill={COLORS[3]} />
                            <Bar dataKey="E" fill={COLORS[4]} />
                        </BarChart>
                    </Col>
                    <Col span={12}>
                        <Card >
                            <div className={Style.timerContainer}>
                                <div>
                                    <img alt='timer' src={timer} className={Style.timerImage} />
                                </div>
                                <div>
                                    <p style={{ fontSize: '1.5rem', opacity: 1, fontWeight: 700, textAlign: 'right', height: '1vh' }}>Most Played Time of the Day </p><br />
                                    <h2> {dayjs(min1Time).format('HH:mm a')}  to {dayjs(min2Time).format('HH:mm a')}</h2>
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>




                <div className="d-flex flex-row justify-content-between align-items-center" style={{ marginTop: '2%' }}>
                    <h2 className={Style.header}>QUALITATIVE ANALYSIS</h2>
                    {/* <input type="date" className="form-control" style={{ width: '15%' }} /> */}
                </div>
                <div style={{ marginLeft: '3vw', width: '38%', height: '2px', borderBottom: '0.5px solid rgba(0,0,0,0.1)' }} ></div>
                <div className="row d-flex justify-content-center align-items-start mt-5">
                    <div className="col-9">
                        <Card className={Style.tableCard} >
                            <table className="mb-0" style={{}}>
                                <thead>
                                    <tr style={{ fontWeight: 100 }}>
                                        <th className={Style.QuantitativeTH}># Users Cleared in 1st Attempt</th>
                                        <th className={Style.QuantitativeTH}># Users Cleared in 2nd Attempt</th>
                                        <th className={Style.QuantitativeTH}>1st Attempt Average Score</th>
                                        <th className={Style.QuantitativeTH}>2nd  Attempt Average Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!scoresByAttempt[1] ? "" :
                                        <tr>
                                            <td scope="row">{scoresByAttempt[1].numUsers || 0}</td>
                                            <td>{scoresByAttempt[2].numUsers || 0}</td>
                                            <td>{Number(!scoresByAttempt[1].score ? 0 : (scoresByAttempt[1].score) / (scoresByAttempt[1].numUsers)).toFixed(2)}</td>
                                            <td>{Number(!scoresByAttempt[2].score ? 0 : scoresByAttempt[2].score / (scoresByAttempt[2].numUsers)).toFixed(2)}</td>
                                        </tr>                                        
                                    }
                                </tbody>
                            </table>
                        </Card>
                    </div>
                    {/*<div className="col" style={{ height: '100%' }}>
                        <Card className={Style.playedCard}>
                            <div>
                                <p style={{ fontSize: '1.5rem', opacity: 0.7 }}>Most Played Time of the Day </p><br />
                                <h2> {dayjs(min1Time).format('HH:mm a')}  to {dayjs(min2Time).format('HH:mm a')}</h2>
                            </div>
                        </Card>
                                </div>*/}
                </div>
                <div className={Style.spacer} />

                <div className="d-flex flex-row justify-content-between align-items-center" style={{ marginTop: '2%'}}>
                    <img alt='logo' className={Style.RakshakLogo} style={{ width: 100 }} src={rakshaklogo} />
                </div>                

                {/*<div className="d-flex justify-content-start align-items-center mt-4">

                    <Card>
                        <a href='/ap-dashboard'>Home</a>
                    </Card>

                            </div>*/}

            </div>)
    }
}

export default withRouter(APDailyUpdates);