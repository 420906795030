import React from 'react';
import firebase from '../../utils/Firebase';
import { PageHeader } from 'antd';
import { Link, Route, withRouter, Switch } from 'react-router-dom';
import DrivingSchoolUserAdd from './DrivingSchoolUserAdd';
import { OrderedListOutlined, UserAddOutlined } from '@ant-design/icons';
import UserList from './UserList';
import { getClaims, RRServer } from '../../utils/lib';
import { DrivingSchoolInterface } from '../../types';




// interface UserStateProps {
//     schools: DrivingSchoolInterface[]
//     selectedSchool: DrivingSchoolInterface | null
//     userCode: string
// }

class User extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state={
            drivingSchoolId:''
        }
    }

    componentDidMount(){
        getClaims().then(claims => {
            if (!claims || !!!claims['drivingSchoolId']) { return null }
            const drivingSchoolId = claims ? claims['drivingSchoolId'] : '';
            this.setState({drivingSchoolId})

            const userPromise = drivingSchoolId === '*'
        })
    }

    render() {
        let { path, url } = this.props.match;
        console.log('P2', this.props.drivingSchool)
        const currentSchool=this.props.drivingSchool || {};

        // const claimsString = localStorage.getItem('claims') || '{}';
        // const claims = JSON.parse(claimsString);
        // claims.drivingSchoolId = claims.drivingSchoolId || ''

        // let extra = [
        //     <Link to={`${url}`} key='1'>
        //         List<OrderedListOutlined />
        //     </Link>,
        // ];
        // if (claims.drivingSchoolId === '*') {
        //     extra.push(<Link to={`${url}/add`} key='0'>
        //         Add School<UserAddOutlined />
        //     </Link>)
        // }



        return (
            <div className="App">
                <main>
                    <PageHeader className="user-page-header"
                        ghost={false}
                        onBack={() => window.history.back()}
                        title="Users"
                        subTitle=""
                        extra={[
                            (this.state.drivingSchoolId =='*' || currentSchool.codesRemaining > 0) ?
                            <Link to={`${url}/add-ds-user`} key='0' style={{color: 'wheat', fontSize: '2.5vh'}}>
                                Add Driving School User<UserAddOutlined />
                            </Link>
                            :'',
                            <Link to={`${url}`} key='1' style={{color: 'wheat', fontSize: '2.5vh'}}>
                                List<OrderedListOutlined />
                            </Link>,
                        ]}
                    >
                    </PageHeader>

                    <Switch>
                        <Route exact path={path}>
                            <UserList drivingSchool={this.props.drivingSchool} />
                        </Route>
                        <Route path={`${path}/add-ds-user`}>
                            <DrivingSchoolUserAdd />
                        </Route>
                        <Route path={`${path}/edit-ds-user/:id`}>
                            <DrivingSchoolUserAdd />
                        </Route>
                    </Switch>

                </main>
            </div>
        );
    }
}

export default withRouter(User);