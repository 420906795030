import { Row, Col, Card, Tabs, Skeleton, Input } from "antd";
import Statistic from "antd/lib/statistic";
import firebase from "firebase";
import React from "react";
import {
  CorporateCodeInterface,
  DrivingSchoolInterface,
  UserInterface,
} from "../../types";

import dayjs from "dayjs";
/*import DrivingSchoolAdd from "../DrivingSchools/DrivingSchoolAdd";*/

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import Style from './AP-EmployeeStats.module.css';
import { RRServer } from "../../utils/lib";
import APToolbar from "./AP-Buttons-Toolbar";
import Search from "antd/lib/input/Search";

const { TabPane } = Tabs;

const CORPORATE_CODE = "RKkKTihRtahnXPFWUN8O";


interface DashProps {
  schools: DrivingSchoolInterface[];
  users: UserInterface[];
  usersFiltered: UserInterface[];
  corporateCodes: CorporateCodeInterface[];
  corporateCodesCount: number;
  drivingSchoolCodesCount: number;
  studentsCount: number;
  instructorCount: number;
  adminCount: number;
  loading: boolean;
}
class APEmployeeStats extends React.Component<any, DashProps> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      schools: [],
      users: [],
      corporateCodes: [],
      corporateCodesCount: 0,
      drivingSchoolCodesCount: 0,
      studentsCount: 0,
      instructorCount: 0,
      adminCount: 0,
      usersFiltered:[]
    };
  }


  componentDidMount() {
    RRServer.list({ table: RRServer.Tables.USERS, forCurrentUser: false, where: [["companyId", "==", CORPORATE_CODE]] })
      .then((r: any) => {
        const users= r.data.data.sort((a: UserInterface, b: UserInterface) => (a.code.localeCompare(b.code)))
        this.setState({ users,usersFiltered:users })
      })
  }

  onSearch=(text:string)=>{

    const {users}=this.state;

    const usersFiltered=users.filter(u=>u.name.toLowerCase().indexOf(text.toLowerCase())!=-1);

    this.setState({usersFiltered})

  }



  render() {

    const { users,usersFiltered } = this.state;

    const data = {
      labels: [
        'Cars',
        'Trains',
        'Airplanes'
      ],
      datasets: [{
        data: [300, 50, 100],
        backgroundColor: [
          '#8dace7',
          '#71deb9',
          '#ef869e'
        ],
        hoverBackgroundColor: [
          '#8dace7',
          '#71deb9',
          '#ef869e'
        ]
      }]
    };

    return (
      <div className="container mt-5">
        <h2>Employee Stats</h2>
        <div className={Style.spacer} />
        <div
          style={{
            width: "100%",
            height: "2px",
            borderBottom: "0.5px solid rgba(0,0,0,0.1)",
          }}
        ></div>
        <div className={Style.spacer} />

        <div className="row d-flex justify-content-start align-items-start mt-5">
          <div className="col-9" style={{width: '100%'}}>
            <Search placeholder="Filter" allowClear onSearch={this.onSearch} style={{ width: 200 }} />
            <Card className={Style.tableCard}>
              <table className="mb-0" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th className={Style.tableHeading}>Corporate ID</th>
                    <th className={Style.tableHeading}>Employee Name</th>
                  </tr>
                </thead>
                <tbody>
                  {usersFiltered.map(user => <tr key={user.id}>
                    <td>
                      <a href={'/ap-employee-stat/' + user.id}>{user.code}</a>
                    </td>
                    <td>{user.name}</td>
                  </tr>)}
                </tbody>
              </table>
            </Card>
          </div>
        </div>

      </div>
    );
  }
}

export default APEmployeeStats;
