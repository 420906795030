import React from 'react';
import { PageHeader } from 'antd';
import { Link, Route, withRouter, Switch } from 'react-router-dom';

import DrivingSchoolAdd from './DrivingSchoolAdd';
import DrivingSchoolList from './DrivingSchoolList';
import DrivingSchoolView from './DrivingSchoolView';

import { OrderedListOutlined, UserAddOutlined } from '@ant-design/icons';



interface UserStateProps {

}

class DrivingSchools extends React.Component<any, UserStateProps> {
    constructor(props: any) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
    }

    render() {
        let { path, url } = this.props.match;

        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || ''

        let extra = [

            <Link to={`${url}`} key='1'>
                List<OrderedListOutlined />
            </Link>,

        ];
        if (claims.drivingSchoolId === '*') {
            extra.push(<Link to={`${url}/add`} key='0'>
                Add School<UserAddOutlined />
            </Link>)
        }

        return (
            <div className="App">
                <main>
                    <PageHeader
                        ghost={false}
                        onBack={() => window.history.back()}
                        title="Driving Schools"
                        subTitle=""
                        extra={extra}
                    >
                    </PageHeader>

                    <Switch>
                        <Route exact path={path}>
                            <DrivingSchoolList />
                        </Route>
                        <Route path={`${path}/add`}>
                            {claims.drivingSchoolId === '*' ? <DrivingSchoolAdd /> : <div>Not Allowed</div>}
                        </Route>
                        <Route path={`${path}/edit/:id`}>
                            {claims.drivingSchoolId === '*' ? <DrivingSchoolAdd /> : <div>Not Allowed</div>}
                        </Route>
                        <Route path={`${path}/view/:id`}>
                            <DrivingSchoolView />
                        </Route>
                    </Switch>

                </main>
            </div>
        );
    }
}

export default withRouter(DrivingSchools);