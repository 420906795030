import React from 'react';
import { Button, Divider, Form, Input, message, Typography, Steps, Modal, Table, Select, Card, Checkbox, DatePicker } from 'antd';
import { withRouter } from 'react-router-dom';
import { DrivingSchoolInterface, InstructorInterface, UserInterface, DAYS, DayType, StudentInterface, EMPTY_SLOTS, DateTimeType, DRIVING_SCHOOL_USER_TYPES, InstallmentInterface } from '../../types';
// import Select, { OptionsType } from 'react-select'
import commonStyles from '../../common/css/common.module.css';
import { formatTime, getClaims, RRServer } from '../../utils/lib';
import TableSelect from './sub-componnets/TableSelect';
import dayjs from 'dayjs';

import firebase from '../../utils/Firebase'

import Styles from './DrivingSchoolUserAdd.module.css';
import moment from 'moment';
import Installments from '../Installments/Installments';


const { Title } = Typography;
const { Step } = Steps;
const { Option } = Select;



interface DrivingSchoolUserAddProps {

    mode: "edit" | "add",
    loading: boolean,

}

interface InstallmentCreateInterface {
    // numberOfInstallments: number
    uid: string
    companyId: string
}

export interface InstallmentLocalInterface {
    dueDate: string;
    id?: string
    uid?: string
    companyId?: string
    status: number
    paidOn: number
    installment: string,

}

class CorporateAdd extends React.Component<any, DrivingSchoolUserAddProps> {
    dsDropdown: React.RefObject<unknown>;
    CLASS_SCHEDULES: DateTimeType[] = [];
    unregisterAuthObserver: firebase.Unsubscribe | undefined;

    constructor(props: any) {
        super(props);
        this.state = {
            mode: !!props.match.params.id ? 'edit' : 'add',
            loading: false
        }
        this.onFinish = this.onFinish.bind(this);
        this.dsDropdown = React.createRef();
    }





    onFinish(values: any) {
        values.currentAdminCount = 0;
        values.currentUserCount = 0;
        console.log('Finish', values)

        this.saveCorporateUser(values)
    };

    onFinishFailed(errorInfo: any) {
        console.log('Failed:', errorInfo);
    };

    onSearch() { }

    onFormValueChange = (form: { [key: string]: any }) => {
    }

    saveCorporateUser(values: any) {
        this.setState({ loading: true })
        RRServer.callFn('corporate-add', values)
            .then((r: any) => {
                if (r.data.s) {
                    this.setState({ loading: false })
                    alert('Added Corporate')
                }
            })
    }

    render() {
        const { mode, loading } = this.state;
        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || '#'

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        const tailLayout = {
            wrapperCol: { offset: 8, span: 16 },
        };


        return (
            <main>
                <Divider />

                <Title>{mode === 'edit' ? 'Edit ' : 'Add '} Corporate</Title>

                <div style={{ width: '400px' }}>
                    <Divider />

                    <div>


                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{
                                // ...props.user
                            }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                            onValuesChange={this.onFormValueChange}
                        >
                            <Form.Item
                                name="identifier"
                                label="Identifier"
                                hasFeedback
                                rules={[{ required: true, message: 'Please select  user type!' }]}
                            >
                                <Input placeholder="Identifier" name="corporate_identifier" value={'CORPORATE_USER_AP'} />
                            </Form.Item>
                            <Form.Item
                                label="Name"
                                name="corporateName"
                                rules={[{ required: true, message: 'Name' }]}
                            >
                                <Input placeholder="Name" name="name" />
                            </Form.Item>
                            <Form.Item
                                label="Code"
                                name="code"
                            >
                                <Input placeholder="Code" />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{ required: true, message: 'Password' }]}
                            >
                                <Input placeholder="Password" />
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <Button type="primary" htmlType="submit" loading={loading}  >
                                    {mode === 'edit' ? 'Edit' : 'Create Corporate'}
                                </Button>
                            </Form.Item>

                        </Form>






                    </div>

                </div>


            </main>
        )
    }
}

// export default withRouter(UserAdd);
export default withRouter(CorporateAdd);