import { GenericObject } from '../types';
import firebase from './Firebase';


export const callOnServer = (fnName: string, params: any) => {
    const fn = firebase.functions().httpsCallable(fnName);
    return fn(params)
        .catch(function (error) {
            console.log(error);
        })
}

interface UpdateParamsInterface {
    table: string
    id: string
    [key: string]: any
}
interface GetParamsInterface {
    table: string
    id: string
}
type FFQuery = { 0: string, 1: "==", 2: string | number | boolean };
interface ListParamsInterface {
    table: string
    where?: FFQuery[]
    forCurrentUser: boolean
}
interface RemoveParamsInterface {
    table: string
    where?: FFQuery[]
    id?: string
}
interface AddParamsInterface {
    table: string
    data: any
}
interface AddMultipleParamsInterface {
    table: string
    data: any[]
}

type TableKeysType = "USERS" | "DRIVING_SCHOOLS" | "CLASS_SCHEDULES" | "INSTALLMENTS" | "ENGAGEMENTS" | "CORPORATES" | "SCORES";
type TablesType = {
    [key in TableKeysType]: string;
};


export class RRServer {
    static addMultiple(params: AddMultipleParamsInterface) {
        return callOnServer('common-addMultiple', params)
    }
    static add(params: AddParamsInterface) {
        return callOnServer('common-add', params)
    }
    static list(params: ListParamsInterface) {
        return callOnServer('common-list', params)
    }
    static get(params: GetParamsInterface) {
        return callOnServer('common-get', params)
    }
    static update(params: UpdateParamsInterface) {
        return callOnServer('common-update', params)
    }
    static remove(params: RemoveParamsInterface) {
        return callOnServer('common-remove', params)
    }
    static callFn(fnName: string, params: any) {
        return callOnServer(fnName, params)
    }
    static Tables: TablesType = {
        USERS: "users",
        DRIVING_SCHOOLS: "driving_schools",
        CLASS_SCHEDULES: "class_schedules",
        INSTALLMENTS: "installments",
        ENGAGEMENTS: "engagements",
        CORPORATES:"corporate_users",
        SCORES:"scores"
    }
    static FNS: GenericObject = {
        PROFILE: {
            UPDATE: "profile-update"
        }
    }
}

export const formatTime = (dateStr: string) => {
    const dt = new Date(dateStr)
    const h = dt.getHours();
    const m = dt.getMinutes();
    return (h < 10 ? ('0' + h) : h) + ':' + (m < 10 ? ('0' + m) : m);
}


export const getClaims = (): Promise<void | { [key: string]: any; }> => {


    return new Promise((res, rej) => {

        firebase.auth().onAuthStateChanged(currentUser => {
            if (currentUser) {
                console.log("state = definitely signed in");
                currentUser.getIdTokenResult()
                    .then((idTokenResult) => {
                        console.log('Claims', idTokenResult.claims);
                        localStorage.setItem('claims', JSON.stringify(idTokenResult.claims));
                        return res(idTokenResult.claims);
                    })
                    .catch((error) => {
                        console.log(error);
                        rej()
                    });
            }
            else {
                console.log("state = definitely signed out");
            }
        })


    })

    // const currentUser = firebase.auth().currentUser;
    // return !!!currentUser
    //     ? new Promise((res => { res({} as firebase.auth.IdTokenResult) }))
    //     : currentUser.getIdTokenResult()
    //         .then((idTokenResult) => {
    //             console.log('Claims', idTokenResult.claims);
    //             localStorage.setItem('claims', JSON.stringify(idTokenResult.claims));
    //             return idTokenResult.claims
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
}


export const getLoggedInUser = () => {
    const userStr = localStorage.getItem('user') || '{}';
    const user = JSON.parse(userStr);
    return user.user;
}
export  const getLoggedInUserName=()=>{
    const user=getLoggedInUser();
    return user.displayName;
}