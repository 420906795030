import React from 'react';
import firebase from '../../utils/Firebase';
import { Button, Divider, Input, Select, Space, Table } from 'antd';
import { EditFilled, EyeFilled, SearchOutlined } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import { callOnServer, RRServer } from '../../utils/lib';
import { InstallmentInterface, UserInterface } from '../../types';
import Highlighter from 'react-highlight-words';

const { Option } = Select;

interface DrivingSchoolInterface {
    code: string;
    id: string
    drivingSchoolName: string
    currentCount: string
}

interface InstallmentStateProps {
    schools: DrivingSchoolInterface[]
    selectedSchool: DrivingSchoolInterface | null
    schoolsHashed: { [key: string]: DrivingSchoolInterface }
    users: UserInterface[],
    usersHashed: { [key: string]: UserInterface }
    userCode: string
    filterSchoolId: string,
    installments: InstallmentInterface[],
    searchText: string,
    searchedColumn: string,
    filteredInfo: any
}

class EngagementList extends React.Component<any, InstallmentStateProps> {
    searchInput: Input | null | undefined;
    constructor(props: any) {
        super(props);
        this.state = {
            schools: [],
            schoolsHashed: {},
            selectedSchool: null,
            users: [],
            usersHashed: {},
            userCode: '',
            filterSchoolId: '',
            installments: [],
            searchText: '',
            searchedColumn: '',
            filteredInfo: {}

        }
        this.getSchool = this.getSchool.bind(this);
    }

    componentDidMount() {

        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || '#'

        if (claims.drivingSchoolId !== '*' || claims.drivingSchoolId !== '#'  ) {

            Promise.all([
                RRServer.get({ table: 'driving_schools', id: claims.drivingSchoolId }),
                RRServer.list({ table: RRServer.Tables.USERS, forCurrentUser: false, where: [["companyId", "==", claims.drivingSchoolId], ["type", "==", "DRIVING_SCHOOL_USER"]] }),
                RRServer.list({ table: RRServer.Tables.INSTALLMENTS, forCurrentUser: false, where: [["companyId", "==", claims.drivingSchoolId]] }),

            ])
                .then((results: any) => {
                    let schools = [results[0].data.data];
                    let users = results[1].data.data;
                    let installments = results[2].data.data;
                    let usersHashed: { [key: string]: UserInterface } = {};
                    let schoolsHashed: { [key: string]: DrivingSchoolInterface } = {};
                    users.map((user: UserInterface) => usersHashed[user.id] = user)
                    schools.map((school: DrivingSchoolInterface) => schoolsHashed[school.id] = school)
                    this.setState({ schools, users, usersHashed, installments, schoolsHashed })
                })


        } else {

            Promise.all([
                RRServer.list({ table: 'driving_schools', forCurrentUser: false }),
                RRServer.list({ table: RRServer.Tables.USERS, forCurrentUser: false, where: [["type", "==", "DRIVING_SCHOOL_USER"]] }),
                RRServer.list({ table: RRServer.Tables.INSTALLMENTS, forCurrentUser: false }),

            ])
                .then((results: any) => {
                    let schools = results[0].data.data;
                    let users = results[1].data.data;
                    let installments = results[2].data.data;
                    let usersHashed: { [key: string]: UserInterface } = {};
                    let schoolsHashed: { [key: string]: DrivingSchoolInterface } = {};
                    users.map((user: UserInterface) => usersHashed[user.id] = user)
                    schools.map((school: DrivingSchoolInterface) => schoolsHashed[school.id] = school)
                    this.setState({ schools, users, usersHashed, installments, schoolsHashed })
                })
        }


    }

    getSchool(schoolId: string) {
        const school = this.state.schools.filter(school => school.id = schoolId);
        return school[0];
    }


    handleChange = (pagination: any, filters: any, sorter: any) => {
        console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            filteredInfo: filters,
        });
    };


    render() {
        const { schools, filterSchoolId, usersHashed, users, installments, schoolsHashed, filteredInfo } = this.state;
        let { url } = this.props.match;


        let dataSource = installments.map(installment => ({ key: installment.id, ...installment, status: installment.status ? 'Yes' : 'No' }))

        if (filterSchoolId) {
            dataSource = dataSource.filter(d => d.companyId === filterSchoolId)
        }

        const columns = [
            // {
            //     title: 'ID',
            //     dataIndex: 'id',
            //     key: 'id',
            // },
            {
                title: 'Name',
                key: 'name',
                render: (installment: InstallmentInterface) => {
                    const school = schoolsHashed[installment.companyId];
                    return school ? school.drivingSchoolName : installment.companyId
                }
            },
            {
                title: 'User',
                key: 'user',

                render: (installment: InstallmentInterface) => {
                    const user = usersHashed[installment.uid];
                    return user ? user.name : installment.uid
                }
            },
            {
                title: 'Installments',
                dataIndex: 'installment',
                key: 'installment',
            },
            {
                title: 'Status',
                key: 'status',
                dataIndex: 'status',
                // render: (installment: InstallmentInterface) => {
                //     return installment.status ? 'Yes' : 'No'
                // },
                filters: [
                    { text: 'Yes', value: 'Yes' },
                    { text: 'No', value: 'No' },
                ],
                filteredValue: filteredInfo.status || null,
                onFilter: (value: any, record: { status: any; }) => record.status == value,
            },

        ];

        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || '#'

        if (claims.drivingSchoolId == '*') {
            (columns as any).push({
                title: 'Action',
                key: 'action',
                sorter: true,
                render: ({ id }: { id: string }) => (
                    <Space split={<Divider type="vertical" />}>
                        <Link to={`${url}/edit/${id}`} key='edit-0'>
                            <EditFilled />
                        </Link>
                        <Link to={`${url}/view/${id}`} key='view-1'>
                            <EyeFilled />
                        </Link>
                        {/* <Link to={`${url}/view/${id}`} key='view-1'>
                        <DeleteFilled />
                    </Link> */}
                    </Space>
                ),
            })
        }

        return (
            <main>
                <div>
                    <Select
                        showSearch
                        style={{ width: 200, opacity: 0 }}
                        placeholder="Filter School"
                        optionFilterProp="children"
                        onChange={(id) => this.setState({ filterSchoolId: id + "" })}
                        // onFocus={onFocus}
                        // onBlur={onBlur}
                        // onSearch={onSearch}
                        filterOption={(input, option) => {
                            return option!.children ? option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                        }
                        }
                    >
                        {schools.map(school => <Option key={school.id} value={school.id}>{school.drivingSchoolName}</Option>)}

                    </Select>
                </div>
                <Table className="EngagementsTable" rowClassName={(record, index) => record.status == 'Yes' ? 'green' : 'red'} dataSource={dataSource} columns={columns} onChange={this.handleChange} />
            </main>
        );
    }
}

// export default withRouter(UserAdd);
export default withRouter(EngagementList);