import { Row, Col, Card, Tabs, Skeleton } from "antd";
import Statistic from "antd/lib/statistic";
import React from "react";
import {
  CorporateCodeInterface,
  DrivingSchoolInterface,
  UserInterface,
} from "../../types";
import { RRServer } from "../../utils/lib";

import { PieChart, Pie, Tooltip, ResponsiveContainer } from "recharts";


import Style from './AP-AssessmentScores.module.css'

const { TabPane } = Tabs;

interface DashProps {
  schools: DrivingSchoolInterface[];
  users: UserInterface[];
  corporateCodes: CorporateCodeInterface[];
  corporateCodesCount: number;
  drivingSchoolCodesCount: number;
  studentsCount: number;
  instructorCount: number;
  adminCount: number;
  loading: boolean;
}
class APAssessmentScores extends React.Component<null, DashProps> {
  constructor(props: null) {
    super(props);

    this.state = {
      loading: true,
      schools: [],
      users: [],
      corporateCodes: [],
      corporateCodesCount: 0,
      drivingSchoolCodesCount: 0,
      studentsCount: 0,
      instructorCount: 0,
      adminCount: 0,
    };
  }

  componentDidMount() {
    const claimsString = localStorage.getItem("claims") || "{}";
    const claims = JSON.parse(claimsString);
    claims.drivingSchoolId = claims.drivingSchoolId || "";

    console.log("Claims", claims);
    if (!claims.drivingSchoolId) {
      alert("Dirvind Shcool Not Found");
      return;
    }

    if (claims.drivingSchoolId === "*") {
      RRServer.list({
        table: RRServer.Tables.DRIVING_SCHOOLS,
        forCurrentUser: false,
      }).then((result: any) => {
        this.setState({ schools: result.data.data });
      });

      RRServer.list({
        table: RRServer.Tables.USERS,
        forCurrentUser: false,
      }).then((result: any) => {
        this.setState({
          users: result.data.data,
        });
      });

      RRServer.list({
        table: "corporate_codes",
        forCurrentUser: false,
      }).then((result: any) => {
        this.setState({
          corporateCodesCount: result.data.data.length,
          corporateCodes: result.data.data,
          drivingSchoolCodesCount: result.data.data.filter(
            ({ type }: { type: string }) =>
              type ? type.indexOf("DRIVING_") != -1 : false
          ).length,
          studentsCount: result.data.data.filter(({ type }: { type: string }) =>
            type
              ? type.indexOf("DRIVING_") != -1 &&
              type.indexOf("DRIVING_SCHOOL_ADMIN") == -1 &&
              type.indexOf("DRIVING_SCHOOL_INSTRUCTOR") == -1
              : false
          ).length,
          instructorCount: result.data.data.filter(
            ({ type }: { type: string }) => type === "DRIVING_SCHOOL_INSTRUCTOR"
          ).length,
          adminCount: result.data.data.filter(
            ({ type }: { type: string }) => type === "DRIVING_SCHOOL_ADMIN"
          ).length,
          loading: false,
        });
      });
    } else {
      RRServer.get({
        table: RRServer.Tables.DRIVING_SCHOOLS,
        id: claims.drivingSchoolId,
      }).then((result: any) => {
        this.setState({ schools: result.data.data });
      });

      RRServer.list({
        table: RRServer.Tables.USERS,
        forCurrentUser: false,
        where: [["drivingSchoolId", "==", claims.drivingSchoolId]],
      }).then((result: any) => {
        this.setState({
          users: result.data.data,
        });
      });

      RRServer.list({
        table: "corporate_codes",
        forCurrentUser: false,
        where: [["companyId", "==", claims.drivingSchoolId]],
      }).then((result: any) => {
        this.setState({
          corporateCodesCount: result.data.data.length,
          corporateCodes: result.data.data,
          drivingSchoolCodesCount: result.data.data.filter(
            ({ type }: { type: string }) => type.indexOf("DRIVING_") != -1
          ).length,
          studentsCount: result.data.data.filter(
            ({ type }: { type: string }) =>
              type.indexOf("DRIVING_") != -1 &&
              type.indexOf("DRIVING_SCHOOL_ADMIN") == -1 &&
              type.indexOf("DRIVING_SCHOOL_INSTRUCTOR") == -1
          ).length,
          instructorCount: result.data.data.filter(
            ({ type }: { type: string }) => type === "DRIVING_SCHOOL_INSTRUCTOR"
          ).length,
          adminCount: result.data.data.filter(
            ({ type }: { type: string }) => type === "DRIVING_SCHOOL_ADMIN"
          ).length,
          loading: false,
        });
      });
    }
  }

  showDashItem = (loading: boolean, title: String, value: number) => {
    return loading ? (
      <Skeleton.Button active={true} size={"large"} />
    ) : (
      <Statistic
        title={title}
        value={value}
        precision={0}
        valueStyle={{ color: "#3f8600" }}
      />
    );
  };

  render() {
    const {
      schools,
      drivingSchoolCodesCount,
      adminCount,
      instructorCount,
      studentsCount,
      users,
      loading,
    } = this.state;

    let dt = new Date();
    const loginsThisMonth = users.filter((user) => {
      if (user.lastLogin) {
        return (
          user.lastLogin >
          +new Date(dt.getFullYear(), dt.getMonth(), 1, 0, 0, 0, 0) &&
          user.lastLogin <
          +new Date(dt.getFullYear(), dt.getMonth() + 1, 1, 0, 0, 0, 0)
        );
      } else {
        return false;
      }
    });

    const data01 = [
      { name: "Group A", value: 400 },
      { name: "Group B", value: 300 },
      { name: "Group C", value: 300 },
      { name: "Group D", value: 200 },
      { name: "Group E", value: 278 },
      { name: "Group F", value: 189 },
    ];

    const data = {
      labels: [
        'Cars',
        'Trains',
        'Airplanes'
      ],
      datasets: [{
        data: [300, 50, 100],
        backgroundColor: [
          '#8dace7',
          '#71deb9',
          '#ef869e'
        ],
        hoverBackgroundColor: [
          '#8dace7',
          '#71deb9',
          '#ef869e'
        ]
      }]
    };

    return (
      <div className="container mt-5">
        <h2>Assessment Scores</h2>
        <div className={Style.spacer} />
        <div
          style={{
            width: "100%",
            height: "2px",
            borderBottom: "0.5px solid rgba(0,0,0,0.1)",
          }}
        ></div>
        <div className={Style.spacer} />

        <div
          className="d-flex flex-row justify-content-between align-items-center"
          style={{ marginTop: "2%" }}
        >
          <h2 className={Style.header}>Pre-Assessment Scores</h2>
          <input
            type="date"
            className="form-control"
            style={{ width: "15%" }}
          />
        </div>

        <div className="row d-flex justify-content-center align-items-start mt-5">
          <div className="col-9">
            <Card className={Style.tableCard}>
              <table className="mb-0" style={{}}>
                <thead>
                  <tr>
                    <th># Employees Attempted</th>
                    <th>Average Score</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">34</th>
                    <td>34</td>
                  </tr>
                  <tr>
                    <th scope="row">34</th>
                    <td>34</td>
                  </tr>
                  <tr>
                    <th scope="row">34</th>
                    <td>34</td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </div>
          <div className="col">
            <PieChart width={400} height={400}>
              <Pie data={data01} dataKey="value" cx="50%" cy="50%" outerRadius={60} fill="#8884d8" />
            </PieChart>
          </div>
        </div>
        <div className={Style.spacer} />
        <div
          style={{
            width: "100%",
            height: "2px",
            borderBottom: "0.5px solid rgba(0,0,0,0.1)",
          }}
        ></div>

        <div
          className="d-flex flex-row justify-content-between align-items-center"
          style={{ marginTop: "2%" }}
        >
          <h2 className={Style.header}>Post-Assessment Scores</h2>
          <input
            type="date"
            className="form-control"
            style={{ width: "15%" }}
          />
        </div>

        <div className="row d-flex justify-content-center align-items-start mt-5">
          <div className="col-9">
            <Card className={Style.tableCard}>
              <table className="mb-0" style={{}}>
                <thead>
                  <tr style={{ fontWeight: 100 }}>
                    <th># Employees Attempted</th>
                    <th>Average Score</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>32</td>
                    <td>65</td>
                  </tr>
                  <tr>
                    <td>32</td>
                    <td>65</td>
                  </tr>
                  <tr>
                    <td>32</td>
                    <td>65</td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </div>
          <div className="col" style={{ height: "100%" }}>
            <Card className={Style.playedCard}>
              <div>
                <p style={{ fontSize: "1.5rem", opacity: 0.7 }}>
                  Most Played Time of the Day{" "}
                </p>
                <br />
                <h2> 2PM to 5PM</h2>
              </div>
            </Card>
          </div>
        </div>
        <div className={Style.spacer} />
      </div>
    );
  }
}

export default APAssessmentScores;
