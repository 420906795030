import * as firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCYREHHeZ--O42zAOiSEcKlIZKRO932Am8",
  authDomain: "ashokleyland-f5e67.firebaseapp.com",
  projectId: "ashokleyland-f5e67",
  storageBucket: "ashokleyland-f5e67.appspot.com",
  messagingSenderId: "1037376803957",
  appId: "1:1037376803957:web:e557ee4ecafef2c221143c",
  measurementId: "G-TXD2MNX4BV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

firebase.auth().languageCode = 'en';

  

  export default firebase;