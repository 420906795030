import { Input, Button, Form, Divider, Row, Col } from 'antd';
import firebase from 'firebase';
import React from 'react';

import logo from '../../assets/images/roadrakshak.webp';
// import logo from '../../assets/images/asian-paints-logo.png';

import Styles from './login.module.css';


const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};


class Login extends React.Component<null, null> {
  uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.PhoneAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => false
    }
  };

  constructor(props: null) {
    super(props);
  }
  render() {

    const onFinish = (values: any) => {

      firebase.auth().signInWithEmailAndPassword(values.code.toLowerCase() + '@roadrakshak.com', values.password)
        .then((user) => {

          firebase.auth().currentUser!.getIdTokenResult()
            .then((idTokenResult) => {
              console.log('Claims', idTokenResult.claims);
              localStorage.setItem('claims', JSON.stringify(idTokenResult.claims));


            })
            .catch((error) => {
              console.log(error);
            });



          // console.log(user.customClaims);
          console.log(user);
          localStorage.setItem('user', JSON.stringify(user));



        })
        .catch((error) => {
        });


      // RRServer.callFn('users1-login', { ...values })
      //   .then((r: any) => {
      //     console.log('Login', r);
      //     if (!r.user) {
      //       alert('User not found. Please check credentials');
      //     } else {

      //     }
      //   })
    };

    const onFinishFailed = (errorInfo: any) => {
      console.log('Failed:', errorInfo);
    };

    return (<>
      <div>

        <Row justify="center" align="middle" className="login-row">
          <img src={logo} />
        </Row>

        <Row justify="center" align="middle" className="login-row">
          <h2>Corporate User Login</h2>
        </Row>

        <Divider />

        <Row justify="center" align="middle" className="login-end">
          <Col span={12}>
            <Form
              {...layout}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                className={Styles["form-input"]}
                label="Corporate Code"
                name="code"
                rules={[{ required: true, message: 'Please input your code!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  Submit
            </Button>
              </Form.Item>
            </Form>

          </Col>

        </Row>

      </div>
      {/* <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} /> */}
    </>
    )
  }
}

export default Login;