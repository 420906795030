import React from 'react';
import { Button, Divider, Form, Input, message, Typography, Steps, Modal, Table, Select, Checkbox, Card, Col, Row, Upload } from 'antd';
import { withRouter } from 'react-router-dom';
import { DrivingSchoolInterface, InstructorInterface, UserInterface, DayType, StudentInterface, EMPTY_SLOTS, DateTimeType, DRIVING_SCHOOL_USER_TYPES, CorporateInterface, CORPORATE_USER_TYPES, CorporatePetroleumInterface, USER_TYPES } from '../../types';
// import Select, { OptionsType } from 'react-select'
import { RRServer } from '../../utils/lib';
import dayjs from 'dayjs';

import firebase from '../../utils/Firebase'

import moment from 'moment';

import XLSX from 'xlsx';
import { UploadOutlined, UserSwitchOutlined } from '@ant-design/icons';


const { Title } = Typography;
const { Step } = Steps;
const { Option } = Select;

interface UserData {
    name: string
    mobile: string
    password: string
    code: string
}

interface DrivingSchoolUserAddProps {
    mode: "edit" | "add",
    loading: boolean,
    corporates: CorporatePetroleumInterface[],
    selectedCorporateId: string,
    selectedCorporate: CorporatePetroleumInterface | null,
    selectedType: string,
    selectedUserType: string,
    file: File | null,
    users: UserData[],
    draftForm: { [key: string]: string }
    selectedStateString: string

}




class CorporatePetroleumUserAdd extends React.Component<any, DrivingSchoolUserAddProps> {
    dsDropdown: React.RefObject<unknown>;
    CLASS_SCHEDULES: DateTimeType[] = [];
    unregisterAuthObserver: firebase.Unsubscribe | undefined;

    constructor(props: any) {
        super(props);
        this.state = {
            mode: !!props.match.params.id ? 'edit' : 'add',
            loading: false,
            corporates: [],
            selectedCorporateId: '',
            selectedCorporate: null,
            selectedType: '',
            selectedUserType: '',
            selectedStateString: '',
            file: null,
            users: [],
            draftForm: {}


        }
        this.onFinish = this.onFinish.bind(this);
        this.dsDropdown = React.createRef();
    }

    componentDidMount() {

        RRServer.list({ table: RRServer.Tables.CORPORATES, forCurrentUser: false, where: [['corporateType', '==', CORPORATE_USER_TYPES.PETROLEUM]] })
            .then((r: any) => {
                this.setState({
                    corporates: r.data.data
                })
            })

    }



    _createCode = (userType: string, selectedCorporate: any) => {

        var code = selectedCorporate.code;
        var serial = userType === "user" ? (selectedCorporate.currentUserCount + 1) : (selectedCorporate.currentAdminCount + 1);
        var serialStr = "" + serial;
        var len = serialStr.length;
        for (let i = len; i < 3; i++) {
            serialStr = "0" + serialStr;
        }

        return {
            ...selectedCorporate,
            code: selectedCorporate.code.substr(0, 6) + serialStr,
            currentUserCount: selectedCorporate.currentUserCount + 1,
            currentAdminCount: selectedCorporate.currentAdminCount + 1
        }

    }

    createCode = (selectedCorporate: CorporatePetroleumInterface, userType: string = "user") => {

        var code = selectedCorporate.code;
        var serial = userType === "user" ? (selectedCorporate.currentUserCount + 1) : (selectedCorporate.currentAdminCount + 1);
        var serialStr = "" + serial;
        var len = serialStr.length;
        for (let i = len; i < 3; i++) {
            serialStr = "0" + serialStr;
        }

        const corp = this._createCode(userType, selectedCorporate)

        selectedCorporate.code = corp.code;
        console.log(selectedCorporate, userType)

        this.setState({
            selectedCorporate
        })

    }

    onSelectCorporate = (value: string, option: any) => {
        const { corporates } = this.state;
        const selectedCorporate = corporates.find(c => c.id == value) || null;
        if (!selectedCorporate) { console.log('Corporate not found'); return }
        // console.log(value, option, selectedCorporate);

        // this.createCode(selectedCorporate)


        this.setState({
            selectedCorporateId: value,
            selectedCorporate
        })

    }

    onSelectType = (value: string, option: any) => {
        console.log(value, option);
        let { selectedCorporate, selectedUserType, selectedType } = this.state;
        if (!selectedCorporate) { return }

        // this.createCodeForUser(selectedCorporate,selectedType,value,selectedUserType)


        this.setState({
            selectedType: value

        })
    }

    onSelectUserType = (value: string, option: any) => {
        console.log(value, option);
        let { selectedCorporate, selectedType, selectedUserType } = this.state;
        if (!selectedCorporate) { return }
        const code = selectedCorporate ? selectedCorporate.code : '';
        const codeArr = code.split('');


        // this.createCodeForUser(selectedCorporate,selectedType,value,selectedUserType)

        this.setState({
            selectedUserType: value,
        })
    }

    createCodeForUser = (selectedCorporate: CorporatePetroleumInterface, typeOfVehicle: string, selectedStateString: string, userType: string) => {
        if (!selectedCorporate) { return }



        const codeStr = selectedCorporate.code;
        let codeArr = codeStr.split('');
        const stateCounter = (Number(selectedCorporate[selectedStateString + "Counter"]) < 10 ? ('0' + selectedCorporate[selectedStateString + "Counter"]) : ('' + selectedCorporate[selectedStateString + "Counter"])).split('');
        const seriesCounter = "" + selectedCorporate[selectedStateString + "SeriesCounter"];
        const stateLetter = selectedStateString[0];

        codeArr[2] = stateLetter;
        codeArr[3] = stateCounter[0]
        codeArr[4] = stateCounter[1]
        codeArr[5] = typeOfVehicle
        
        let _seriesCounter = seriesCounter;
        for (let i = seriesCounter.length; i < 3; i++) {
            _seriesCounter = "0" + _seriesCounter;
        }

        if (userType === 'admin') {
            codeArr[6] = 'A';
            codeArr[7]=_seriesCounter[0]
            codeArr[8]=_seriesCounter[1]
        }else{
            codeArr[6] =_seriesCounter[0];
            codeArr[7]=_seriesCounter[1]
            codeArr[8]=_seriesCounter[2]
        }

        this.setState({ selectedCorporate: { ...selectedCorporate, code: codeArr.join('') } })

    }

    onSelectStateString = (value: string, option: any) => {

        const { selectedCorporate, selectedType, selectedUserType } = this.state;
        if (!selectedCorporate) { return }

        const codeStr = selectedCorporate.code;
        let codeArr = codeStr.split('');
        const stateCounter = (Number(selectedCorporate[value + "Counter"]) < 10 ? ('0' + selectedCorporate[value + "Counter"]) : ('' + selectedCorporate[value + "Counter"])).split('');
        const seriesCounter = Number(selectedCorporate[value + "SeriesCounter"]);
        const stateLetter = value[0];

        codeArr[2] = stateLetter;
        codeArr[3] = stateCounter[0]
        codeArr[4] = stateCounter[1]


        this.createCodeForUser(selectedCorporate, selectedType, value, selectedUserType)
        this.setState({ selectedStateString: value })
        // this.setState({ selectedCorporate:{...selectedCorporate,code:codeArr.join('')} })

    }

    onFinish(values: any) {
        // values.code = this.state.userCode;
        // values.companyId = this.state.selectedSchool?.id;
        // console.log('Finish', values)
        this.saveCorporateUser(values);
    };

    onFinishFailed(errorInfo: any) {
        console.log('Failed:', errorInfo);
    };

    onSearch() { }

    onFormValueChange = (form: { [key: string]: any }) => {
        this.setState({ draftForm: { ...this.state.draftForm, ...form } })
    }

    incrementCounter(s: string) {
        let ss = s.split('');
        let alpha = ss.shift();
        let num = +ss.join('')
        num += 1;
        if (num > 99) {
            alpha = String.fromCharCode(alpha!.charCodeAt(0) + 1);
            num = 0;
        }
        const numStr = num < 10 ? ('0' + num) : num
        return alpha + '' + numStr;
    }

    saveCorporateUser(values: any) {
        const { selectedCorporate,selectedStateString } = this.state;
        if (!selectedCorporate) { console.log('Corporate Not Selected'); return }
        console.log('Save', values)
        values.userCode = selectedCorporate.code;
        values.companyId = values.corporate;
        values.type = selectedCorporate.identifier;
        values.corporateType=CORPORATE_USER_TYPES.PETROLEUM;
        values.selectedStateString=selectedStateString;


        RRServer.callFn('corporate-addPetroleumUser', {
            ...values
        })
            .then(r => {

                console.log('User Added', r);

                if (!values.dontsendsms) {
                    const link = 'https://play.google.com/store/apps/details?id=io.pyps.roadrakshak'

                    RRServer.callFn('notification1-sms', {
                        tmpl: "sms-template-boarding",
                        phone: values.mobile,
                        code: values.userCode,
                        pass: values.password,
                        params: {
                            code: values.userCode,
                            link,
                            name: values.name,
                            password: values.password
                        }
                    })
                        .then(r => window.location.reload())
                }

            })
    }

    render() {
        const { mode, loading, corporates, selectedCorporate, users, selectedType } = this.state;
        const props = this.props;
        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || ''

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        const tailLayout = {
            wrapperCol: { offset: 8, span: 16 },
        };


        const types = [
            'T', 'B'
        ]
        const stateStrings = selectedCorporate ? selectedCorporate.stateStrings.filter(s=>s[s.length-1]==selectedType) : [];
        

        return (
            <main>
                <Divider />

                <Title>{mode === 'edit' ? 'Edit ' : 'Add '} Corporate Petroleum User</Title>

                <div style={{ width: '400px' }}>
                    <Divider />

                    <div>
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{
                                // ...props.user
                            }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                            onValuesChange={this.onFormValueChange}
                        >

                            <Form.Item
                                name="corporate"
                                label="Corporate"
                                hasFeedback
                                rules={[{ required: true, message: 'Please select a Corporate!' }]}
                            >
                                <Select placeholder="Please select a Corporate" onChange={this.onSelectCorporate}>
                                    {corporates.map(c => <Option key={c.id} value={c.id}>{c.corporateName}</Option>)}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="typeOfVehicle"
                                label="Type Of Vehicle"
                                hasFeedback
                                rules={[{ required: true, message: 'Please select a type!' }]}
                            >
                                <Select placeholder="Please select a type" onChange={this.onSelectType}>
                                    {types.map(c => <Option key={'k-' + c} value={c}>{c}</Option>)}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="typeOfUser"
                                label="Type Of User"
                                hasFeedback
                                rules={[{ required: true, message: 'Please select a User Type!' }]}
                            >
                                <Select placeholder="Please select a User Type" onChange={this.onSelectUserType}>
                                    <Option key={'k-admin'} value={'admin'}>Admin</Option>
                                    <Option key={'k-user'} value={'user'}>User</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="selectedString"
                                label="Identifier String"
                                hasFeedback
                                rules={[{ required: true, message: 'Please select a Identifier String!' }]}
                            >
                                <Select placeholder="Please select a Identifier Strinf" onChange={this.onSelectStateString}>
                                    {stateStrings.map(c => <Option key={'k-' + c} value={c}>{c}</Option>)}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Code"
                            >
                                {selectedCorporate && selectedCorporate.code}
                            </Form.Item>
                            <Form.Item
                                label="Employee ID"
                                name="employeeID"
                                rules={[{ required: true, message: 'Name' }]}
                            >
                                <Input placeholder="Employee ID" name="employeeID" />
                            </Form.Item>
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: 'Name' }]}
                            >
                                <Input placeholder="Name" name="name" />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{ required: true, message: 'Password' }]}
                            >
                                <Input placeholder="Password" />
                            </Form.Item>
                            <Form.Item
                                label="Mobile"
                                name="mobile"
                                rules={[{ required: true, message: 'Mobile' }]}
                            >
                                <Input placeholder="Mobile" />
                            </Form.Item>

                            <Form.Item
                                label="Dont Send SMS"
                                name="dontsendsms"
                                valuePropName="checked"
                                rules={[{ required: false, message: 'Send SMS' }]}
                            >
                                <Checkbox>Dont Send SMS</Checkbox>
                            </Form.Item>


                            <Form.Item {...tailLayout}>
                                <Button type="primary" htmlType={'submit'}>
                                    {mode === 'edit' ? 'Edit' : 'Create Corporate User'}
                                </Button>
                            </Form.Item>

                        </Form>



                    </div>

                </div>


            </main>
        )
    }
}

// export default withRouter(UserAdd);
export default withRouter(CorporatePetroleumUserAdd);