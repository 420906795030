import React from 'react';
import { PageHeader } from 'antd';
import { Link, Route, withRouter, Switch } from 'react-router-dom';
import DrivingSchoolUserAdd from './CorporateUserAdd';
import { OrderedListOutlined, UserAddOutlined } from '@ant-design/icons';
import CorporateList from './CorporateList';
import CorporatePetroleumUserAdd from './CorporatePetroleumUserAdd';



class CorporateUser extends React.Component<any, any> {

    render() {
        let { path, url } = this.props.match;
        console.log('P2', this.props.drivingSchool)

        return (
            <div className="App">
                <main>
                    <PageHeader
                        ghost={false}
                        onBack={() => window.history.back()}
                        title="Corporate Users"
                        subTitle=""
                        extra={[
                            <Link to={`${url}/add-ds-user`} key='0'>
                                Add Corporate User<UserAddOutlined />
                            </Link>,
                            <Link to={`${url}/add-petroleum-user`} key='1'>
                                Add Corporate Petroleum User<UserAddOutlined />
                            </Link>,
                            <Link to={`${url}`} key='2'>
                                List<OrderedListOutlined />
                            </Link>,
                        ]}
                    >
                    </PageHeader>

                    <Switch>
                        <Route exact path={path}>
                            <CorporateList drivingSchool={this.props.drivingSchool} />
                        </Route>
                        <Route path={`${path}/add-petroleum-user`}>
                            <CorporatePetroleumUserAdd />
                        </Route>
                        <Route path={`${path}/add-ds-user`}>
                            <DrivingSchoolUserAdd />
                        </Route>
                        <Route path={`${path}/edit-ds-user/:id`}>
                            <DrivingSchoolUserAdd />
                        </Route>
                    </Switch>

                </main>
            </div>
        );
    }
}

export default withRouter(CorporateUser);