import React from 'react';

import firebase from '../utils/Firebase';


import Dashboard from './Dashboard'


class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSignedIn: false,
      drivingSchoolName: '',
      code: {},
      password: '',
      corporateName: '',
      schools: [],
      corporateUsers: [],
      selectedSchool: null,
      selectedSchoolId: '',
      selectedCorporateId: '',
      newUserName: '',
      newMobile: '',
      userCode: '',
      newsLang: 'en',
      newsTitle: '',
      newsFileUrl: '',
      publishDate: '',
      newUserType: '',

      //For Dash
      drivingSchoolCodesCount: 0,
      corporateCodesCount: 0,
      studentsCount: 0,
      instructorCount: 0,
      adminCount: 0,
      corporateCodes: [],

    }
    
  }

  uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.PhoneAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => false
    }
  };

  componentDidMount() {
    // this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
    //   (user) => {
    //     this.setState({ isSignedIn: !!user })
    //   }
    // );

    // RRServer.list({
    //   table: RRServer.Tables.DRIVING_SCHOOLS,
    //   forCurrentUser: false
    // })
    //   .then((result) => {
    //     this.setState({ schools: result.data.data })
    //   })


    // var getCorporateUsers = firebase.functions().httpsCallable('getCorporateUsers');
    // getCorporateUsers().then(result => {
    //   let corporateUsers = result.data.corporateUsers;
    //   this.setState({ corporateUsers: corporateUsers })
    // })
    //   .catch(error => {
    //     console.error('GET CU ', error)
    //   })
  }

  componentWillUnmount() {
    // this.unregisterAuthObserver();
  }

  render() {


    return (
      <div className="App">
        <header className="App-header">
          <Dashboard />
        </header>
      </div>
    );
  }
}

export default Home;