import React from 'react';
import { withRouter } from 'react-router-dom';

import { DatePicker, Select, Table } from 'antd';
import { CorporateInterface, DrivingSchoolInterface, UserInterface } from '../../types';
import { RRServer } from '../../utils/lib';
import dayjs from 'dayjs';

const { Option } = Select;

interface ScoreInterface {
    id: string
    cid: string
    uid: string
    game: string
    level: number
    score: number
    addedOn: number
}

interface ScoresViewStateProps {
    scores: ScoreInterface[]
    loading: boolean
    schools: DrivingSchoolInterface[],
    corporates: CorporateInterface[],
    users: UserInterface[]
    filterSchoolId: string,
    filterUserId: string,
    filterDate: number,
    userHashed: { [key: string]: UserInterface },
    schoolsHashed: { [key: string]: DrivingSchoolInterface },
    corpHashed: { [key: string]: CorporateInterface },
}

class ScoresView extends React.Component<any, ScoresViewStateProps>{
    constructor(props: any) {
        super(props);

        const claimsStr = localStorage.getItem('claims');
        const claims = !!claimsStr ? JSON.parse(claimsStr) : {};

        this.state = {
            loading: true,
            scores: [],
            schools: [],
            corporates: [],
            users: [],
            filterSchoolId: '',
            filterUserId: '',
            filterDate: 0,
            userHashed: {},
            schoolsHashed: {},
            corpHashed: {}
        };
    }

    componentDidMount() {

        const claimsStr = localStorage.getItem('claims');
        const claims = !!claimsStr ? JSON.parse(claimsStr) : {};
        const drivingSchoolId = claims.drivingSchoolId || '';

        let promiseArr: any = [];
        if (drivingSchoolId === "*" || drivingSchoolId === "#"  ) {
            promiseArr = [
                RRServer.list({ table: "scores", forCurrentUser: false }),
                RRServer.list({ table: "users", forCurrentUser: false }),
                RRServer.list({ table: RRServer.Tables.DRIVING_SCHOOLS, forCurrentUser: false }),
                RRServer.list({ table: RRServer.Tables.CORPORATES, forCurrentUser: false })
            ]
        } else {
            promiseArr = [
                RRServer.list({ table: "scores", forCurrentUser: false, where: [['cid', '==', drivingSchoolId]] }),
                RRServer.list({ table: "users", forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] }),
                RRServer.list({ table: RRServer.Tables.DRIVING_SCHOOLS, forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] }),
                RRServer.list({ table: RRServer.Tables.CORPORATES, forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] })
            ]
        }

        Promise.all(promiseArr)
            .then((results: any) => {
                const scores = results[0].data.data;
                const users = results[1].data.data as UserInterface[];
                const schools = results[2].data.data as DrivingSchoolInterface[];
                const corporates = results[3].data.data as CorporateInterface[];

                let userHashed: { [key: string]: UserInterface } = {};
                users.map(user => userHashed[user.id] = user);

                let schoolsHashed: { [key: string]: DrivingSchoolInterface } = {};
                schools.map(school => schoolsHashed[school.id] = school);

                let corpHashed: { [key: string]: CorporateInterface } = {};
                corporates.map(school => corpHashed[school.id] = school);

                this.setState({ loading: false, scores, users, userHashed, schools, schoolsHashed, corporates, corpHashed })
            })
    }

    render() {

        const { scores, schools, filterSchoolId, filterUserId, filterDate, users, userHashed, schoolsHashed, corporates, corpHashed } = this.state;
        let { url } = this.props.match;

        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || '';

        let dataSource = scores.map(score => ({ key: score.id, ...score }))



        const columns = [

            {
                title: 'Game',
                dataIndex: 'game',
                key: 'game',
                filters: [
                    { text: 'Isometric Quiz', value: 'Isometric Quiz' },
                    { text: 'Roadways', value: 'Roadways' },
                    { text: 'Legals Quiz', value: 'Legals Quiz' },
                    { text: 'Quiz Basics', value: 'Quiz Basics' },
                    { text: 'Neon Square Basic', value: 'Neon Square Basic' },
                    { text: 'Limits Quiz', value: 'Limits Quiz' },
                    { text: 'Hardware Quiz', value: 'Hardware Quiz' },
                    { text: 'Limits Quiz', value: 'Limits Quiz' },
                    { text: 'Parking Quiz', value: 'Parking Quiz' },
                    { text: 'Definition Quiz', value: 'Definition Quiz' },
                    { text: 'Signal Quiz', value: 'Signal Quiz' },
                ],
            },
            {
                title: 'Date & Time',
                render: (score: ScoreInterface) => {
                    const dt = dayjs(score.addedOn).format('DD/MM/YYYY H:m')
                    return dt;
                },
                sorter: (a: any, b: any) => a.addedOn - b.addedOn,
                // sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'User',
                key: 'user',
                render: (score: ScoreInterface) => {
                    return userHashed[score.uid] ? userHashed[score.uid].name : '-';
                },
                sorter: (a: any, b: any) => a.addedOn - b.addedOn,
            },
            {
                title: 'Attempt',
                dataIndex: 'attemptNumber',
                key: 'attemptNumber',
            },

            {
                title: 'Level',
                dataIndex: 'level',
                key: 'level',
            },

            {
                title: 'Score',
                dataIndex: 'score',
                key: 'score',
            }
        ];

        if (filterSchoolId) {
            dataSource = dataSource.filter(d => d.cid === filterSchoolId)
        }
        if (filterUserId) {
            dataSource = dataSource.filter(d => d.uid === filterUserId)
        }
        if (!!filterDate) {
            const df = dayjs(filterDate).set('h',0).set('minute',0).set('second',0).set('millisecond',0);
            dataSource = dataSource.filter(d => {
                const da = dayjs(d.addedOn).set('h',0).set('minute',0).set('second',0).set('millisecond',0);
                return da.isSame(df)
            })
        }


        return (
            <main>

                {claims.drivingSchoolId === "*" ?
                    <div>
                        <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Filter School / Corporate"
                            optionFilterProp="children"
                            onChange={(id) => this.setState({ filterSchoolId: id + "" })}
                            // onFocus={onFocus}
                            // onBlur={onBlur}
                            // onSearch={onSearch}
                            filterOption={(input, option) => {
                                return option!.children ? option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                            }
                            }
                        >
                            {schools.map(school => <Option key={school.id} value={school.id}>{school.drivingSchoolName}</Option>)}
                            {corporates.map(school => <Option key={school.id} value={school.id}>{school.corporateName}</Option>)}

                        </Select>
                    </div>
                    : ""}
                <Select
                    showSearch
                    style={{ width: 200, marginTop: '2vh', marginBottom: '2vh' }}
                    placeholder="Filter User"
                    optionFilterProp="children"
                    allowClear
                    onClear={() => this.setState({ filterUserId: '' })}
                    onChange={(id: string) => this.setState({ filterUserId: id })}
                    // onFocus={onFocus}
                    // onBlur={onBlur}
                    // onSearch={onSearch}
                    filterOption={(input, option) => {
                        return option!.children ? option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                    }
                    }
                >
                    {users.map(user => <Option key={user.id} value={user.id}>{user.name}</Option>)}

                </Select>
                <DatePicker
                    onChange={(d) => this.setState({ filterDate: d ? +d : -1 })}
                />
                <Table className="ScoresTable" dataSource={dataSource} columns={columns} />
            </main>
        );
    }




};

export default withRouter(ScoresView);

const games = {
    roadways: 'Roadways',
    aa: 'Accident Analysis',
    lm: 'Landmark Mapping',
    oa: 'Overtaking Analysis',
    hazchem: 'Hazchem Quiz',
    basics: 'Quiz Basics',
    signfactory: 'Sign Factory',
    iso: 'Isometric Quiz'
};

const gameData = [
    {
        route: games.roadways,
        level: 1
    },
    {
        route: games.aa,
        level: 1
    },
    {
        route: games.basics,
        level: 1
    },
    {
        route: games.lm,
        level: 1
    },
    {
        route: games.oa,
        level: 1
    },
    {
        route: games.hazchem,
        level: 1
    },
    {
        route: games.iso,
        level: 1
    },
    {
        route: games.aa,
        level: 2
    },
    {
        route: games.basics,
        level: 2
    },
    {
        route: games.signfactory,
        level: 1
    },
    {
        route: games.oa,
        level: 2
    },
    {
        route: games.hazchem,
        level: 2
    },
    {
        route: games.roadways,
        level: 2
    },
    {
        route: games.aa,
        level: 3
    },
    {
        route: games.basics,
        level: 3
    },
    {
        route: games.lm,
        level: 2
    },
    {
        route: games.oa,
        level: 3
    },
    {
        route: games.hazchem,
        level: 3
    },
    {
        route: games.aa,
        level: 4
    },
    {
        route: games.iso,
        level: 2
    },
    {
        route: games.basics,
        level: 4
    },
    {
        route: games.oa,
        level: 4
    },
    {
        route: games.signfactory,
        level: 2
    },
    {
        route: games.roadways,
        level: 3
    },
    {
        route: games.hazchem,
        level: 4
    },
    {
        route: games.aa,
        level: 5
    },
    {
        route: games.lm,
        level: 3
    },
    {
        route: games.iso,
        level: 3
    },
    {
        route: games.oa,
        level: 5
    }
];