import { Button, Modal, Skeleton, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react'


interface TableSelectProps {
    title: string
    // onOk: () => void
    // onCancel: () => void
    onSelect: (val: any) => void
    columns: ColumnsType<any>
    data: any[]
    fetch?: Promise<any>
    selectedValue: string | undefined
}

const TableSelect = (props: TableSelectProps) => {


    // const [_data, _setData] = useState<any[]>([]);
    const [showModal, setShowModal] = useState(false);


    // if (props.fetch) {
    //     props.fetch.then(result => _setData(result.data))
    // } else if (props.data) {
    //     _setData(props.data);
    // }
    
    

    const showModalToggle = () => setShowModal(!showModal)

    return (<>

        <Button onClick={showModalToggle} type='link'> {props.selectedValue ? props.selectedValue : props.title} </Button>

        <Modal
            title={props.title}
            visible={showModal}
            onOk={showModalToggle}
            onCancel={showModalToggle}
        >
            {props.data.length
                ? <Table
                    rowSelection={{
                        type: 'radio',
                        onChange: (selectedRowKeys, selectedRows) => {
                            props.onSelect(selectedRows[0])
                        },
                    }}
                    columns={props.columns}
                    dataSource={props.data}
                />
                : <Skeleton active />
            }
        </Modal>
    </>)
}

export default TableSelect;
