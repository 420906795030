import React from 'react';
import { Divider, Space, Table } from 'antd';
import { EditFilled, EyeFilled } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import { RRServer } from '../../utils/lib';


interface NewsInterface {
    id: string
    title: string
    content: string
}

interface UserStateProps {
    news: NewsInterface[]
}

class NewsList extends React.Component<any, UserStateProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            news: [],
        }
    }

    componentDidMount() {

        RRServer.list({ table: 'news_articles', forCurrentUser: false }).then((result: any) => {
            let news = result.data.data;
            this.setState({ news })
        });


    }


    render() {
        const { news } = this.state;
        let { url } = this.props.match;


        const dataSource = news.map(news => ({ key: news.id, ...news }))



        const columns = [
            // {
            //     title: 'ID',
            //     dataIndex: 'id',
            //     key: 'id',
            // },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
            },

            {
                title: 'Content',
                dataIndex: 'content',
                key: 'content',
            },
            {
                title: 'Destination',
                dataIndex: 'destination',
                key: 'destination',
            },


            {
                title: 'Action',
                key: 'action',
                sorter: true,
                render: ({ id }: { id: string }) => (
                    <Space split={<Divider type="vertical" />}>
                        <Link to={`${url}/edit/${id}`} key='edit-0'>
                            <EditFilled />
                        </Link>
                        <Link to={`${url}/view/${id}`} key='view-1'>
                            <EyeFilled />
                        </Link>
                        {/* <Link to={`${url}/view/${id}`} key='view-1'>
                            <DeleteFilled />
                        </Link> */}
                    </Space>
                ),
            },
        ];

        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || ''

        if (claims.drivingSchoolId == '*') {
            (columns as any).push({
                title: 'Action',
                key: 'action',
                sorter: true,
                render: ({ id }: { id: string }) => (
                    <Space split={<Divider type="vertical" />}>
                        <Link to={`${url}/edit/${id}`} key='edit-0'>
                            <EditFilled />
                        </Link>
                        <Link to={`${url}/view/${id}`} key='view-1'>
                            <EyeFilled />
                        </Link>
                        {/* <Link to={`${url}/view/${id}`} key='view-1'>
                            <DeleteFilled />
                        </Link> */}
                    </Space>
                ),
            })
        }

        return (
            <main>
                <Table dataSource={dataSource} columns={columns} />
            </main>
        );
    }
}

// export default withRouter(UserAdd);
export default withRouter(NewsList);