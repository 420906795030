import React from 'react';
import { Button, Divider, Form, Input, message, Skeleton, Switch } from 'antd';
import { withRouter } from 'react-router-dom';
import MaskedInput from 'antd-mask-input'
import { callOnServer, RRServer } from '../../utils/lib';
import { InstallmentInterface, UserInterface } from '../../types';
import { promises } from 'fs';
import { CheckOutlined, CloseOutlined, UserOutlined } from '@ant-design/icons';


interface DrivingSchoolInterface {
    code: string;
    id: string
    drivingSchoolName: string
    currentCount: string
    sosNumber: string
}
interface CorporateCodeInterface {
    password: string
    id: string
}

interface DrivingSchoolAddInterface {
    currentSchool: null | DrivingSchoolInterface,
    currentInstallment: null | InstallmentInterface,
    currentUser: null | UserInterface,
    mode: 'edit' | 'add',
}

class InstallmentsAdd extends React.Component<any, DrivingSchoolAddInterface> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentSchool: null,
            currentInstallment: null,
            currentUser: null,
            mode: !!props.match.params.id ? 'edit' : 'add',
        }
        this.onFinish = this.onFinish.bind(this);
        this.editInstallment = this.editInstallment.bind(this);
    }

    componentDidMount() {
        if (this.state.mode === 'edit') {
            RRServer.get({ table: RRServer.Tables.INSTALLMENTS, id: this.props.match.params.id })
                .then((results: any) => {
                    const currentInstallment = results.data.data;
                    this.setState({
                        currentInstallment
                    });

                    Promise.all([
                        RRServer.get({ table: RRServer.Tables.USERS, id: currentInstallment.uid }),
                        RRServer.get({ table: RRServer.Tables.DRIVING_SCHOOLS, id: currentInstallment.companyId }),
                    ])
                        .then((results: any) => {
                            const currentUser = results[0].data.data;
                            const currentSchool = results[1].data.data;
                            this.setState({ currentSchool, currentUser })
                        })
                })
        }
    }


    editInstallment(installment:InstallmentInterface) {
        const { mode, currentInstallment } = this.state;

        installment.status=installment.status?1:0;
        if (mode === 'edit') {
                RRServer.update({ id:currentInstallment!.id,   status:installment.status, table: RRServer.Tables.INSTALLMENTS})
                .then((result: any) => {
                    console.log(result);
                    if (result.data.s) {
                        message.success('Successfully Updated Installment Status');
                        setTimeout(() => window.location.href = '/installments', 200)
                    }
                })
        } else {
           
        }

    }

    onFinish(values: any) {
        console.log(values);
        this.editInstallment(values)
    };

    onFinishFailed(errorInfo: any) {
        console.log('Failed:', errorInfo);
    };

    onSearch() {
    }




    render() {
        const { mode, currentSchool, currentInstallment,currentUser } = this.state

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        const tailLayout = {
            wrapperCol: { offset: 8, span: 16 },
        };


        return (
            <main>
                <Divider />
                <div style={{ width: '400px' }}>

                    {mode === 'edit' && (currentInstallment === null || currentSchool === null || currentUser === null)
                        ? <Skeleton active />
                        : <Form
                            {...layout}
                            name="basic"
                            initialValues={{
                                ...currentInstallment,
                                ...currentSchool,
                                ...currentUser

                            }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                        // onValuesChange={(changedValues, allValues) => console.log(changedValues, allValues)}
                        >

                            <Form.Item
                                label="School"
                                name="drivingSchoolName"
                                rules={[{ required: false, message: 'SOS Number' }]}
                            >
                                <Input  bordered={false} disabled={true} />
                            </Form.Item>
                            <Form.Item
                                label="User"
                                name="name"
                                rules={[{ required: false, message: 'SOS Number' }]}
                            >
                                <Input  prefix={<UserOutlined className="site-form-item-icon" />} bordered={false} disabled={true} />
                            </Form.Item>
                            <Form.Item
                                label="Installment"
                                name="installment"
                                rules={[{ required: false, message: 'SOS Number' }]}
                            >
                                <Input disabled={true} bordered={false} />
                            </Form.Item>

                            <Form.Item
                                label="Paid"
                                name="status"
                                rules={[{ required: false, message: 'SOS Number' }]}
                            >
                                {/* <Input value={1} type="checkbox" /> */}
                                <Switch  
                                defaultChecked={!!currentInstallment!.status}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                />
                            </Form.Item>



                            <Form.Item {...tailLayout}>
                                <Button type="primary" htmlType="submit" >
                                    {mode === 'edit' ? 'Save' : 'Add'}
                                </Button>
                            </Form.Item>
                        </Form>
                    }
                </div>
            </main>
        );
    }
}

export default withRouter(InstallmentsAdd);