import React from 'react';
import firebase from '../../utils/Firebase';
import { Divider, Select, Space, Table } from 'antd';
import { EditFilled, EyeFilled } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import { callOnServer, RRServer } from '../../utils/lib';

const { Option } = Select;

interface DrivingSchoolInterface {
    code: string;
    id: string
    drivingSchoolName: string
    currentCount: string
}

interface UserStateProps {
    schools: DrivingSchoolInterface[]
    selectedSchool: DrivingSchoolInterface | null
    userCode: string
    filterSchoolId: string
}

class DrivingSchoolList extends React.Component<any, UserStateProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            schools: [],
            selectedSchool: null,
            userCode: '',
            filterSchoolId: ''

        }
        this.getSchool = this.getSchool.bind(this);
    }

    componentDidMount() {

        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || ''

        if (claims.drivingSchoolId !== '*' && claims.drivingSchoolId !== '#' ) {

            RRServer.get({ table: 'driving_schools', id: claims.drivingSchoolId }).then((result: any) => {
                let schools = [result.data.data];
                this.setState({ schools })
            });
        } else {
            RRServer.list({ table: 'driving_schools', forCurrentUser: false }).then((result: any) => {
                let schools = result.data.data;
                this.setState({ schools })
            });
        }


    }

    getSchool(schoolId: string) {
        const school = this.state.schools.filter(school => school.id = schoolId);
        return school[0];
    }





    render() {
        const { schools, filterSchoolId } = this.state;
        let { url } = this.props.match;

        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || ''


        let dataSource = schools.map(school => ({ key: school.id, ...school }))

        if (filterSchoolId) {
            dataSource = dataSource.filter(d => d.id === filterSchoolId)
        }



        const columns = [
            // {
            //     title: 'ID',
            //     dataIndex: 'id',
            //     key: 'id',
            // },
            {
                title: 'Name',
                dataIndex: 'drivingSchoolName',
                key: 'drivingSchoolName',
            },
            {
                title: 'Code',
                dataIndex: 'code',
                key: 'code',
            },
            {
                title: 'Current Count',
                dataIndex: 'currentCount',
                key: 'currentCount',
            },

        ];

        if (claims.drivingSchoolId == '*') {
            (columns as any).push({
                title: 'Action',
                key: 'action',
                sorter: true,
                render: ({ id }: { id: string }) => (
                    <Space split={<Divider type="vertical" />}>
                        <Link to={`${url}/edit/${id}`} key='edit-0'>
                            <EditFilled />
                        </Link>
                        <Link to={`${url}/view/${id}`} key='view-1'>
                            <EyeFilled />
                        </Link>
                        {/* <Link to={`${url}/view/${id}`} key='view-1'>
                <DeleteFilled />
            </Link> */}
                    </Space>
                ),
            })
        }

        return (
            <main>
                <div>
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Filter School"
                        optionFilterProp="children"
                        onChange={(id) => this.setState({ filterSchoolId: id + "" })}
                        // onFocus={onFocus}
                        // onBlur={onBlur}
                        // onSearch={onSearch}
                        filterOption={(input, option) => {
                            return option!.children ? option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                        }
                        }
                    >
                        {schools.map(school => <Option key={school.id} value={school.id}>{school.drivingSchoolName}</Option>)}

                    </Select>
                </div>
                <Table dataSource={dataSource} columns={columns} />
            </main>
        );
    }
}

// export default withRouter(UserAdd);
export default withRouter(DrivingSchoolList);