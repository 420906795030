import React from 'react';
import { Button, Divider, Form, Input, message, Select, Skeleton } from 'antd';
import { withRouter } from 'react-router-dom';
import MaskedInput from 'antd-mask-input'
import { callOnServer, RRServer } from '../../utils/lib';
import TextArea from 'antd/lib/input/TextArea';


interface DrivingSchoolInterface {
    code: string;
    id: string
    drivingSchoolName: string
    currentCount: string
    sosNumber: string
}
interface CorporateCodeInterface {
    password: string
    id: string
}

interface DrivingSchoolAddInterface {
    currentSchool: null | DrivingSchoolInterface,
    corporateCode: null | CorporateCodeInterface
    mode: 'edit' | 'add',
}

class NewsAdd extends React.Component<any, DrivingSchoolAddInterface> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentSchool: null,
            corporateCode: null,
            mode: !!props.match.params.id ? 'edit' : 'add',
        }
        this.onFinish = this.onFinish.bind(this);
    }

    componentDidMount() {
        if (this.state.mode === 'edit') {
            Promise.all([
                RRServer.get({ table: 'driving_schools', id: this.props.match.params.id }),
                callOnServer('find', { table: 'corporate_codes', where: [['companyId', '==', this.props.match.params.id]] })
            ]).then((results: any) => {
                this.setState({
                    currentSchool: results[0].data.data,
                    corporateCode: results[1].data.data
                })
            })
        }
    }

    addArticle = (values: any) => {
        console.log(values)

        RRServer.callFn('news1-add', values)
            .then((result: any) => {
                console.log(result);
                if (result.data.s) {
                    alert('Successfully Saved News Article');
                    window.location.reload();
                }
            })

    }

    onFinish(values: any) {
        this.addArticle(values);
    };

    onFinishFailed(errorInfo: any) {
        console.log('Failed:', errorInfo);
    };

    onSearch() {
    }




    render() {
        const { mode, currentSchool, corporateCode } = this.state

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        const tailLayout = {
            wrapperCol: { offset: 8, span: 16 },
        };

        const { Option } = Select;


        return (
            <main>
                <Divider />
                <div style={{ width: '400px' }}>

                    {mode === 'edit' && currentSchool === null
                        ? <Skeleton active />
                        : <Form
                            {...layout}
                            name="basic"
                            initialValues={{
                                ...currentSchool,
                                password: corporateCode && corporateCode.password
                            }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                        // onValuesChange={(changedValues, allValues) => console.log(changedValues, allValues)}
                        >



                            <Form.Item
                                label="Destination"
                                name="destination"
                                rules={[{ required: true, message: 'Please Enter Destination!' }]}
                            >
                                {/* <MaskedInput mask="111111A00" name="code" size={'small'} /> */}
                                <Select>
                                    <Option value='ap'>Asian Paints</Option>
                                    <Option value='iocl'>IOCL</Option>
                                    <Option value='ds'>Driving School</Option>
                                    <Option value='ds2'>Driving School_2W</Option>
                                    <Option value='ds4'>Driving School_4W</Option>
                                </Select>

                            </Form.Item>

                            <Form.Item
                                label="Language"
                                name="lang"
                                rules={[{ required: true, message: 'Please Enter newsLang!' }]}
                            >
                                {/* <MaskedInput mask="111111A00" name="code" size={'small'} /> */}
                                <Select>
                                    <Option value='en'>English</Option>
                                    <Option value='ta'>தமிழ்</Option>
                                    <Option value='hi'>Hindi</Option>
                                </Select>

                            </Form.Item>

                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[{ required: true, message: 'Title' }]}
                            >
                                <Input placeholder="Title" />
                            </Form.Item>

                            <Form.Item
                                label="Content"
                                name="content"
                                rules={[{ required: true, message: 'Content' }]}
                            >
                                <TextArea placeholder="Content" rows={20} cols={20} />
                            </Form.Item>
                            <Form.Item
                                label="File Url"
                                name="fileUrl"
                                rules={[{ required: true, message: 'File Url' }]}
                            >
                                <Input placeholder="File Url" />
                            </Form.Item>
                            <Form.Item
                                label="Video Url"
                                name="videoUrl"
                                rules={[{  message: 'Video Url' }]}
                            >
                                <Input placeholder="Video Url" />
                            </Form.Item>
                            <Form.Item
                                label="Publish Date"
                                name="publishDate"
                                rules={[{ required: true, message: 'Publish Date' }]}
                            >
                                <Input placeholder="Publish Date" type='date' />
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <Button type="primary" htmlType="submit" >
                                    {mode === 'edit' ? 'Edit' : 'Add'}
                                </Button>
                            </Form.Item>
                        </Form>
                    }
                </div>
            </main>
        );
    }
}

export default withRouter(NewsAdd);