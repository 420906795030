import React from 'react';
import { PageHeader } from 'antd';
import { Link, Route, withRouter, Switch } from 'react-router-dom';

import NewsAdd from './NewsAdd';
import NewsList from './NewsList';
// import DrivingSchoolView from './DrivingSchoolView';

import { OrderedListOutlined, UserAddOutlined } from '@ant-design/icons';
import { extractInstanceAndPath } from 'firebase-functions/lib/providers/database';



interface UserStateProps {

}

class News extends React.Component<any, UserStateProps> {
    constructor(props: any) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
    }

    render() {
        let { path, url } = this.props.match;

        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || '';

        let extra = [<Link to={`${url}`} key='1'>
            List<OrderedListOutlined />
        </Link>];
        if (claims.drivingSchoolId == '*') {
            extra.push(<Link to={`${url}/add`} key='0'>
                Add Article<UserAddOutlined />
            </Link>)
        }

        return (
            <div className="App">
                <main>
                    <PageHeader
                        ghost={false}
                        onBack={() => window.history.back()}
                        title="News"
                        subTitle=""
                        extra={extra}
                    >
                    </PageHeader>

                    <Switch>
                        <Route exact path={path}>
                            <NewsList />
                        </Route>
                        <Route path={`${path}/add`}>
                            <NewsAdd />
                        </Route>
                        <Route path={`${path}/edit/:id`}>
                            {/* <DrivingSchoolAdd /> */}
                        </Route>
                        <Route path={`${path}/view/:id`}>
                            {/* <DrivingSchoolView /> */}
                        </Route>
                    </Switch>

                </main>
            </div>
        );
    }
}

export default withRouter(News);