import React from 'react';
import { DatePicker, Divider, Select, Space, Table } from 'antd';
import { EditFilled, ShareAltOutlined } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import { getClaims, RRServer } from '../../utils/lib';
import { ClassScheduleInterface, UserInterface } from '../../types';
import dayjs from 'dayjs';

const { Option } = Select;
const { RangePicker } = DatePicker;


interface DrivingSchoolInterface {
    code: string;
    id: string
    drivingSchoolName: string
    currentCount: string
}

interface ClassSchedulesListStateProps {
    classSchedules: ClassScheduleInterface[]
    selectedSchedule: ClassScheduleInterface | null
    userCode: string
    usersHash: { [key: string]: UserInterface },
    filterSchoolId: string,
    filterUserId: string,
    schools: DrivingSchoolInterface[],
    filterDates: any
    claims: any
}

class ClassSchedulesList extends React.Component<any, ClassSchedulesListStateProps> {
    requesting: { [key: string]: boolean } = {}
    constructor(props: any) {
        super(props);
        this.state = {
            classSchedules: [],
            selectedSchedule: null,
            userCode: '',
            usersHash: {},
            filterSchoolId: '',
            filterUserId: "",
            filterDates: null,
            schools: [],
            claims: {}
        }
        this.getSchool = this.getSchool.bind(this);
    }

    componentDidMount() {

        getClaims().then(claims => {
            if (!claims || !!!claims['drivingSchoolId']) { console.log('No Claim - cs'); return null }
            const drivingSchoolId = claims ? claims['drivingSchoolId'] : '#';
            let promise = [(drivingSchoolId === '*' || drivingSchoolId === '#')
                ? RRServer.list({ table: 'class_schedules', forCurrentUser: false })
                : RRServer.list({ table: 'class_schedules', forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] })]
            promise.push((drivingSchoolId === '*' || drivingSchoolId === '#')
                ? RRServer.list({ table: 'driving_schools', forCurrentUser: false })
                : RRServer.list({ table: 'driving_schools', forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] }));


            Promise.all(promise).then((results: any) => {
                let classSchedules = results[0].data.data;
                let schools = results[1].data.data;
                this.setState({ classSchedules, schools, claims })
            });
        });
    }

    getSchool(schoolId: string) {
        const school = this.state.classSchedules.filter(school => school.id = schoolId);
        return school[0];
    }


    getUser = (id: string) => {
        if (this.requesting[id]) { return }
        this.requesting[id] = true;
        RRServer.get({ table: "users", id })
            .then((result: any) => {
                let usersHash = this.state.usersHash;
                usersHash[id] = result.data.data;
                this.setState({
                    usersHash
                })

            })
    }


    render() {
        const { classSchedules, filterSchoolId, filterUserId, filterDates, schools, claims } = this.state;
        let { url } = this.props.match;
        const drivingSchoolId = claims ? claims['drivingSchoolId'] : '#';


        let dataSource = classSchedules.map(school => ({ key: school.id, ...school }))

        if (filterSchoolId) {
            dataSource = dataSource.filter(d => d.companyId === filterSchoolId)
        }
        if (filterUserId) {
            dataSource = dataSource.filter(d => d.studentId === filterUserId)
        }
        if (filterDates) {
            dataSource = dataSource.filter(ds => {
                const [d, m, y] = ds.date.split('/');
                const [df1, mf1, yf1] = filterDates[0].format('DD/MM/YYYY').split('/');
                const [df2, mf2, yf2] = filterDates[1].format('DD/MM/YYYY').split('/');

                const sd = dayjs(new Date(+y, +m - 1, +d, 0, 0, 0, 0));
                const fd1 = dayjs(new Date(+yf1, +mf1 - 1, +df1, 0, 0, 0, 0));
                const fd2 = dayjs(new Date(+yf2, +mf2 - 1, +df2, 0, 0, 0, 0));

                return sd.isSame(dayjs(fd1))
                    ||
                    sd.isSame(dayjs(fd2))
                    ||
                    (
                        sd.isAfter(dayjs(+filterDates[0]))
                        && sd.isBefore(dayjs(+filterDates[1]))
                    )
            })
        }

        const columns = [

            {
                title: 'Instructor',
                dataIndex: 'instructor',
                key: 'instructor',
            },
            {
                title: 'User',
                dataIndex: 'student',
                key: 'student',
                sorter: true,
            },
            {
                title: 'Date & Time',
                render: (schedule: ClassScheduleInterface) => {
                    return <div>
                        <div>{(schedule.pinValidatedTime ? 'Scheduled :' : '') + (schedule.date + ' ' + schedule.time)}</div>
                        {schedule.pinValidatedTime && <div>Start :{dayjs(schedule.pinValidatedTime).format('DD/MM/YYYY HH:mm')}</div>}
                        {schedule.classEndTime && <div>End :{dayjs(schedule.classEndTime).format('DD/MM/YYYY HH:mm')}</div>}
                        {schedule.rescheduleRequest && <Link to={`${url}/view/${schedule.id}/${schedule.instructorId}`}>Reschedule</Link>}
                    </div>;
                },
                sorter: true,
            },
            {
                title: 'Rating',
                dataIndex: 'rating',
                key: 'rating',
            }

        ];
        if (drivingSchoolId == '*') {
            (columns as any).push({
                title: 'Action',
                key: 'action',
                sorter: true,
                render: (schedule: ClassScheduleInterface) => (<Space split={<Divider type="vertical" />}>
                        <Link to={`${url}/view/${schedule.id}/${schedule.instructorId}`} key='edit-0'>
                            <EditFilled />
                        </Link>
                        {schedule.rating && <Link to={`${url}/share/${schedule.id}`} key='edit-1'>
                            <ShareAltOutlined />
                        </Link>
                        }
                        {/* <Link to={`${url}/view/${id}`} key='view-1'>
                            <DeleteFilled />
                        </Link> */}
                    </Space>
                )
            })
        }

        let uniqueStudentsHash: { [key: string]: ClassScheduleInterface } = {};
        classSchedules.map(csh => uniqueStudentsHash[csh.studentId] = csh)
        const uniqueStudents = Object.values(uniqueStudentsHash);


        return (
            <main>

                <Select
                    showSearch
                    style={{ width: 200, marginTop: '2vh', marginBottom: '2vh' }}
                    placeholder="Filter User"
                    optionFilterProp="children"
                    allowClear
                    onClear={() => this.setState({ filterUserId: "" })}
                    onChange={(id: string) => this.setState({ filterUserId: id })}
                    // onFocus={onFocus}
                    // onBlur={onBlur}
                    // onSearch={onSearch}
                    filterOption={(input, option) => {
                        return option!.children ? option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                    }
                    }
                >
                    {uniqueStudents.map(classSchedule => <Option key={classSchedule.id} value={classSchedule.studentId}>{classSchedule.student}</Option>)}

                </Select>

                <RangePicker
                    allowClear
                    onChange={(dates: any) => this.setState({ filterDates: dates })}
                />



                <Table className="ClassesTable" dataSource={dataSource} columns={columns} />
            </main>
        );
    }
}

export default withRouter(ClassSchedulesList);