import React from 'react';
import { PageHeader } from 'antd';
import { Link, Route, withRouter, Switch } from 'react-router-dom';

import ClassSchedulesList from './ClassSchedulesList';
import ClassSchedulesView from './ClassSchedulesView';

import { OrderedListOutlined, UserAddOutlined } from '@ant-design/icons';



interface ClassScheduleProps {
  
}

class ClassSchedules extends React.Component<any, ClassScheduleProps> {
    constructor(props: any) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
    }

    render() {
        let { path, url } = this.props.match;

        return (
            <div className="App">
                <main>
                    <PageHeader
                        className = "class-page-header"
                        ghost={false}
                        onBack={() => window.history.back()}
                        title="Class Schedules"
                        subTitle=""
                        extra={[
                            <Link to={`${url}`} key='1' style={{color: 'wheat', fontSize: '2.5vh'}}>
                                List<OrderedListOutlined />
                            </Link>,
                        ]}
                    >
                    </PageHeader>

                    <Switch>
                        <Route exact path={path}>
                            <ClassSchedulesList />
                        </Route>
                        <Route path={`${path}/view/:id/:instructorId`}>
                            <ClassSchedulesView />
                        </Route>
                    </Switch>

                </main>
            </div>
        );
    }
}

export default withRouter(ClassSchedules);