import { Row, Col, Card, Tabs, Skeleton } from 'antd';
import Statistic from 'antd/lib/statistic';
import React from 'react';
import { CorporateCodeInterface, DrivingSchoolInterface, UserInterface } from '../../types';
import { RRServer } from '../../utils/lib';
import poweredByImg from "../../assets/images/poweredByRC.png";
import rakshaklogo from '../../assets/images/timer.png';
import rakshaklogoVertical from '../../assets/images/rakshaklogo.png';

const { TabPane } = Tabs;


interface DashProps {
    schools: DrivingSchoolInterface[]
    users: UserInterface[],
    corporateCodes: CorporateCodeInterface[],
    corporateCodesCount: number,
    drivingSchoolCodesCount: number,
    studentsCount: number,
    instructorCount: number,
    adminCount: number,
    loading: boolean
}
class Dashboard extends React.Component<null, DashProps> {


    constructor(props: null) {
        super(props);

        this.state = {
            loading: true,
            schools: [],
            users: [],
            corporateCodes: [],
            corporateCodesCount: 0,
            drivingSchoolCodesCount: 0,
            studentsCount: 0,
            instructorCount: 0,
            adminCount: 0,
        }
    }


    componentDidMount() {

        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || '';

        if (claims.corporate) {
            window.location.href = '/ap-dashboard'
            return;
        }
        if (!claims.drivingSchoolId) {
            alert('Driving school Not Found');
            return;
        }

        if (claims.drivingSchoolId === "*") {
            Promise.all([
                RRServer.list({
                    table: RRServer.Tables.DRIVING_SCHOOLS,
                    forCurrentUser: false,
                }),
                RRServer.list({
                    table: RRServer.Tables.USERS,
                    forCurrentUser: false
                }),
                RRServer.list({
                    table: 'corporate_codes',
                    forCurrentUser: false
                })]).then((results: any) => {
                    this.setState({
                        schools: results[0].data.data,
                        users: results[1].data.data,
                        corporateCodesCount: results[2].data.data.length,
                        corporateCodes: results[2].data.data,
                        drivingSchoolCodesCount: results[2].data.data.filter(({ type }: { type: string }) => type ? type.indexOf('DRIVING_') != -1 : false).length,
                        studentsCount: results[2].data.data.filter(({ type }: { type: string }) => type ? (type.indexOf('DRIVING_') != -1 && type.indexOf('DRIVING_SCHOOL_ADMIN') == -1 && type.indexOf('DRIVING_SCHOOL_INSTRUCTOR') == -1) : false).length,
                        instructorCount: results[2].data.data.filter(({ type }: { type: string }) => type === 'DRIVING_SCHOOL_INSTRUCTOR').length,
                        adminCount: results[2].data.data.filter(({ type }: { type: string }) => type === 'DRIVING_SCHOOL_ADMIN').length,
                        loading: false
                    });
                })

        } else {

            Promise.all([
                RRServer.get({
                    table: RRServer.Tables.DRIVING_SCHOOLS,
                    id: claims.drivingSchoolId
                }),
                RRServer.list({
                    table: RRServer.Tables.USERS,
                    forCurrentUser: false,
                    where: [["drivingSchoolId", "==", claims.drivingSchoolId]]
                }),
                RRServer.list({
                    table: 'corporate_codes',
                    forCurrentUser: false,
                    where: [["companyId", "==", claims.drivingSchoolId]]
                })]).then((results: any) => {
                    this.setState({
                        schools: [results[0].data.data],
                        users: results[1].data.data,
                        corporateCodesCount: results[2].data.data.length,
                        corporateCodes: results[2].data.data,
                        drivingSchoolCodesCount: results[2].data.data.filter(({ type }: { type: string }) => type.indexOf('DRIVING_') != -1).length,
                        studentsCount: results[2].data.data.filter(({ type }: { type: string }) => type.indexOf('DRIVING_') != -1 && type.indexOf('DRIVING_SCHOOL_ADMIN') == -1 && type.indexOf('DRIVING_SCHOOL_INSTRUCTOR') == -1).length,
                        instructorCount: results[2].data.data.filter(({ type }: { type: string }) => type === 'DRIVING_SCHOOL_INSTRUCTOR').length,
                        adminCount: results[2].data.data.filter(({ type }: { type: string }) => type === 'DRIVING_SCHOOL_ADMIN').length,
                        loading: false
                    });
                })


        }



    }

    showDashItem = (loading: boolean, title: String, value: number) => {
        return loading
            ? <Skeleton.Button active={true} size={'large'} />
            : < Statistic
                title={title}
                value={value}
                precision={0}
                valueStyle={{ color: '#3f8600' }
                }
            />
    }


    render() {
        const { schools, drivingSchoolCodesCount, adminCount, instructorCount, studentsCount, users, loading } = this.state;

        let dt = new Date();
        const loginsThisMonth = users.filter(user => {
            if (user.lastLogin) {
                return user.lastLogin > +new Date(dt.getFullYear(), dt.getMonth(), 1, 0, 0, 0, 0)
                    && user.lastLogin < +new Date(dt.getFullYear(), dt.getMonth() + 1, 1, 0, 0, 0, 0)
            } else {
                return false;
            }
        });

        const profileImage=schools[0] ? (schools[0].profileImage || '') : '';
        const drivingSchoolUnionImg=schools[0] ? (schools[0].drivingSchoolUnionImg || '') : '';

        return (<>
            <Row gutter={16} style={{ marginTop: '-5vh' }}>
                <Col span={4}>
                    <Card style={{}}>
                        {
                            !drivingSchoolUnionImg ? '' : <img alt='logo' style={{ width: 100, height: 100, marginTop: '0%', opacity: '1', marginLeft: '0%' }} src={drivingSchoolUnionImg ? drivingSchoolUnionImg : rakshaklogoVertical} />
                        }
                    </Card>
                </Col>
                <Col span={6}>
                    <Card style={{}}>
                        {/*
                            <img alt='logo' style={{ width: 100, marginTop:'0%', opacity: '1', marginLeft: '0%' }} src={rakshaklogo} />
                        */}
                    </Card>
                </Col>
                <Col span={4}>
                    <Card style={{}}>
                        {
                            !profileImage ? '' : <img alt='logo' style={{ width: 100, marginTop: '0%', opacity: '1', marginLeft: '0%' }} src={profileImage ? profileImage : rakshaklogoVertical} />
                        }
                    </Card>
                </Col>
                <Col span={6}>
                    <Card style={{}}>
                        {/*
                            <img alt='logo' style={{ width: 100, marginTop:'0%', opacity: '1', marginLeft: '0%' }} src={rakshaklogo} />
                        */}
                    </Card>
                </Col>
                <Col span={4}>
                    <Card style={{}}>
                        {
                            <img alt='logo' style={{ width: 100, marginTop: '0%', opacity: '1', marginLeft: '0%' }} src={rakshaklogoVertical} />
                        }
                    </Card>
                </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '0vh' }}>
                <Col span={24}>
                    <h2 style={{ backgroundColor: '#3f3f3f', color: 'wheat' }}>Logins</h2>
                </Col>
                <Col span={4}>
                    <Card style={{ backgroundColor: '#3f3f3f', borderRadius: '3%' }}>
                        {this.showDashItem(loading, "Logins This Month", loginsThisMonth.length)}
                    </Card>
                </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '4vh', marginBottom: '4vh' }}>
                <Col span={24}>
                    <h2 style={{ backgroundColor: '#3f3f3f', color: 'wheat' }}>Driving Schools</h2>
                </Col>
                <Col span={4}>
                    <Card style={{ backgroundColor: '#3f3f3f', borderRadius: '3%' }}>
                        {this.showDashItem(loading, "Driving Schools", schools.length)}
                    </Card>
                </Col>
                <Col span={4}>
                    <Card style={{ backgroundColor: '#3f3f3f', borderRadius: '3%' }}>
                        {this.showDashItem(loading, "Admins", adminCount)}
                    </Card>
                </Col>
                <Col span={4}>
                    <Card style={{ backgroundColor: '#3f3f3f', borderRadius: '3%' }}>
                        {this.showDashItem(loading, "Instructors", instructorCount)}
                    </Card>
                </Col>
                <Col span={4}>
                    <Card style={{ backgroundColor: '#3f3f3f', borderRadius: '3%' }}>
                        {this.showDashItem(loading, "Students", studentsCount)}
                    </Card>
                </Col>
                <Col span={4}>
                    <Card style={{ backgroundColor: '#3f3f3f', borderRadius: '3%' }}>
                        {this.showDashItem(loading, "Codes", drivingSchoolCodesCount)}
                    </Card>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <img alt='logo' style={{ width: 300, marginTop: '3%', opacity: '1', marginLeft: '75%' }} src={poweredByImg} />
                </Col>
            </Row>
        </>)
    }
}

export default Dashboard;