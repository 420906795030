import React from 'react';
import { Button, Divider, Form, Input, message, Typography, Steps, Modal, Table, Select, Checkbox, Card, Col, Row, Upload } from 'antd';
import { withRouter } from 'react-router-dom';
import { DrivingSchoolInterface, InstructorInterface, UserInterface, DayType, StudentInterface, EMPTY_SLOTS, DateTimeType, DRIVING_SCHOOL_USER_TYPES, CorporateInterface } from '../../types';
// import Select, { OptionsType } from 'react-select'
import { RRServer } from '../../utils/lib';
import dayjs from 'dayjs';

import firebase from '../../utils/Firebase'

import moment from 'moment';

import XLSX from 'xlsx';
import { UploadOutlined, UserSwitchOutlined } from '@ant-design/icons';


const { Title } = Typography;
const { Step } = Steps;
const { Option } = Select;

interface UserData {
    name: string
    mobile: string
    password: string
    code: string
}

interface DrivingSchoolUserAddProps {
    mode: "edit" | "add",
    loading: boolean,
    corporates: CorporateInterface[],
    selectedCorporateId: string,
    selectedCorporate: CorporateInterface | null,
    selectedType: string,
    selectedUserType: string,
    file: File | null,
    users: UserData[],
    draftForm: { [key: string]: string }

}




class DrivingSchoolUserAdd extends React.Component<any, DrivingSchoolUserAddProps> {
    dsDropdown: React.RefObject<unknown>;
    CLASS_SCHEDULES: DateTimeType[] = [];
    unregisterAuthObserver: firebase.Unsubscribe | undefined;

    constructor(props: any) {
        super(props);
        this.state = {
            mode: !!props.match.params.id ? 'edit' : 'add',
            loading: false,
            corporates: [],
            selectedCorporateId: '',
            selectedCorporate: null,
            selectedType: '',
            selectedUserType: '',
            file: null,
            users: [],
            draftForm: {}


        }
        this.onFinish = this.onFinish.bind(this);
        this.dsDropdown = React.createRef();
    }

    componentDidMount() {


        RRServer.list({ table: RRServer.Tables.CORPORATES, forCurrentUser: false })
            .then((r: any) => {

                this.setState({
                    corporates: r.data.data
                })

            })



    }



    // addUserToSchool(user: UserInterface & { sendsms: boolean }) {
    //     const { name, mobile, companyId, code, password, type, sendsms } = user;
    //     this.setState({ loading: true })

    //     const { drivingSchoolId } = this.state;

    //     const link = 'https://play.google.com/store/apps/details?id=io.pyps.roadrakshak'

    //     RRServer.callFn('users1-add', {
    //         name: name,
    //         mobile: mobile,
    //         companyId: companyId,
    //         userCode: code,
    //         password,
    //         type,
    //         sendsms
    //     }).then((result: any) => {
    //         if (result.data.s) {
    //             // alert('Successfully Added User Driving School');
    //             message.success('Successfully Added User to Driving School');

    //             this.setState({
    //                 user: { ...user, id: result.data.id },
    //                 currentStep: 1,
    //                 loading: false
    //             });

    //             const { numberOfInstallments } = this.state;


    //             if (sendsms || drivingSchoolId !== '*') {
    //                 RRServer.callFn('notification1-sms', {
    //                     tmpl: "sms-template-boarding",
    //                     phone: mobile,
    //                     code,
    //                     pass: password,
    //                     params: {
    //                         code,
    //                         link,
    //                         name,
    //                         password
    //                     }
    //                 })
    //             }

    //         }
    //     }).catch(function (error) {
    //         alert(error);
    //     })

    // }




    _createCode = (userType: string, selectedCorporate: any) => {

        var code = selectedCorporate.code;
        var serial = userType === "user" ? (selectedCorporate.currentUserCount + 1) : (selectedCorporate.currentAdminCount + 1);
        var serialStr = "" + serial;
        var len = serialStr.length;
        for (let i = len; i < 3; i++) {
            serialStr = "0" + serialStr;
        }

        return {
            ...selectedCorporate,
            code: selectedCorporate.code.substr(0, 6) + serialStr,
            currentUserCount: selectedCorporate.currentUserCount + 1,
            currentAdminCount: selectedCorporate.currentAdminCount + 1
        }

    }

    createCode = (selectedCorporate: CorporateInterface, userType: string = "user") => {

        var code = selectedCorporate.code;
        var serial = userType === "user" ? (selectedCorporate.currentUserCount + 1) : (selectedCorporate.currentAdminCount + 1);
        var serialStr = "" + serial;
        var len = serialStr.length;
        for (let i = len; i < 3; i++) {
            serialStr = "0" + serialStr;
        }

        const corp = this._createCode(userType, selectedCorporate)

        selectedCorporate.code = corp.code;
        console.log(selectedCorporate, userType)

        this.setState({
            selectedCorporate
        })

    }

    onSelectCorporate = (value: string, option: any) => {
        const { corporates } = this.state;
        const selectedCorporate = corporates.find(c => c.id == value) || null;
        if (!selectedCorporate) { console.log('Corporate not found'); return }
        console.log(value, option, selectedCorporate);

        this.createCode(selectedCorporate)


        this.setState({
            selectedCorporateId: value,
            selectedCorporate

        })
    }
    onSelectType = (value: string, option: any) => {
        console.log(value, option);
        let { selectedCorporate } = this.state;
        if (!selectedCorporate) { return }
        const code = selectedCorporate ? selectedCorporate.code : '';


        const codeArr = code.split('')

        //Special Case
        //Check if First Character is Z/I
        const typeOfVehiclePosition = codeArr[0] === 'Z' ? 6 : 4;


        codeArr[typeOfVehiclePosition] = value;
        selectedCorporate.code = codeArr.join('');


        this.createCode(selectedCorporate)

        this.setState({
            selectedType: value

        })
    }
    onSelectUserType = (value: string, option: any) => {
        console.log(value, option);
        let { selectedCorporate } = this.state;
        if (!selectedCorporate) { return }
        const code = selectedCorporate ? selectedCorporate.code : '';
        const codeArr = code.split('');

        //Special Case
        //Check if First Character is Z/I
        //User types dont exst in IOCL
        if(codeArr[0] === 'Z'){
            return;
        }

        codeArr[5] = value === 'admin' ? 'A' : 'B';
        selectedCorporate.code = codeArr.join('');

        this.createCode(selectedCorporate, value)

        this.setState({
            selectedUserType: value,
        })
    }

    onFinish(values: any) {
        // values.code = this.state.userCode;
        // values.companyId = this.state.selectedSchool?.id;
        // console.log('Finish', values)
        this.saveCorporateUser(values);
    };

    onFinishFailed(errorInfo: any) {
        console.log('Failed:', errorInfo);
    };

    onSearch() { }

    onFormValueChange = (form: { [key: string]: any }) => {
        this.setState({ draftForm: { ...this.state.draftForm, ...form } })
    }

    incrementCounter(s: string) {
        let ss = s.split('');
        let alpha = ss.shift();
        let num = +ss.join('')
        num += 1;
        if (num > 99) {
            alpha = String.fromCharCode(alpha!.charCodeAt(0) + 1);
            num = 0;
        }
        const numStr = num < 10 ? ('0' + num) : num
        return alpha + '' + numStr;
    }

    saveCorporateUser(values: any) {
        const { selectedCorporate } = this.state;
        if (!selectedCorporate) { console.log('Corporate Not Selected'); return }
        console.log('Save', values)
        values.userCode = selectedCorporate.code;
        values.companyId = values.corporate;
        values.type = selectedCorporate.identifier;

        RRServer.callFn('corporate-addUser', {
            ...values
        })
            .then(r => {

                console.log('User Added', r);

                if (!values.dontsendsms) {
                    const link = 'https://play.google.com/store/apps/details?id=io.pyps.roadrakshak'

                    RRServer.callFn('notification1-sms', {
                        tmpl: "sms-template-boarding",
                        phone: values.mobile,
                        code: values.userCode,
                        pass: values.password,
                        params: {
                            code: values.userCode,
                            link,
                            name: values.name,
                            password: values.password
                        }
                    })
                        .then(r => window.location.reload())
                }

            })
    }


    fileUploadChange = (info: any) => {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    readFile = () => {
        const { file, corporates, selectedCorporateId } = this.state;

        const selectedCorporate = corporates.find(c => c.id == selectedCorporateId) || null;
        if (!selectedCorporate) { console.log('Corporate not found'); return }

        var workbook;
        var reader = new FileReader();
        reader.onload = (e: any) => {
            var data = new Uint8Array(e.target.result);
            workbook = XLSX.read(data, { type: 'array' });

            var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
            var fileData: UserData[] = XLSX.utils.sheet_to_json(first_worksheet, {});

            console.log(fileData);

            const { corporate, typeOfUser, userType, dontsendsms } = this.state.draftForm;

            let _selectedCorporate = selectedCorporate;
            const users = fileData.map(f => {

                _selectedCorporate = this._createCode(userType, _selectedCorporate);

                return {
                    ...f,
                    companyId: corporate,
                    typeOfUser,
                    code: _selectedCorporate.code,
                    dontsendsms,
                    type: selectedCorporate.identifier
                }
            })

            this.setState({
                users
            })
            console.log('Users', users)

        };
        reader.readAsArrayBuffer(file as File);
    }

    bulkUpload = () => {
        const { users } = this.state

        RRServer.callFn('corporate-bulkUpload', {
            users
        })
            .then(r => {
                console.log(r)
            })

    }

    render() {
        const { mode, loading, corporates, selectedCorporate, users } = this.state;
        const props = this.props;
        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || '#'

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        const tailLayout = {
            wrapperCol: { offset: 8, span: 16 },
        };


        const types = [
            0, 2, 4, 6, 8, 'T', 'B'
        ]

        return (
            <main>
                <Divider />

                <Title>{mode === 'edit' ? 'Edit ' : 'Add '} Corporate User</Title>

                <div style={{ width: '400px' }}>
                    <Divider />

                    <div>
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{
                                // ...props.user
                            }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                            onValuesChange={this.onFormValueChange}
                        >
                            <Form.Item
                                name="corporate"
                                label="Corporate"
                                hasFeedback
                                rules={[{ required: true, message: 'Please select a Corporate!' }]}
                            >
                                <Select placeholder="Please select a Corporate" onChange={this.onSelectCorporate}>
                                    {corporates.map(c => <Option key={c.id} value={c.id}>{c.corporateName}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="typeOfVehicle"
                                label="Type Of Vehicle"
                                hasFeedback
                                rules={[{ required: true, message: 'Please select a type!' }]}
                            >
                                <Select placeholder="Please select a type" onChange={this.onSelectType}>
                                    {types.map(c => <Option key={'k-' + c} value={c}>{c}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="typeOfUser"
                                label="Type Of User"
                                hasFeedback
                                rules={[{ required: true, message: 'Please select a User Type!' }]}
                            >
                                <Select placeholder="Please select a User Type" onChange={this.onSelectUserType}>
                                    <Option key={'k-admin'} value={'admin'}>Admin</Option>
                                    <Option key={'k-user'} value={'user'}>User</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Code"
                            >
                                {selectedCorporate && selectedCorporate.code}
                            </Form.Item>
                            <Form.Item
                                label="Employee ID"
                                name="employeeID"
                                rules={[{ required: true, message: 'Name' }]}
                            >
                                <Input placeholder="Employee ID" name="employeeID" />
                            </Form.Item>
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: 'Name' }]}
                            >
                                <Input placeholder="Name" name="name" />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{ required: true, message: 'Password' }]}
                            >
                                <Input placeholder="Password" />
                            </Form.Item>
                            <Form.Item
                                label="Mobile"
                                name="mobile"
                                rules={[{ required: true, message: 'Mobile' }]}
                            >
                                <Input placeholder="Mobile" />
                            </Form.Item>

                            <Form.Item
                                label="Dont Send SMS"
                                name="dontsendsms"
                                valuePropName="checked"
                                rules={[{ required: false, message: 'Send SMS' }]}
                            >
                                <Checkbox>Dont Send SMS</Checkbox>
                            </Form.Item>


                            <Form.Item {...tailLayout}>
                                <Button type="primary" htmlType={'submit'}>
                                    {mode === 'edit' ? 'Edit' : 'Create Corporate User'}
                                </Button>
                            </Form.Item>

                        </Form>




                        <div>

                            <Row justify="center" align="middle">
                                <Col span={18}>
                                    <input type='file' onChange={(ev: any) => this.setState({ file: ev.target.files[0] })} />
                                    <Button icon={<UploadOutlined />} onClick={this.readFile}>Click to Upload</Button>
                                </Col>
                            </Row>
                        </div>


                        <Row>
                            <table>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Password</th>
                                        <th>Code</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map(user => <tr>
                                        <td></td>
                                        <td>{user.name}</td>
                                        <td>{user.mobile}</td>
                                        <td>{user.password}</td>
                                        <td>{user.code}</td>
                                    </tr>)}
                                </tbody>

                            </table>
                            <Button onClick={this.bulkUpload}>Upload</Button>
                        </Row>






                    </div>

                </div>


            </main>
        )
    }
}

// export default withRouter(UserAdd);
export default withRouter(DrivingSchoolUserAdd);