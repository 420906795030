import React from 'react';
import { PageHeader } from 'antd';
import { Link, Route, withRouter, Switch } from 'react-router-dom';

import InstallmentsList from './InstallmentsList';
import InstallmentsAdd from './InstallmentsAdd';

import { OrderedListOutlined, UserAddOutlined } from '@ant-design/icons';



interface UserStateProps {
  
}

class Installments extends React.Component<any, UserStateProps> {
    constructor(props: any) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
    }

    render() {
        let { path, url } = this.props.match;

        return (
            <div className="App">
                <main>
                    <PageHeader
                        className = "installment-page-header"
                        ghost={false}
                        onBack={() => window.history.back()}
                        title="Installments"
                        subTitle=""
                        extra={[
                            // <Link to={`${url}/add`} key='0'>
                            //     Add School<UserAddOutlined />
                            // </Link>,
                            <Link to={`${url}`} key='1' style={{color: 'wheat', fontSize: '2.5vh'}}>
                                List<OrderedListOutlined />
                            </Link>,
                        ]}                        
                    >
                    </PageHeader>

                    <Switch>
                        <Route exact path={path}>
                            <InstallmentsList />
                        </Route>
                        {/* <Route path={`${path}/add`}>
                            <DrivingSchoolAdd />
                        </Route> */}
                        <Route path={`${path}/edit/:id`}>
                            <InstallmentsAdd />
                        </Route>
                        {/* <Route path={`${path}/view/:id`}>
                            <DrivingSchoolView />
                        </Route> */}
                    </Switch>

                </main>
            </div>
        );
    }
}

export default withRouter(Installments);