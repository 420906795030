import React from 'react';
import { PageHeader } from 'antd';
import { Link, Route, withRouter, Switch } from 'react-router-dom';

import EngagementList from './EngagementList';
import EngagementAdd from './EngagementAdd';

import { OrderedListOutlined, UserAddOutlined } from '@ant-design/icons';
import EngagementAddCorporate from './EngagementAddCorporate';



interface UserStateProps {

}

class Engagement extends React.Component<any, UserStateProps> {
    constructor(props: any) {
        super(props);
        this.state = {}
        // this.state =/Users/raj/Desktop/RR Copy/rr-backend-app/rr-backend-app/src/pages/Engagement/EngagementAddCorporate.tsx {}
    }

    componentDidMount() {
        
    }



    render() {
        let { path, url } = this.props.match;
        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || '';

        const links = [<Link to={`${url}/add`} key='0' style={{ color: 'wheat', fontSize: '2.5vh' }}>
                Add <UserAddOutlined />
            </Link>
        ];
        (claims.corporate || claims.drivingSchoolId==='*') && links.push(<Link to={`${url}/add-corporate`} key='0' style={{ color: 'wheat', fontSize: '2.5vh' }}>
            Add Corporate<UserAddOutlined />
        </Link>)
        

        return (
            <div className="App">
                <main>
                    <PageHeader className="engagements-page-header"
                        ghost={false}
                        onBack={() => window.history.back()}
                        title="Engagements"
                        subTitle=""
                        extra={links}
                    >
                    </PageHeader>

                <Switch>
                    <Route exact path={path}>
                        <EngagementList />
                    </Route>
                    <Route path={`${path}/add-corporate`}>
                        <EngagementAddCorporate />
                    </Route>
                    <Route path={`${path}/add`}>
                        <EngagementAdd />
                    </Route>
                    <Route path={`${path}/edit/:id`}>
                        <EngagementAdd />
                    </Route>
                    {/* <Route path={`${path}/view/:id`}>
                            <DrivingSchoolView />
                        </Route> */}
                </Switch>

                </main>
            </div >
        );
    }
}

export default withRouter(Engagement);