import React from 'react';
import { Button, Divider, Form, Input, message, Typography, Steps, Modal, Table, Select, Card, Checkbox, DatePicker, Skeleton } from 'antd';
import { withRouter } from 'react-router-dom';
import { DrivingSchoolInterface, InstructorInterface, UserInterface, DAYS, DayType, StudentInterface, EMPTY_SLOTS, DateTimeType, DRIVING_SCHOOL_USER_TYPES, InstallmentInterface, CorporateInterface, CorporatePetroleumInterface, CORPORATE_USER_TYPES } from '../../types';
// import Select, { OptionsType } from 'react-select'
import commonStyles from '../../common/css/common.module.css';
import { formatTime, getClaims, RRServer } from '../../utils/lib';
import TableSelect from './sub-componnets/TableSelect';
import dayjs from 'dayjs';

import firebase from '../../utils/Firebase'

import Styles from './DrivingSchoolUserAdd.module.css';
import moment from 'moment';
import Installments from '../Installments/Installments';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { DeleteFilled, PlusCircleOutlined } from '@ant-design/icons';
import { SkeletonParagraphProps } from 'antd/lib/skeleton/Paragraph';


const { Title } = Typography;
const { Step } = Steps;
const { Option } = Select;



interface CorporatePetroleumAddState {

    mode: "edit" | "add",
    loading: boolean,
    stateStrings: string[]
    currentString: string
    corporate?: CorporatePetroleumInterface

}


export interface InstallmentLocalInterface {
    dueDate: string;
    id?: string
    uid?: string
    companyId?: string
    status: number
    paidOn: number
    installment: string,

}

class CorporatePetroleumAdd extends React.Component<any, CorporatePetroleumAddState> {
    dsDropdown: React.RefObject<unknown>;
    CLASS_SCHEDULES: DateTimeType[] = [];
    unregisterAuthObserver: firebase.Unsubscribe | undefined;

    constructor(props: any) {
        super(props);
        this.state = {
            mode: !!props.match.params.id ? 'edit' : 'add',
            loading: true,
            stateStrings: [],
            currentString: ''
        }
        this.onFinish = this.onFinish.bind(this);
        this.dsDropdown = React.createRef();
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if (id) {
            RRServer.get({ table: RRServer.Tables.CORPORATES, id: id })
                .then((r: any) => {
                    console.log('Corporate', r.data.data);
                    const corporate = r.data.data as CorporatePetroleumInterface;
                    this.setState({
                        corporate,
                        stateStrings: corporate.stateStrings as string[],
                        loading: false
                    })
                })
        } else {
            this.setState({ loading: false })
        }

    }


    onFinish(values: any) {
        values.currentAdminCount = 0;
        values.currentUserCount = 0;
        const { stateStrings, mode } = this.state;
        values.stateStrings = stateStrings;
        if (mode == 'add') {
            this.saveCorporateUser(values)
        }else if(mode=='edit'){
            this.editCorporateUser(values)
        }
    };

    onFinishFailed(errorInfo: any) {
        console.log('Failed:', errorInfo);
    };

    onSearch() { }

    onFormValueChange = (form: { [key: string]: any }) => {
        if (form.currentString) {
            this.setState({ currentString: form.currentString })
        }
    }

    saveCorporateUser(values: any) {
        this.setState({ loading: true })
        values.corporateType=CORPORATE_USER_TYPES.PETROLEUM;
        RRServer.callFn('corporate-addPetroleum', values)
            .then((r: any) => {
                if (r.data.s) {
                    this.setState({ loading: false })
                    alert('Added Corporate')
                }
            })
    }
    editCorporateUser(values: any) {
        this.setState({ loading: true })
        const id=this.props.match.params.id;

        RRServer.update({table:RRServer.Tables.CORPORATES,id,...values})
            .then((r: any) => {
                if (r.data.s) {
                    this.setState({ loading: false })
                    alert('Updated Corporate')
                }
            })
    }

    addStateString = () => {
        const { stateStrings, currentString } = this.state
        const typeOfVehicles = ['B', 'T']
        if (currentString !== '') {
            typeOfVehicles.map(t => stateStrings.push(currentString + t));
        }
        this.setState({ stateStrings, currentString: '' })
    }

    removeStateString = (ss: string) => this.setState({ stateStrings: this.state.stateStrings.filter(s => s != ss) })

    render() {
        const { mode, loading, stateStrings, currentString, corporate } = this.state;
        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || ''

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        const tailLayout = {
            wrapperCol: { offset: 8, span: 16 },
        };

        const currentData = corporate ? corporate : {};


        return (
            <main>
                <Divider />

                <Title>{mode === 'edit' ? 'Edit ' : 'Add '} Corporate Petroleum</Title>

                <div style={{ width: '400px' }}>
                    <Divider />

                    {
                        loading
                            ? <Skeleton />
                            :
                            <div>

                                <Form
                                    {...layout}
                                    name="basic"
                                    initialValues={{
                                        // ...props.user
                                        currentString,
                                        ...currentData,

                                    }}
                                    onFinish={this.onFinish}
                                    onFinishFailed={this.onFinishFailed}
                                    onValuesChange={this.onFormValueChange}
                                >
                                    <Form.Item
                                        name="identifier"
                                        label="Identifier"
                                        hasFeedback
                                        rules={[{ required: true, message: 'Please select  user type!' }]}
                                    >
                                        <Input placeholder="Identifier" name="identifier" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Name"
                                        name="corporateName"
                                        rules={[{ required: true, message: 'Name' }]}
                                    >
                                        <Input placeholder="Name" name="name" />
                                    </Form.Item>


                                    <Form.Item
                                        label="String"
                                        name={"currentString"}
                                        rules={[{ required: true, message: 'SN' }]}
                                    >
                                        <Input placeholder="SN" name={"String"} />
                                    </Form.Item>


                                    {!!currentString && <button onClick={this.addStateString} type='button' className='btn btn-success'><PlusCircleOutlined /></button>}
                                    {stateStrings.map(ss => <div className='card bordered position-relative p-2' key={ss}>
                                        <button onClick={() => this.removeStateString(ss)} type='button' className='btn btn-danger  position-absolute t-0 r-0'>
                                            <DeleteFilled />
                                        </button>
                                        <Title level={5}>{ss}</Title>
                                        <hr />

                                        <Form.Item
                                            label="Start Number"
                                            name={ss + "Counter"}
                                            rules={[{ required: true, message: 'SN' }]}

                                        >
                                            <Input placeholder="SN" name={ss + "Counter"} type={"number"} />
                                        </Form.Item>
                                        <Form.Item
                                            label="Start Series"
                                            name={ss + "SeriesCounter"}
                                            rules={[{ required: true, message: 'Series' }]}
                                        >
                                            <Input placeholder="Series" name={ss + "SeriesCounter"} type={"number"} />
                                        </Form.Item>
                                    </div>)}







                                    <Form.Item
                                        label="Code"
                                        name="code"
                                    >
                                        <Input placeholder="Code" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Bottling Plant SOS"
                                        name="bottlingPlantSOS"
                                    >
                                        <Input placeholder="Bottling Plant SOS" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Terminal SOS"
                                        name="terminalSOS"
                                    >
                                        <Input placeholder="Terminal SOS" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        rules={[{ required: true, message: 'Password' }]}
                                    >
                                        <Input placeholder="Password" />
                                    </Form.Item>

                                    <Form.Item {...tailLayout}>
                                        <Button type="primary" htmlType="submit" loading={loading}  >
                                            {mode === 'edit' ? 'Edit' : 'Create Corporate'}
                                        </Button>
                                    </Form.Item>

                                </Form>

                            </div>}

                </div>


            </main>
        )
    }
}

// export default withRouter(UserAdd);
export default withRouter(CorporatePetroleumAdd);