import React from 'react';
import firebase from '../../utils/Firebase';
import { PageHeader } from 'antd';
import { Link, Route, withRouter, Switch } from 'react-router-dom';
import { OrderedListOutlined, UserAddOutlined } from '@ant-design/icons';
import CorporateUserList from './CorporateUserList';
import { RRServer } from '../../utils/lib';
import { DrivingSchoolInterface } from '../../types';
import CorporateAdd from './CorporateAdd';
import CorporatePetroleumAdd from './CorporatePetroleumAdd';
import CorporateList from '../CorporateUser/CorporateList';


class Corporate extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        let { path, url } = this.props.match;

        return (
            <div className="App">
                <main>
                    <PageHeader
                        ghost={false}
                        onBack={() => window.history.back()}
                        title="Corporates"
                        subTitle=""
                        extra={[
                           
                            <Link to={`${url}/add-petroleum-corporate`} key='0'>
                                Add Petroleum Corporate <UserAddOutlined />
                            </Link>,
                            <Link to={`${url}/add-corporate`} key='1'>
                                Add Corporate <UserAddOutlined />
                            </Link>,
                            <Link to={`${url}`} key='2'>
                                List<OrderedListOutlined />
                            </Link>,
                        ]}
                    >
                    </PageHeader>

                    <Switch>
                        <Route exact path={path}>
                            <CorporateList drivingSchool={this.props.drivingSchool} />
                        </Route>
                        <Route path={`${path}/add-petroleum-corporate`}>
                            <CorporatePetroleumAdd />
                        </Route>
                        <Route path={`${path}/edit-petroleum-corporate/:id`}>
                            <CorporatePetroleumAdd />
                        </Route>
                        <Route path={`${path}/add-corporate`}>
                            <CorporateAdd />
                        </Route>
                    </Switch>

                </main>
            </div>
        );
    }
}

export default withRouter(Corporate);