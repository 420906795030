import React from 'react';
import { Button, Divider, Form, Input, message, Select, Skeleton, Switch } from 'antd';
import { withRouter } from 'react-router-dom';
import MaskedInput from 'antd-mask-input'
import { callOnServer, RRServer } from '../../utils/lib';
import { CorporateInterface, InstallmentInterface, StudentInterface, UserInterface } from '../../types';
import { promises } from 'fs';
import { CheckOutlined, CloseOutlined, UserOutlined } from '@ant-design/icons';
import DrivingSchools from '../DrivingSchools/DrivingSchools';
import TextArea from 'antd/lib/input/TextArea';


interface DrivingSchoolInterface {
    code: string;
    id: string
    drivingSchoolName: string
    currentCount: string
    sosNumber: string
}
interface CorporateCodeInterface {
    password: string
    id: string
}

interface DrivingSchoolAddInterface {
    currentSchool: null | DrivingSchoolInterface,
    currentInstallment: null | InstallmentInterface,
    currentUser: null | UserInterface,
    schools: CorporateInterface[]
    recepients: StudentInterface[]
}

const { Option } = Select;

class EngagementAddCorporate extends React.Component<any, DrivingSchoolAddInterface> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentSchool: null,
            currentInstallment: null,
            currentUser: null,
            schools: [],
            recepients: []
        }
        this.onFinish = this.onFinish.bind(this);
        // this.editInstallment = this.editInstallment.bind(this);
    }

    componentDidMount() {
        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || ''

        const promises = [];
        // if (claims.drivingSchoolId !== '*') {
        promises.push(RRServer.list({ table: RRServer.Tables.CORPORATES, forCurrentUser: false }))
        // promises.push(RRServer.list({ table: RRServer.Tables.ENGAGEMENTS, forCurrentUser: false, where: [['drivingSchoolId', '==', claims.drivingSchoolId]] }))
        // } else {
        // promises.push(RRServer.list({ table: RRServer.Tables.DRIVING_SCHOOLS, forCurrentUser: false }))
        // promises.push(RRServer.list({ table: RRServer.Tables.ENGAGEMENTS, forCurrentUser: false }))
        // }

        Promise.all(promises)
            .then((results: any) => {
                console.log(results);
                const schools = results[0].data.data;


                this.setState({ schools })
            })
    }


    // editInstallment(installment: InstallmentInterface) {
    //     const { mode, currentInstallment } = this.state;

    //     installment.status = installment.status ? 1 : 0;
    //     if (mode === 'edit') {
    //         RRServer.update({ id: currentInstallment!.id, status: installment.status, table: RRServer.Tables.INSTALLMENTS })
    //             .then((result: any) => {
    //                 console.log(result);
    //                 if (result.data.s) {
    //                     message.success('Successfully Updated Installment Status');
    //                     setTimeout(() => window.location.href = '/installments', 200)
    //                 }
    //             })
    //     } else {

    //     }

    // }

    onFinish(values: any) {
        console.log(values);
        // this.editInstallment(values)

        values.condition = [["companyId", "==", values.corporateId]];


        RRServer.callFn('notification1-push', values)

    };

    onFinishFailed(errorInfo: any) {
        console.log('Failed:', errorInfo);
    };

    onSearch() {
    }

    getStudents = (drivingSchoolId: string) => {

        RRServer.list({ table: RRServer.Tables.USERS, forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] })
            .then((r: any) => {
                this.setState({ recepients: r.data.data })
            })

    }




    render() {
        const { currentSchool, currentInstallment, currentUser, schools, recepients } = this.state

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        const tailLayout = {
            wrapperCol: { offset: 8, span: 16 },
        };


        return (
            <main>
                <Divider />
                <div style={{ width: '400px' }}>


                    <Form
                        {...layout}
                        name="basic"
                        initialValues={{
                            ...currentInstallment,
                            ...currentSchool,
                            ...currentUser

                        }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        onValuesChange={(changedValues, allValues) => changedValues.corporateId && this.getStudents(changedValues.corporateId)}
                    >
                        <Form.Item
                            label="Corporate"
                            name="corporateId"
                            rules={[{ required: true, message: 'Corporate Name' }]}
                        >
                            <Select placeholder="Please select a Corporate" defaultValue={schools.length == 1 ? schools[0].id : 'Please select a Corporate'}>
                                {schools.map(school => <Option key={school.id} value={school.id}>{school.corporateName}</Option>)}
                            </Select>
                        </Form.Item>
                        {/* <Form.Item
                            label="Recepients"
                            name="recepients"
                            rules={[{ required: true, message: 'School Name' }]}
                        >
                            <Select mode="multiple" placeholder="Please select a Recepient"   >
                                {recepients.map(school => <Option key={school.id} value={school.id}>{school.name}</Option>)}
                            </Select>
                        </Form.Item> */}

                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[{ required: true, message: 'Type' }]}
                        >
                            <Select placeholder="Please select a Type" >
                                {/* <Option value={'SMS'}>SMS</Option>) */}
                                <Option value={'PUSH_NOTIFICATION'}>Push Notification</Option>)
                                {/* <Option value={'WHATSAPP'}>WhatsApp</Option>) */}
                                {/* <Option value={'EMAIL'}>Email</Option>) */}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[{ required: true, message: 'Title' }]}
                        >
                            <Input   />

                        </Form.Item>
                        <Form.Item
                            label="Content"
                            name="body"
                            rules={[{ required: true, message: 'Content' }]}
                        >
                            <TextArea></TextArea>
                        </Form.Item>
                        {/* <Select placeholder="Please select a School" defaultValue={schools.length == 1 ? schools[0].id : 'Please select a School'}>
                            {schools.map(school => <Option value={school.id}>{school.drivingSchoolName}</Option>)}
                        </Select> */}

                        {/* <Form.Item
                                label="School"
                                name="drivingSchoolName"
                                rules={[{ required: false, message: 'SOS Number' }]}
                            >
                                <Input bordered={false} disabled={true} />
                            </Form.Item>
                            <Form.Item
                                label="User"
                                name="name"
                                rules={[{ required: false, message: 'SOS Number' }]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} bordered={false} disabled={true} />
                            </Form.Item>
                            <Form.Item
                                label="Installment"
                                name="installment"
                                rules={[{ required: false, message: 'SOS Number' }]}
                            >
                                <Input disabled={true} bordered={false} />
                            </Form.Item>

                            <Form.Item
                                label="Paid"
                                name="status"
                                rules={[{ required: false, message: 'SOS Number' }]}
                            >
                                <Switch
                                    defaultChecked={!!currentInstallment!.status}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </Form.Item> */}



                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit" >
                                Send
                                </Button>
                        </Form.Item>
                    </Form>

                </div>
            </main>
        );
    }
}

export default withRouter(EngagementAddCorporate);