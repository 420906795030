import { Row, Col, Card, Tabs, Skeleton, Table } from 'antd';
import Statistic from 'antd/lib/statistic';
import React from 'react';
import { CorporateCodeInterface, DrivingSchoolInterface, ScoreInterface, UserInterface } from '../../types';
import { RRServer } from '../../utils/lib';
import Style from './AP-TrafficTuesdayUpdates.module.css'

import { PieChart, Pie, Tooltip, ResponsiveContainer } from 'recharts';
import dayjs from 'dayjs';


const { TabPane } = Tabs;


interface DashProps {
    schools: DrivingSchoolInterface[]
    corporateCodes: CorporateCodeInterface[],
    corporateCodesCount: number,
    drivingSchoolCodesCount: number,
    studentsCount: number,
    instructorCount: number,
    adminCount: number,
    loading: boolean,

    scores: ScoreInterface[],
    users: UserInterface[],
    scoresHashed: any,
    scoresByAttempt: any
}
class APTrafficTuesdayUpdates extends React.Component<null, DashProps> {


    constructor(props: null) {
        super(props);

        this.state = {
            loading: true,
            schools: [],
            corporateCodes: [],
            corporateCodesCount: 0,
            drivingSchoolCodesCount: 0,
            studentsCount: 0,
            instructorCount: 0,
            adminCount: 0,

            scores: [],
            users: [],
            scoresHashed: {},
            scoresByAttempt: {}
        }
    }


    calculateScores = (scores: ScoreInterface[]) => {
        let _scores: { [key: string]: any } = {};

        scores.filter(s => s.extra.daily).map(score => {
            _scores[score.game] = _scores[score.game] || {
                numUsers: 0,
                score: 0
            };

            _scores[score.game].numUsers += 1;
            _scores[score.game].score += score.score;

        })
        let _scoresByLevel: { [key: number]: any } = {};

        scores.filter(s => s.extra.daily).map(score => {
            _scoresByLevel[score.level] = _scores[score.level] || {
                numUsers: 0,
                score: 0
            };

            _scoresByLevel[score.level].numUsers += 1;
            _scoresByLevel[score.level].score += score.score;
            _scoresByLevel[score.level].level = score.level;

        })
        let _scoresByAttempt: { [key: number]: any } = {
            1: {
                numUsers: 0,
                score: 0,
                time: []
            }, 2: {
                numUsers: 0,
                score: 0,
                time: []
            }
        };

        scores.filter(s => s.extra.daily).map(score => {
            // _scoresByAttempt[score.attemptNumber] = _scores[score.attemptNumber] || {
            //     numUsers: 0,
            //     score: 0
            // };

            _scoresByAttempt[score.attemptNumber].numUsers += 1;
            _scoresByAttempt[score.attemptNumber].score += score.score;
            _scoresByAttempt[score.attemptNumber].level = score.level;
            _scoresByAttempt[score.attemptNumber].attemptNumber = score.attemptNumber;
            _scoresByAttempt[score.attemptNumber].time.push(score.addedOn);

        })
        console.log(scores, _scoresByAttempt)
        this.setState({
            scores,
            scoresHashed: _scoresByLevel,
            scoresByAttempt: _scoresByAttempt

        })

    }


    componentDidMount() {

        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || '';

        console.log('Claims', claims);
        if (!claims.drivingSchoolId) {
            alert('Dirvind School Not Found');
            return;
        }

        const cid = claims.drivingSchoolId;

        Promise.all([
            RRServer.list({ table: RRServer.Tables.USERS, forCurrentUser: false, where: [["companyId", "==", cid]] }),
            RRServer.list({ table: RRServer.Tables.SCORES, forCurrentUser: false, where: [["cid", "==", cid]] })
        ])
            .then((r: any) => {

                const users = r[0].data.data;
                const scores: ScoreInterface[] = r[1].data.data;

                this.calculateScores(scores);
                this.setState({
                    users
                })



            })



    }


    showDashItem = (loading: boolean, title: String, value: number) => {
        return loading
            ? <Skeleton.Button active={true} size={'large'} />
            : < Statistic
                title={title}
                value={value}
                precision={0}
                valueStyle={{ color: '#3f8600' }
                }
            />
    }

    changeDate = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const scores = this.state.scores;


        const start = dayjs(ev.target.value).valueOf()
        const end = dayjs(ev.target.value).add(1, 'day').valueOf()

        console.log(start, end)

        this.calculateScores(scores.filter(s => s.addedOn > start && s.addedOn < end))

    }



    render() {
        const { schools, drivingSchoolCodesCount, adminCount, instructorCount, studentsCount, users, loading, scores, scoresHashed, scoresByAttempt } = this.state;

        let min1Time = 0, max1Time = 0;
        let min2Time = 0, max2Time = 0;
        if (scoresByAttempt[1]) {
            min1Time = Math.min.apply(this, scoresByAttempt[1].time);
            max1Time = Math.max.apply(this, scoresByAttempt[1].time);
            console.log(min1Time, max1Time)
        }
        if (scoresByAttempt[2]) {
            min2Time = Math.min.apply(this, scoresByAttempt[2].time);
            max2Time = Math.max.apply(this, scoresByAttempt[2].time);
            console.log(min2Time, max2Time)
        }

        let dt = new Date();

        const data01 = [
            { name: 'Group A', value: 400 },
            { name: 'Group B', value: 300 },
            { name: 'Group C', value: 300 },
            { name: 'Group D', value: 200 },
            { name: 'Group E', value: 278 },
            { name: 'Group F', value: 189 },
        ];

        return (
            <div className="container mt-5">
                <h2>Traffic Tuesday Updates</h2>
                <div className={Style.spacer} />
                <div style={{ width: '100%', height: '2px', borderBottom: '0.5px solid rgba(0,0,0,0.1)' }} ></div>
                <div className={Style.spacer} />

                <div className="d-flex flex-row justify-content-between align-items-center" style={{ marginTop: '0%' }}>
                    <input type="date" className="form-control" style={{ width: '15%' }} onChange={this.changeDate} />
                </div>

                <div className={Style.spacer} />


                <div className="d-flex flex-row justify-content-between align-items-center" style={{ marginTop: '2%' }}>
                    <h2 className={Style.header}>Quantitative Analysis</h2>
                    {/* <input type="date" className="form-control" style={{ width: '15%' }} /> */}
                </div>


                <div className="row d-flex justify-content-center align-items-start mt-5">
                    <div className="col-9">
                        <Card className={Style.tableCard} >
                            <table className="mb-0" style={{ marginLeft: '0vw', width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th>Game Level</th>
                                        <th># Employees Attempted</th>
                                        <th>Average Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.values(scoresHashed).map((score: any) => <tr>
                                        <th scope="row">{score.level}</th>
                                        <td>{score.numUsers}</td>
                                        <td>{score.score / score.numUsers}</td>
                                    </tr>
                                    )}
                                </tbody>
                            </table>
                        </Card>
                    </div>
                    <div className="col">
                        <PieChart width={400} height={400}>
                            <Pie data={data01} dataKey="value" cx="50%" cy="50%" outerRadius={60} fill="#8884d8" />
                        </PieChart>
                    </div>
                </div>
                <div className={Style.spacer} />
                <div style={{ width: '100%', height: '2px', borderBottom: '0.5px solid rgba(0,0,0,0.1)' }} ></div>

                <div className="d-flex flex-row justify-content-between align-items-center" style={{ marginTop: '2%' }}>
                    <h2 className={Style.header}>Qualitative Analysis</h2>
                    {/* <input type="date" className="form-control" style={{ width: '15%' }} /> */}
                </div>

                <div className="row d-flex justify-content-center align-items-start mt-5">
                    <div className="col-9">
                        <Card className={Style.tableCard} >
                            <table className="mb-0" style={{ marginLeft: '0vw', width: '100%' }}>
                                <thead>
                                    <tr style={{ fontWeight: 100 }}>
                                        <th># Employees Cleared in 1st Attempt</th>
                                        <th># Employees Cleared in 2nd Attempt</th>
                                        <th>1st Attempt Average Score</th>
                                        <th>2nd  Attempt Average Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!scoresByAttempt[1] ? "" :
                                        <tr>
                                            <th scope="row">{scoresByAttempt[1].numUsers || 0}</th>
                                            <td>{scoresByAttempt[2].numUsers || 0}</td>
                                            <td>{Number(!scoresByAttempt[1].score ? 0 : (scoresByAttempt[1].score) / (scoresByAttempt[1].numUsers)).toFixed(2)}</td>
                                            <td>{Number(!scoresByAttempt[2].score ? 0 : scoresByAttempt[2].score / (scoresByAttempt[2].numUsers)).toFixed(2)}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </Card>
                    </div>
                    <div className="col" style={{ height: '100%' }}>
                        <Card className={Style.playedCard}>
                            <div>
                                <p style={{ fontSize: '1.5rem', opacity: 0.7 }}>Most Played Time of the Day </p><br />
                                <h2> {dayjs(min1Time).format('HH:mm a')}  to {dayjs(min2Time).format('HH:mm a')}</h2>

                            </div>
                        </Card>
                    </div>
                </div>
                <div className={Style.spacer} />
            </div>)
    }
}

export default APTrafficTuesdayUpdates;