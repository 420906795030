import React from 'react';
import { Button, Divider, Form, Input, message, Skeleton, Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import MaskedInput from 'antd-mask-input'
import { callOnServer, RRServer } from '../../utils/lib';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { confirm } = Modal;

interface DrivingSchoolInterface {
    code: string;
    id: string
    drivingSchoolName: string
    currentCount: string
}
interface CorporateCodeInterface {
    password: string
    id: string
}

interface DrivingSchoolAddInterface {
    currentSchool: null | DrivingSchoolInterface,
    corporateCode: null | CorporateCodeInterface
}

class DrivingSchoolView extends React.Component<any, DrivingSchoolAddInterface> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentSchool: null,
            corporateCode: null,
        }
        this.removeDrivingSchool = this.removeDrivingSchool.bind(this);

    }

    componentDidMount() {
        Promise.all([
            RRServer.get( { table: 'driving_schools', id: this.props.match.params.id }),
            callOnServer('find', { table: 'corporate_codes', where: [['companyId', '==', this.props.match.params.id]] })
        ]).then((results: any) => {
            if(!results[0].data.data){
                message.error('School not found');
            }
            this.setState({
                currentSchool: results[0].data.data,
                corporateCode: results[1].data.data
            })
        })
    }


    removeDrivingSchool(id: string) {
        return Promise.all([
            callOnServer('common-remove', { id, table: 'driving_schools' }),
            callOnServer('common-remove', { where: [['companyId', '==', id]], table: 'corporate_codes' })
        ])
            .then((results: any) => {
                if (results[0].data.s && results[1].data.s) {
                    message.success('Successfully Removed Driving School');
                    setTimeout(() => window.location.href = '/driving-schools', 200)
                }
            })


    }


    onOk = (id: string) => () => this.removeDrivingSchool(id).catch(() => console.log('Oops errors!'))

    showPromiseConfirm = () => {
        const id = this.props.match.params.id;
        confirm({
            title: 'Do you want to delete these items?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be reversed. This will delete the drinvg school, corporate codes and the users for this school',
            onOk: this.onOk(id),
            onCancel() { },
        });
    }




    render() {
        const { currentSchool, corporateCode } = this.state

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };

        const tailLayout = {
            wrapperCol: { offset: 18, span: 16 },
        };




        return (
            <main>
                <Divider />
                <div style={{ width: '400px' }}>

                    {currentSchool === null
                        ? <Skeleton active />
                        : <Form
                            {...layout}
                            name="basic"
                            initialValues={{
                                ...currentSchool,
                                password: corporateCode && corporateCode.password
                            }}
                        // onValuesChange={(changedValues, allValues) => console.log(changedValues, allValues)}
                        >
                            <Form.Item
                                label="Code"
                                name="code"
                                rules={[{ required: true, message: 'Please select Driving School!' }]}
                            >
                                <MaskedInput mask="111111A00" name="code" size={'small'} />
                            </Form.Item>

                            <Form.Item
                                label="Name"
                                name="drivingSchoolName"
                                rules={[{ required: true, message: 'Name' }]}
                            >
                                <Input placeholder="Name" />
                            </Form.Item>
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{ required: true, message: 'Name' }]}
                            >
                                <Input placeholder="Name" />
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <Button type="ghost" danger onClick={this.showPromiseConfirm}>
                                    Delete
                                </Button>
                            </Form.Item>

                        </Form>
                    }
                </div>
            </main>
        );
    }
}

export default withRouter(DrivingSchoolView);