
export type DRIVING_SCHOOL_USER_TYPES = 'DRIVING_SCHOOL_USER' | 'DRIVING_SCHOOL_ADMIN' | 'DRIVING_SCHOOL_INSTRUCTOR' | 'DRIVING_SCHOOL_OFFICE_ADMIN';
export enum USER_TYPES {
  DRIVING_SCHOOL_USER = 'DRIVING_SCHOOL_USER',
  DRIVING_SCHOOL_ADMIN = 'DRIVING_SCHOOL_ADMIN',
  DRIVING_SCHOOL_INSTRUCTOR = 'DRIVING_SCHOOL_INSTRUCTOR',
  DRIVING_SCHOOL_OFFICE_ADMIN = 'DRIVING_SCHOOL_OFFICE_ADMIN'

}
export enum CORPORATE_USER_TYPES {
  PETROLEUM = 'PETROLEUM'
}

export interface UserInterface {
  code: string
  companyId: string
  id: string
  mobile: string
  name: string
  type: string
  password: string
  instructorId: string,
  lastLogin?: number,
  slots?: SlotInterface
  fcmToken?: string

}

export interface PaymentInterface {
  id: string
  schoolId: string
  amount: number
  codesToBuy: number
  pricePerCode: number
  addedOn: number
}


interface DocumentDetailsInterface {
  document: string
  valid: boolean
  submitted: boolean
}
export interface DocumentsInterface {
  [key: string]: DocumentDetailsInterface
  Address: DocumentDetailsInterface
  Age: DocumentDetailsInterface
  IDProof: DocumentDetailsInterface
}

export type DateTimeType = {
  date: string,
  time: string
}

export type ClassScheduleType = { [key: string]: DateTimeType[] }

export interface ClassScheduleInterface {
  id: string
  userId: string
  companyId: string
  instructorId: string
  date: string
  time: string
  rating?: number
  ratingSaved?: boolean
  pinValidatedTime?: number
  rescheduleRequest?: boolean,
  classEndTime?: string
  student: string
  studentId: string
}


export interface StudentInterface {
  id: string,
  name: string,
  mobile: string,
  status?: boolean,
  llr?: boolean,
  dl?: boolean,
  documents?: DocumentsInterface,
  instructorId?: string
  slots?: SlotInterface
  classSchedules?: ClassScheduleType

  // classassigned: ClassAssignedInterface,
}


export interface DrivingSchoolInterface {
  code: string;
  id: string
  drivingSchoolName: string
  currentCount: string
  currentCountAdmin: string;
  instructorCurrentCount: any;
  officeAdminCurrentCount: any;
  profileImage?: string;
  drivingSchoolUnionImg?: string

}

export interface CorporateInterface {
  code: string
  corporateName: string
  currentCount: number
  currentAdminCount: number
  currentUserCount: number
  identifier: string
  id: string
}
export interface CorporatePetroleumInterface extends CorporateInterface{
  stateStrings:string[]
  [x:string]:string|number|string[] 
}

export interface CorporateCodeInterface {
  type: string
}

export const DAYS: DayType[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export type DayType = "Sun" | "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat";
export type SlotInterface = {
  [key in DayType]: string[];
};
export const EMPTY_SLOTS: SlotInterface = { "Sun": [], "Mon": [], "Tue": [], "Wed": [], "Thu": [], "Fri": [], "Sat": [] };


export interface InstructorInterface {
  id: string
  name: string
  mobile: string,
  slots: SlotInterface,
  companyId: string
  classSchedules?: ClassScheduleType

}

export type GenericObject = Record<string, any>;


export interface InstallmentInterface {
  id: string
  uid: string
  companyId: string
  status: number
  paidOn: number
  installment: string
  dueDate: string
}

export interface ScoreInterface {
  cid: string
  uid: string
  game: string
  level: number
  score: number
  attemptNumber: number
  addedOn: number
  extra: {
    daily: boolean
  }
}
