import React from 'react';
import { withRouter } from 'react-router-dom';

import { DatePicker, Select, Table } from 'antd';
import { CorporateInterface, DrivingSchoolInterface, UserInterface } from '../../types';
import { RRServer } from '../../utils/lib';
import dayjs from 'dayjs';

const { Option } = Select;

interface ScoreInterface {
    id: string
    cid: string
    uid: string
    game: string
    level: number
    score: number
    addedOn: number
}

interface ScoresViewStateProps {
    scores: ScoreInterface[]
    loading: boolean
    schools: DrivingSchoolInterface[],
    corporates: CorporateInterface[],
    users: UserInterface[]
    filterSchoolId: string,
    filterUserId: string,
    filterDate: number,
    userHashed: { [key: string]: UserInterface },
    schoolsHashed: { [key: string]: DrivingSchoolInterface },
    corpHashed: { [key: string]: CorporateInterface },
    drivingSchoolId:string
}

class BBJourner extends React.Component<any, ScoresViewStateProps>{
    constructor(props: any) {
        super(props);

        const claimsStr = localStorage.getItem('claims');
        const claims = !!claimsStr ? JSON.parse(claimsStr) : {};

        this.state = {
            loading: true,
            scores: [],
            schools: [],
            corporates: [],
            users: [],
            filterSchoolId: '',
            filterUserId: '',
            filterDate: 0,
            userHashed: {},
            schoolsHashed: {},
            corpHashed: {},
            drivingSchoolId:'dWCDFblM44jUJkuqijW8'
        };
    }

    getData=(id?:string)=>{
        const claimsStr = localStorage.getItem('claims');
        const claims = !!claimsStr ? JSON.parse(claimsStr) : {};
        let {drivingSchoolId} = this.state;//'dWCDFblM44jUJkuqijW8';//claims.drivingSchoolId || '';

        drivingSchoolId=id?id:drivingSchoolId;
        
        let promiseArr: any = [];
        if (false) {//drivingSchoolId === "*") {
            promiseArr = [
                RRServer.list({ table: "bb_journey", forCurrentUser: false }),
                RRServer.list({ table: "users", forCurrentUser: false }),
                RRServer.list({ table: RRServer.Tables.DRIVING_SCHOOLS, forCurrentUser: false }),
                RRServer.list({ table: RRServer.Tables.CORPORATES, forCurrentUser: false })
            ]
        } else {
            promiseArr = [
                RRServer.list({ table: "bb_journey", forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] }),
                RRServer.list({ table: "users", forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] }),
                RRServer.list({ table: RRServer.Tables.DRIVING_SCHOOLS, forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] }),
                RRServer.list({ table: RRServer.Tables.CORPORATES, forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] })
            ]
        }

        Promise.all(promiseArr)
            .then((results: any) => {
                const scores = results[0].data.data;
                const users = results[1].data.data as UserInterface[];
                const schools = results[2].data.data as DrivingSchoolInterface[];
                const corporates = results[3].data.data as CorporateInterface[];

                let userHashed: { [key: string]: UserInterface } = {};
                users.map(user => userHashed[user.id] = user);

                let schoolsHashed: { [key: string]: DrivingSchoolInterface } = {};
                schools.map(school => schoolsHashed[school.id] = school);

                let corpHashed: { [key: string]: CorporateInterface } = {};
                corporates.map(school => corpHashed[school.id] = school);

                this.setState({ loading: false, scores, users, userHashed, schools, schoolsHashed, corporates, corpHashed })
            })
    }

    componentDidMount() {

        this.getData()
    }

   

    render() {

        const { scores, schools, filterSchoolId, filterUserId, filterDate, users, userHashed, schoolsHashed, corporates, corpHashed } = this.state;
        let { url } = this.props.match;

        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || '';

        let dataSource = scores.map(score => ({ key: score.id, ...score })).sort((a: any, b: any) => b.addedOn - a.addedOn)



        const columns = [

            // {
            //     title: 'Date & Time',
            //     render: (score: ScoreInterface) => {
            //         const dt = dayjs(score.addedOn).format('DD/MM/YYYY H:mm')
            //         return dt;
            //     },
            //     sorter: (a: any, b: any) => a.addedOn - b.addedOn,
            //     // sortDirections: ['descend', 'ascend'],
            // },
            {
                title: 'Driver Name',
                key: 'user',
                render: (score: ScoreInterface) => {
                    return userHashed[score.uid] ? userHashed[score.uid].name : '-';
                },
                sorter: (a: any, b: any) => a.addedOn - b.addedOn,
            },
            {
                title: 'Code',
                dataIndex: 'code',
                key: 'code'
            },
            {
                title: 'Start & End',
                // dataIndex: 'startJourneyTime',
                key: 'startJourneyTime',
                render: (score: any) => {
                    const dt1 = dayjs(score.startJourneyTime).format('DD/MM/YYYY H:m')
                    const dt2 = dayjs(score.endJourneyTime).format('DD/MM/YYYY H:m')
                    return (<>
                        {dt1} <br /> {dt2}
                    </>);
                },
                sorter: (a: any, b: any) => a.startJourneyTime - b.startJourneyTime,
            },

            {
                title: 'Events',
                render: (score: any) => {
                    let t = <></>;
                    if (score.alertEvents) {
                        t = score.alertEvents.map((a: any) => {
                            return (<>
                                {a.alertType}:{dayjs(a.timestamp).format('DD/MM/YYYY H:m')}<br />
                            </>)
                        })
                    }
                    return t
                },
            }


        ];

        if (filterSchoolId) {
            dataSource = dataSource.filter(d => d.cid === filterSchoolId)
        }
        if (filterUserId) {
            dataSource = dataSource.filter(d => d.uid === filterUserId)
        }
        if (!!filterDate) {
            const df = dayjs(filterDate).set('h', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
            dataSource = dataSource.filter(d => {
                const da = dayjs(d.addedOn).set('h', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
                return da.isSame(df)
            })
        }

        const ids=[
            {id:'dWCDFblM44jUJkuqijW8',name:'Chitoor'},
            {id:'2x38475dOv3oVM0WiZt7',name:'Simoga'}
        ];

        return (
            <main>

                <Select
                    showSearch
                    style={{ width: 200, marginTop: '2vh', marginBottom: '2vh' }}
                    placeholder="Filter User"
                    optionFilterProp="children"
                    allowClear
                    onClear={() => this.setState({ drivingSchoolId: '' })}
                    onChange={(id: string) => this.getData(id)}
                    // onFocus={onFocus}
                    // onBlur={onBlur}
                    // onSearch={onSearch}
                    filterOption={(input, option) => {
                        return option!.children ? option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                    }
                    }
                >
                    {ids.map(id=><Option key={id.id} value={id.id}>{id.name}</Option>)}

                </Select>

                <Table className="ScoresTable" dataSource={dataSource} columns={columns} />
            </main>
        );
    }




};

export default withRouter(BBJourner);

const games = {
    roadways: 'Roadways',
    aa: 'Accident Analysis',
    lm: 'Landmark Mapping',
    oa: 'Overtaking Analysis',
    hazchem: 'Hazchem Quiz',
    basics: 'Quiz Basics',
    signfactory: 'Sign Factory',
    iso: 'Isometric Quiz'
};

const gameData = [
    {
        route: games.roadways,
        level: 1
    },
    {
        route: games.aa,
        level: 1
    },
    {
        route: games.basics,
        level: 1
    },
    {
        route: games.lm,
        level: 1
    },
    {
        route: games.oa,
        level: 1
    },
    {
        route: games.hazchem,
        level: 1
    },
    {
        route: games.iso,
        level: 1
    },
    {
        route: games.aa,
        level: 2
    },
    {
        route: games.basics,
        level: 2
    },
    {
        route: games.signfactory,
        level: 1
    },
    {
        route: games.oa,
        level: 2
    },
    {
        route: games.hazchem,
        level: 2
    },
    {
        route: games.roadways,
        level: 2
    },
    {
        route: games.aa,
        level: 3
    },
    {
        route: games.basics,
        level: 3
    },
    {
        route: games.lm,
        level: 2
    },
    {
        route: games.oa,
        level: 3
    },
    {
        route: games.hazchem,
        level: 3
    },
    {
        route: games.aa,
        level: 4
    },
    {
        route: games.iso,
        level: 2
    },
    {
        route: games.basics,
        level: 4
    },
    {
        route: games.oa,
        level: 4
    },
    {
        route: games.signfactory,
        level: 2
    },
    {
        route: games.roadways,
        level: 3
    },
    {
        route: games.hazchem,
        level: 4
    },
    {
        route: games.aa,
        level: 5
    },
    {
        route: games.lm,
        level: 3
    },
    {
        route: games.iso,
        level: 3
    },
    {
        route: games.oa,
        level: 5
    }
];