import { Row, Col, Tabs, Skeleton } from 'antd';
import React from 'react';
import { CorporateCodeInterface, DrivingSchoolInterface, ScoreInterface, UserInterface } from '../../types';
import { RRServer } from '../../utils/lib';

import dayjs from 'dayjs';

const { TabPane } = Tabs;



interface DashProps {
    schools: DrivingSchoolInterface[]
    corporateCodes: CorporateCodeInterface[],
    corporateCodesCount: number,
    drivingSchoolCodesCount: number,
    studentsCount: number,
    instructorCount: number,
    adminCount: number,
    loading: boolean,

    user: UserInterface | null,
    scores: ScoreInterface[]
}
class APEmployeeStat extends React.Component<any, DashProps> {


    constructor(props: any) {
        super(props);

        this.state = {
            loading: true,
            schools: [],
            corporateCodes: [],
            corporateCodesCount: 0,
            drivingSchoolCodesCount: 0,
            studentsCount: 0,
            instructorCount: 0,
            adminCount: 0,

            user: null,
            scores: []
        }
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        Promise.all([RRServer.get({ table: RRServer.Tables.USERS, id }),
        RRServer.list({ table: RRServer.Tables.SCORES, forCurrentUser: false, where: [["uid", "==", id]] })])
            .then((results: any[]) => {
                this.setState({
                    user: results[0].data.data,
                    scores: results[1].data.data
                })
            })
    }


    render() {


        const { user, scores } = this.state;
        
        //Total score from attempt 1
        var totalScore:number = 0;                

        for(var i = 0; i < scores.length; i++){
            if(scores[i].attemptNumber == 1){
                totalScore += scores[i].score;
            }
        }        

        return (<>
            {!user
                ? <Skeleton avatar paragraph={{ rows: 4 }} />
                :
                <Row gutter={16}>
                    <Col span={24}>
                        <h2 style={{backgroundColor: '#3f3f3f', color: 'wheat'}} >Student Stat for  {user.name} ({user.code})</h2>
                    </Col>
                    <Col span={24}>
                        <table style={{'width':'100%', fontSize: '3vh'}} className={"studentstat-table"}>
                            <thead>
                                <tr>
                                    <th style={{backgroundColor: '#3f3f3f', color: 'wheat', opacity: '1'}}>Date</th>
                                    <th style={{backgroundColor: '#3f3f3f', color: 'wheat', opacity: '1'}}>Game</th>
                                    {/* <th>Level</th> */}
                                    <th style={{backgroundColor: '#3f3f3f', color: 'wheat', opacity: '1'}}>#Attempt</th>
                                    <th style={{backgroundColor: '#3f3f3f', color: 'wheat', opacity: '1'}}>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {scores.map(score => <tr>                                    
                                    <th>{dayjs(score.addedOn).format('DD/MM/YY hh:mm A')}</th>
                                    <th>{score.game}</th>
                                    {/* <th>{score.level}</th> */}
                                    <th>{score.attemptNumber}</th>
                                    <th>{score.score}</th>
                                </tr>)}
                            </tbody>
                        </table>
                    </Col>
                    <Col span={24}>
                        <table style={{'width':'50%', marginTop: '10vh', marginLeft: '76vh'}} className={"studentstat-table"}>
                            <thead>
                                <tr>
                                    <th style={{paddingLeft: '2vh', backgroundColor: '#3f3f3f', color: 'wheat', opacity: '1', textAlign: 'left'}}>TOTAL SCORE</th>
                                    <th style={{paddingRight: '0vh',backgroundColor: '#3f3f3f', color: 'wheat', opacity: '1', textAlign: 'center'}}>{totalScore}</th>                                    
                                </tr>
                            </thead>                            
                        </table>
                    </Col>
                </Row>
            }
        </>)
    }
}

export default APEmployeeStat;