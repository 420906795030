import React from 'react';
import { Button, Divider, Form, Input, message, Skeleton } from 'antd';
import { withRouter } from 'react-router-dom';
import MaskedInput from 'antd-mask-input'
import { callOnServer, RRServer } from '../../utils/lib';


interface DrivingSchoolInterface {
    code: string;
    id: string
    drivingSchoolName: string
    currentCount: string
    sosNumber: string
    pricePerCode: number
}
interface CorporateCodeInterface {
    password: string
    id: string
}

interface DrivingSchoolAddInterface {
    currentSchool: null | DrivingSchoolInterface,
    corporateCode: null | CorporateCodeInterface
    mode: 'edit' | 'add',
}

class DrivingSchoolAdd extends React.Component<any, DrivingSchoolAddInterface> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentSchool: null,
            corporateCode: null,
            mode: !!props.match.params.id ? 'edit' : 'add',
        }
        this.onFinish = this.onFinish.bind(this);
        this.addDrivingSchool = this.addDrivingSchool.bind(this);
    }

    componentDidMount() {
        if (this.state.mode === 'edit') {
            Promise.all([
                RRServer.get({ table: 'driving_schools', id: this.props.match.params.id }),
                callOnServer('find', { table: 'corporate_codes', where: [['companyId', '==', this.props.match.params.id]] })
            ]).then((results: any) => {
                this.setState({
                    currentSchool: results[0].data.data,
                    corporateCode: results[1].data.data
                })
            })
        }
    }


    addDrivingSchool(school: DrivingSchoolInterface & { password?: string, credits: number }) {
        const { mode, currentSchool, corporateCode } = this.state;
        const currentCount = school.code.split('').slice(school.code.length - 3).join('');


        if (mode === 'edit') {
            let _school = school;
            school.id = currentSchool!.id;
            const password = _school.password;
            delete _school.password;
            Promise.all([
                RRServer.update({ ...school, table: 'driving_schools' }),
                RRServer.update({ password: password, id: corporateCode!.id, table: 'corporate_codes' })
            ])
                .then((results: any) => {
                    console.log(results);
                    if (results[0].data.s && results[1].data.s) {
                        message.success('Successfully Updated Driving School');
                        setTimeout(() => window.location.href = '/driving-schools', 200)
                    }
                })

        } else {
            const params = {
                ...school,
                currentCount: "B00",
                currentCountAdmin: "A00",
                instructorCode: "123456A20",
                instructorCurrentCount: "A20",
                officeAdminCurrentCount: "A10"
            };



            const fnNameOLD = 'addDrivingSchool';
            const fnName = 'ds1-add';
            RRServer.callFn(fnName, params).then((result: any) => {
                if (result.data.s) {

                    if (school.credits) {
                        RRServer.add({
                            table: 'payments', data: {
                                razorpayPaymentId: 'FREE CREDIT',
                                razorpayOrderId: 'FREE CREDIT',
                                amount: 0,
                                codesToBuy: school.credits,
                                pricePerCode: school.pricePerCode,
                                schoolId: result.data.id,
                                schoolName: school.drivingSchoolName
                            }
                        })
                        .then(()=>{
                            message.success('Successfully Added Driving School');
                            setTimeout(() => window.location.href = '/driving-schools', 200)
                        })
                    }else{
                        message.success('Successfully Added Driving School');
                        setTimeout(() => window.location.href = '/driving-schools', 200)
                    }



                    // alert('Successfully Added User Driving School');
                   
                }
            }).catch(function (error) {
                alert(error);
            })
        }

    }

    onFinish(values: any) {
        this.addDrivingSchool(values);
    };

    onFinishFailed(errorInfo: any) {
        console.log('Failed:', errorInfo);
    };

    onSearch() {
    }




    render() {
        const { mode, currentSchool, corporateCode } = this.state

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        const tailLayout = {
            wrapperCol: { offset: 8, span: 16 },
        };


        return (
            <main>
                <Divider />
                <div style={{ width: '400px' }}>

                    {mode === 'edit' && currentSchool === null
                        ? <Skeleton active />
                        : <Form
                            {...layout}
                            name="basic"
                            initialValues={{
                                ...currentSchool,
                                password: corporateCode && corporateCode.password
                            }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                        // onValuesChange={(changedValues, allValues) => console.log(changedValues, allValues)}
                        >
                            <Form.Item
                                label="Code"
                                name="code"
                                rules={[{ required: true, message: 'Please Enter Code!' }]}
                            >
                                {/* <MaskedInput mask="111111A00" name="code" size={'small'} /> */}
                                <Input placeholder="Code" />

                            </Form.Item>

                            <Form.Item
                                label="Name"
                                name="drivingSchoolName"
                                rules={[{ required: true, message: 'Name' }]}
                            >
                                <Input placeholder="Name" />
                            </Form.Item>
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{ required: true, message: 'Name' }]}
                            >
                                <Input placeholder="Name" />
                            </Form.Item>
                            <Form.Item
                                label="SOS Phone Number"
                                name="sosNumber"
                                rules={[{ required: true, message: 'SOS Number' }]}
                            >
                                <Input placeholder="SOS Number" />
                            </Form.Item>
                            <Form.Item
                                label="Price Per Code"
                                name="pricePerCode"
                                rules={[{ required: true, message: 'Price Per Code' }]}
                            >
                                <Input placeholder="Price Per Code" />
                            </Form.Item>
                            <Form.Item
                                label="Credits"
                                name="credits"
                                rules={[{ required: true, message: 'Credits' }]}
                            >
                                <Input placeholder="Credits" />
                            </Form.Item>
                            <Form.Item
                                label="Union Image"
                                name="drivingSchoolUnionImg"
                                rules={[{ required: false, message: 'drivingSchoolUnionImg' }]}
                            >
                                <Input placeholder="Union Image" />
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <Button type="primary" htmlType="submit" >
                                    {mode === 'edit' ? 'Edit' : 'Add'}
                                </Button>
                            </Form.Item>
                        </Form>
                    }
                </div>
            </main>
        );
    }
}

export default withRouter(DrivingSchoolAdd);