import React from 'react';
import { Button, Card, Divider, Input, message, PageHeader, Select } from 'antd';
import { withRouter } from 'react-router-dom';
import { getClaims, RRServer } from '../../utils/lib';
import { DrivingSchoolInterface, UserInterface } from '../../types';

const { Option } = Select;


type schoolHashedType = { [key: string]: DrivingSchoolInterface };


interface UserStateProps {
    smsTemplate: string
    smsTemplateID: string
    phone: string
    schoolId: string
    schools: DrivingSchoolInterface[]
    users: UserInterface[]
    schoolsHashed: schoolHashedType
    userId: string
}

class Settings extends React.Component<any, UserStateProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            smsTemplate: '',
            smsTemplateID: '',
            phone: '',
            schoolId: '',
            schools: [],
            users: [],
            schoolsHashed: {},
            userId: ''

        }
    }

    componentDidMount() {

        RRServer.list({ table: 'settings', forCurrentUser: false })
            .then((r: any) => {
                const settings = r.data.data;
                let _settings = { ...this.state };
                settings.map((s: { id: any; value: any; }) => {
                    switch (s.id) {
                        case 'sms-template-boarding':
                            _settings['smsTemplate'] = s.value; break;
                    }


                })
                this.setState(_settings)

            })



        getClaims().then(claims => {
            if (!claims || !!!claims['drivingSchoolId']) { return null }
            const drivingSchoolId = claims ? claims['drivingSchoolId'] : '';

            const userPromise = (drivingSchoolId === '*' || drivingSchoolId === '#')
                ? RRServer.list({ table: 'users', forCurrentUser: false })
                : RRServer.list({ table: 'users', forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] });
            const dsPromise = (drivingSchoolId === '*' || drivingSchoolId === '#')
                ? RRServer.list({ table: 'driving_schools', forCurrentUser: false })
                : RRServer.get({ table: 'driving_schools', id: drivingSchoolId });

            Promise.all([
                // RRServer.list({ table: 'users', forCurrentUser: false, where:[['companyId','==',id]] }),
                userPromise,
                dsPromise,
                RRServer.list({ table: 'corporate_users', forCurrentUser: false }),
                RRServer.list({ table: 'corporate_codes', forCurrentUser: false }),
            ])
                .then((results: any) => {
                    let users = results[0].data.data;

                    let schools: DrivingSchoolInterface[] = results[1].data.data.length ? results[1].data.data : [results[1].data.data];
                    let _s: schoolHashedType = {};
                    schools.map(school => _s[school.id] = school)


                    this.setState({ users, schools, schoolsHashed: _s, })
                });


        })

    }

    saveSmsTemplate = () => {

        const { smsTemplate } = this.state;
        RRServer.update({ table: 'settings', id: 'sms-template-boarding', value: smsTemplate })
            .then((r: any) => {
                if (r.data.s) {
                    message.success('Successfully Saved Template');
                }

            })



    }

    sendSampleSMS = () => {

        RRServer.callFn('notification1-sms', {
            tmpl: "sms-template-boarding",
            phone: this.state.phone,
            params: {
                code: "123",
                pass: "sssss"
            }
        })

    }

    setTmpl = (smsTemplate: string) => this.setState({ smsTemplate })
    setPhone = (phone: string) => this.setState({ phone })

    clearDevice=()=>{
        RRServer.update({ table: RRServer.Tables.USERS, id: this.state.userId, deviceUUID:"" })
            .then((r: any) => {
                if (r.data.s) {
                    message.success('Successfully Cleared');
                }

            })
    }



    render() {

        const { smsTemplate, phone, schools, users, schoolId, userId } = this.state;
        const usersFiltered = schoolId ? users.filter(user => user.companyId == schoolId) : users;

       

        return (
            <div className="App">
                <main>
                    <PageHeader
                        ghost={false}
                        onBack={() => window.history.back()}
                        title="Settings"
                        subTitle=""
                        extra={[
                            // <Link to={`${url}/add`} key='0'>
                            //     Add School<UserAddOutlined />
                            // </Link>,
                            // <Link to={`${url}`} key='1'>
                            //     List<OrderedListOutlined />
                            // </Link>,
                        ]}
                    >
                    </PageHeader>

                    <Card title="SMS Template - Boarding" bordered={false}>
                        <Input placeholder="Template" value={smsTemplate} onChange={(e) => this.setTmpl(e.target.value)} />
                        <Button onClick={this.saveSmsTemplate}>Save</Button>
                    </Card>
                    <Card title="SMS Sample" bordered={false}>
                        <Input placeholder="Phone" value={phone} onChange={(e) => this.setPhone(e.target.value)} />
                        <Button onClick={this.sendSampleSMS}>Save</Button>
                    </Card>
                    <Divider />
                    <Card title="Clear Device" bordered={false}>
                        <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Filter School"
                            optionFilterProp="children"
                            onChange={(id) => this.setState({ schoolId: id + "" })}
                            // onFocus={onFocus}
                            // onBlur={onBlur}
                            // onSearch={onSearch}
                            filterOption={(input, option) => {
                                return option!.children ? option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                            }
                            }
                        >
                            {schools.map(school => <Option key={school.id} value={school.id}>{school.drivingSchoolName}</Option>)}

                        </Select>
                        <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Filter School"
                            optionFilterProp="children"
                            onChange={(id) => this.setState({ userId: id + "" })}
                            // onFocus={onFocus}
                            // onBlur={onBlur}
                            // onSearch={onSearch}
                            filterOption={(input, option) => {
                                return option!.children ? option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                            }
                            }
                        >
                            {usersFiltered.map(user => <Option key={user.id} value={user.id}>{user.name}</Option>)}

                        </Select>
                        {/* {userId && console.log(users.find(user => user.id == userId) as any)} */}
                        {userId ? (users.find(user => user.id == userId) as any).deviceUUID : ''}
                        <Button onClick={this.clearDevice}>Clear</Button>
                    </Card>



                </main>
            </div>
        );
    }
}

export default withRouter(Settings);