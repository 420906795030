import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

import ScoresView from './pages/Scores/ScoresView';
import User from './pages/User/User';
import StudentStats from './pages/StudentStats/StudentStats';
import StudentStat from './pages/StudentStats/StudentStat';
import Home from './pages/home';
import NewsList from './pages/News/News';

import firebase from './utils/Firebase';
import { Button, Layout, Menu } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  LogoutOutlined,
  DashboardOutlined,
  CarOutlined,
  ClockCircleOutlined,
  UnorderedListOutlined,
  MoneyCollectFilled,
  SettingFilled,
  NotificationOutlined,
  HomeFilled,
  CarryOutOutlined,
  CarryOutFilled,
  CalendarFilled,
  IdcardFilled
} from '@ant-design/icons';

import 'antd/dist/antd.css';
import DrivingSchools from './pages/DrivingSchools/DrivingSchools';

import Login from './pages/Login'
import ClassSchedules from './pages/ClassSchedules/ClassSchedules';
import { RRServer } from './utils/lib';

import './App.css'
import Installments from './pages/Installments/Installments';
import Settings from './pages/Settings/Settings';
// import Engagement from './pages/Engagement/EngagementAdd';
import Engagement from './pages/Engagement/Engagement';
import CorporateUser from './pages/CorporateUser/CorporateUser';


import APDashboard from './pages/Dashboard/AP-Dashboard'
import APTrafficTuesdayUpdates from './pages/Dashboard/AP-TrafficTuesdayUpdates';
import APDailyUpdates from './pages/Dashboard/AP-DailyUpdates';
import APAssessmentScores from './pages/Dashboard/AP-AssessmentScores';
import APEmployeeStats from './pages/Dashboard/AP-EmployeeStats';
import APEmployeeStat from './pages/Dashboard/AP-EmployeeStat';

// import logo from './assets/images/roadrakshak.png' 
import logo from './assets/images/roadrakshak.png';
import APDailyUpdatesTuesday from './pages/Dashboard/AP-DailyUpdatesTuesday';
import Assesments from './pages/Assessments/Assesments';
import Corporate from './pages/Corporate/Corporate';
import BBJourney from './pages/BBJourney/BBJourney';

const { Header, Sider, Content } = Layout;
class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      menuVisible: false,
      collapsed: false,
      isSignedIn: false,
      corporate: false
    }
  }
  componentDidMount() {
    const claimsString = localStorage.getItem('claims');
    const claims = JSON.parse(claimsString);
    let corporate = false;
    if (!!claims) {
      corporate = !!claims.corporate;
    }

    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {
        if (!!user) {
          const uid = user.uid;
          RRServer.list({ table: RRServer.Tables.DRIVING_SCHOOLS, forCurrentUser: false, where: [['firebaseUserUid', '==', uid]] })
            .then(r => this.setState({ isSignedIn: true, currentLoggedInSchool: r.data.data[0], corporate }))
            .catch(r => console.log(r))
          this.setState({ displayName: user.displayName, corporate })
        } else {
          this.setState({ isSignedIn: false })

        }
      }
    );

  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }


  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };



  logout() {
    firebase.auth().signOut().then(function () {
      window.location.href = '/';
    }, function () {
      alert('Error Signing Out')
    })
  }

  render() {

    const { isSignedIn, currentLoggedInSchool, displayName, corporate } = this.state;


    const claimsString = localStorage.getItem('claims') || '{}';
    const claims = JSON.parse(claimsString);
    claims.drivingSchoolId = claims.drivingSchoolId || '';




    // RRServer.callFn('notification-sms', {
    //   flow_id: "62299d9ff305947257632a99",
    //   mobiles: "9176135097",
    //   phone: "9176135097",
    //   tmpl:"sss",
    //   params: {
    //     name: "SSSS",
    //     code: "DDDD",
    //     password: "EEEE",
    //     extra: "Extra",
    //     link: "ddddf",
    //     tmpl: "222"
    //   }
    // })


    return (
      <Router>
        <Layout>
          <Sider trigger={null} collapsible collapsed={this.state.collapsed} style={{ display: isSignedIn ? 'block' : 'none' }}>

            <Menu theme="dark" mode="inline" defaultSelectedKeys={['0']}>

              <Menu.Item key="0" icon={<HomeFilled />} >
                <Link to='/'>Dashboard</Link>
              </Menu.Item>

              {claims.corporate &&
                <Menu.Item key="111" icon={<CarryOutFilled />}>
                  <Link to='/ap-daily-updates'>Todays Updates</Link>
                </Menu.Item>
              }
              {claims.corporate &&
                <Menu.Item key="121" icon={<CalendarFilled />}>
                  <Link to='/ap-traffic-tuesday-updates'>Traffic Tuesday Updatess</Link>
                </Menu.Item>
              }
              {claims.corporate &&
                <Menu.Item key="131" icon={<IdcardFilled />}>
                  <Link to='/ap-employee-stats'>Employee Stats</Link>
                </Menu.Item>
              }



              {(!corporate && (claims.drivingSchoolId === "*" || claims.drivingSchoolId === "#")) ? <Menu.Item key="141" icon={<CarOutlined />}>
                <Link to='/driving-schools'>Driving Schools</Link>
              </Menu.Item> : ""}

              {!corporate ? <Menu.Item key="151" icon={<UserOutlined />}>
                <Link to='/user'>Users</Link>
              </Menu.Item>
                : ""}

              {!corporate ? <Menu.Item key="161" icon={<ClockCircleOutlined />}>
                <Link to='/class-schedules'>Classes</Link>
              </Menu.Item> : ""}

              {!corporate ? <Menu.Item key="171" icon={<MoneyCollectFilled />}>
                <Link to='/installments'>Installments</Link>
              </Menu.Item> : ""}

              {!corporate ? <Menu.Item key="181" icon={<UserOutlined />}>
                <Link to='/scores'>Scores</Link>
              </Menu.Item> : ""}
              {!corporate ? <Menu.Item key="1811" icon={<UserOutlined />}>
                <Link to='/bb-journey'>BB Journey</Link>
              </Menu.Item> : ""}

              {<Menu.Item key="181-assessments" icon={<UserOutlined />}>
                <Link to='/assessments'>Assessments</Link>
              </Menu.Item>}

              {(!corporate && claims.drivingSchoolId !== "#") ? <Menu.Item key="191" icon={<NotificationOutlined />}>
                <Link to='/engagement'>Engagement</Link>
              </Menu.Item> : ""}

              {/* {!corporate ? <Menu.Item key="201" icon={<UserOutlined />}>
                <Link to='/purchase'>Purchase</Link>
              </Menu.Item>
                : ""} */}

              {(!corporate) ? <Menu.Item key="202" icon={<UserOutlined />}>
                <Link to='/studentstats'>Student Stats</Link>
              </Menu.Item>
                : ""}

              {(!corporate && (claims.drivingSchoolId === "*" || claims.drivingSchoolId === "#")) ? <Menu.Item key="11" icon={<UserOutlined />}>
                <Link to='/corporates'>Corporates</Link>
              </Menu.Item>
                : ""}
              {(!corporate && (claims.drivingSchoolId === "*" || claims.drivingSchoolId === "#")) ? <Menu.Item key="11C" icon={<UserOutlined />}>
                <Link to='/corporate-user'>Corporate Users</Link>
              </Menu.Item>
                : ""}

              {(!corporate && (claims.drivingSchoolId === "*" || claims.drivingSchoolId === "#")) ? <Menu.Item key="203" icon={<UnorderedListOutlined />}>
                <Link to='/news'>News</Link>
              </Menu.Item> : ''}


              {(!corporate && (claims.drivingSchoolId === "*" || claims.drivingSchoolId === "#")) ? <Menu.Item key="204" icon={<SettingFilled />}>
                <Link to='/settings'>Settings</Link>
              </Menu.Item> : ''}

              {isSignedIn && <Menu.Item key="205" icon={<LogoutOutlined />}>
                <a href='#' onClick={this.logout}>Logout</a>
              </Menu.Item>}
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Header className="site-layout-background" style={{ padding: 0 , display: isSignedIn ? 'block' : 'none' }}>
              {<Button onClick={this.toggle} className={'trigger'}>
                {this.state.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </Button>}
              <div className='header-username'>Welcome {displayName}</div>
            </Header>
            <Content
              className="site-layout-background"
              style={{
                margin: '24px 16px',
                padding: 24,
                minHeight: 280,
              }}
            >
              <Switch>
                <Route exact path="/Login">
                  <Login />
                </Route>
                <Route exact path="/">
                  {isSignedIn ? <Home /> : <Login />}
                </Route>
                <Route exact path="/scores">
                  <ScoresView />
                </Route>
                <Route exact path="/bb-journey">
                  <BBJourney />
                </Route>
                <Route exact path="/assessments">
                  <Assesments />
                </Route>
                <Route path="/user">
                  <User drivingSchool={currentLoggedInSchool} />
                </Route>
                {/* <Route path="/purchase">
                  <Purchase/>
                </Route> */}
                <Route path="/studentstats">
                  <StudentStats />
                </Route>
                <Route path="/corporate-user">
                  <CorporateUser drivingSchool={currentLoggedInSchool} />
                </Route>
                <Route path="/corporates">
                  <Corporate drivingSchool={currentLoggedInSchool} />
                </Route>
                <Route path="/news">
                  <NewsList />
                </Route>
                <Route path="/driving-schools">
                  <DrivingSchools />
                </Route>
                <Route path="/installments">
                  <Installments />
                </Route>
                <Route path="/engagement">
                  <Engagement />
                </Route>
                <Route path="/class-schedules">
                  <ClassSchedules />
                </Route>
                <Route path="/settings">
                  <Settings />
                </Route>

                <Route path="/ap-dashboard">
                  <APDashboard />
                </Route>
                <Route path="/ap-traffic-tuesday-updates">
                  {/* <APTrafficTuesdayUpdates /> */}
                  <APDailyUpdatesTuesday />
                </Route>
                <Route path="/ap-daily-updates">
                  <APDailyUpdates />
                </Route>
                <Route path="/ap-assessment-scores" render={(props) => <APAssessmentScores {...props} />} />
                <Route path="/ap-employee-stats" render={(props) => <APEmployeeStats {...props} />} />
                <Route path="/ap-employee-stat/:id" render={(props) => <APEmployeeStat {...props} />} />
                <Route path="/student-stat/:id" render={(props) => <StudentStat {...props} />} />


              </Switch>
            </Content>
          </Layout>
        </Layout>
      </Router>
    );
  }
}

export default App;
